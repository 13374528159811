import {Service} from '@/content/pages/services/index';

export default {
  icon: require('@/assets/icons/services/ppc_icon.png'),
  graphic: require('@/assets/graphics/services/adwords_graphic.svg'),
  title: 'Google Ads Packages',
  bullet:
    'Profit-led Google Ads Management to help fuel the growth of your marketing.',
  url: '/services/google-ads-packages',
  meta: {
    title: 'Google Ads Packages - #1 for Agencies and Brands [2021!] - Ocere',
    // TODO
  },
  // shop: 'https://my.ocere.com/product-category/ppc/',
  days: 28,
  description: [
    'Google Ads are powerful ways of driving more visibility, sales and leads to ' +
      'your business. We can help you master these platforms by creating new campaigns or optimising existing ' +
      'accounts. We’ll make sure you are using up-to-date features and best-practices in order to beat your ' +
      'competitors.',
  ],
  points: [
    'Grow Your Business',
    'Measurable ROI',
    'Fast Results',
    'Ever-evolving Platforms',
    'Human & AI Management (best of both)',
  ],
  packages: [
    {
      name: 'Google Ads Package 1',
      url: 'https://my.ocere.com/product/google-ads-management-1/',
      price: {
        USD: 695,
        GBP: 495,
      },
      items: {
        '': {
          'Suitable for click spends:': 'Up to $2500/m',
          'Strategy Creation': true,
          'Keyword Research': true,
          'Account Creation (If Required)': true,
          'Keyword Review (Including Negative)': true,
          'Ad Copy Review / Creation': true,
          'A/B Ad Testing': true,
          'Account Structure Review / Creation': true,
          'Budget Management Review / Creation': true,
          'Bid Management': true,
          'Analytics Linking (Basic Setup, If No Account)': false,
          'Detailed Competitor Review': false,
          'Google Search Text Ads': true,
          'Google Search "Click to Call" Ads': true,
          'Landing Page Analysis': false,
          'Ad Extentions Utilisation (Call/Sitelink/Location)': true,
          'Full Monthly Reporting': true,
        },
      },
    },
    {
      name: 'Google Ads Package 2',
      url: 'https://my.ocere.com/product/google-ads-management-2/',
      price: {
        USD: 1295,
        GBP: 895,
      },
      items: {
        '': {
          'Suitable for click spends:': 'Up to $6500/m',
          'Strategy Creation': true,
          'Keyword Research': true,
          'Account Creation (If Required)': true,
          'Keyword Review (Including Negative)': true,
          'Ad Copy Review / Creation': true,
          'A/B Ad Testing': true,
          'Account Structure Review / Creation': true,
          'Budget Management Review / Creation': true,
          'Bid Management': true,
          'Analytics Linking (Basic Setup, If No Account)': true,
          'Detailed Competitor Review': true,
          'Google Search Text Ads': true,
          'Google Search "Click to Call" Ads': true,
          'Landing Page Analysis': true,
          'Ad Extentions Utilisation (Call/Sitelink/Location)': true,
          'Full Monthly Reporting': true,
        },
      },
    },
    {
      name: 'Google Ads Package 3',
      url: '/get-in-touch',
      price: '12.5% of spend',
      items: {
        '': {
          'Suitable for click spends:': '> $6500+/m',
          'Strategy Creation': true,
          'Keyword Research': true,
          'Account Creation (If Required)': true,
          'Keyword Review (Including Negative)': true,
          'Ad Copy Review / Creation': true,
          'A/B Ad Testing': true,
          'Account Structure Review / Creation': true,
          'Budget Management Review / Creation': true,
          'Bid Management': true,
          'Analytics Linking (Basic Setup, If No Account)': true,
          'Detailed Competitor Review': true,
          'Google Search Text Ads': true,
          'Google Search "Click to Call" Ads': true,
          'Landing Page Analysis': true,
          'Ad Extentions Utilisation (Call/Sitelink/Location)': true,
          'Full Monthly Reporting': true,
        },
      },
    },
  ],
  how_it_works: [
    {
      title: 'Increase Your Presence',
      description:
        'Placing ads infront of customers already considering your product and service. These ' +
        'consist of Search Ads (Text, Responsive Ad), Click-to-call mobile Ads & Google Shopping Ads. ' +
        'We research relevant keywords/topics and other areas for you to target. We can benchmark your ' +
        'competition also.',
    },
    {
      title: 'Monthly Execution',
      description:
        `We'll execute this plan from Month 1 onwards. Typically this involves creating and ` +
        'implementing new ads (text, image, with extensions), bid management, keyword expansion and much ' +
        'more. Full reporting is given on every activity every month (via email and online portal).',
    },
    {
      title: 'Ongoing Refinement',
      description:
        'Here we continually monitor competitors, add new keywords, A/B test new ads and ' +
        'constantly refine the process. Over time, the results improve as the campaign becomes more ' +
        'optimised. Each month further we can look to grow your sales.',
    },
  ],
  key_benefits: [
    {
      title: 'Drive Business Goals',
      description:
        'Google Ads can have a strong and measurable impact on your your business goals and ' +
        'bottom line.',
    },
    {
      title: 'Measurable & Trackable',
      description:
        'Track ROI with pinpoint accuracy, compared with almost any other form of advertising.',
    },
    {
      title: 'AI-assisted Tools',
      description:
        'We have tools that work whilst you sleep: Analysing campaigns and providing recommendations.',
    },
    {
      title: 'Quick Results',
      description: `Immediate impact. Don't wait around for results.`,
    },
    {
      title: 'Innovative Targeting Options',
      description: `Target keywords, beat competitors, use Google's latest features to stay ahead.`,
    },
  ],
  faq: [
    {
      title: 'What is Google Ads (formerly Google AdWords)?',
      description:
        'Google Ads is a targeting form of advertising that are run across the Google Search & ' +
        'Google display networks.  Advertisers pay to display ads to users searching for their products or ' +
        'services, or who may be browsing a website related to their products or services.',
    },
    {
      title: 'What is CPC?',
      description:
        'Cost-per-Click refers to the most common type of bidding on Google Ads. ' +
        'Advertisers only pay for clicks on their ads, rather than paying per impression as with ' +
        'traditional advertising methods.',
    },

    {
      title: 'How much does it cost to run a PPC campaign?',
      description:
        'Our prices start from £495 per month for the management of your campaign, in addition we ' +
        'recommend a minimum clickspend of £750 (paid direct to Google).',
    },

    {
      title: 'Can you run the campaign on a commission basis?',
      description: 'We are unable to work on a commission basis because…',
    },

    {
      title:
        'I have an existing campaign, can’t you just manage this immediately?',
      description:
        'We can start management on a campaign immediately, however our team will still spend the ' +
        'first few days analysing the campaign and planning for any restructuring that may be necessary.',
    },
    {
      title:
        'My ad campaign is live but when I search for it, I can’t find it!',
      description:
        'Due to the dynamic nature of the Google Ads system your ad won’t always show on all ' +
        'occasions and what you may sometimes not be representative of what other users see. Our team use ' +
        'Ads Diagnostic tools which we use to identify any issues with ads not being shown correctly.',
    },
    {
      title: 'Do I need a Google Account to advertise on Google Ads?',
      description:
        'Yes, a Google account is needed to use Google Ads. If you don’t have one, we can create a ' +
        'new Google Ads account for you and invite an email of your choosing for full admin control over ' +
        'your account.',
    },
    {
      title:
        'Why do I need to add a Credit/Debit card to my Google Ads account?',
      description:
        'Your Ad campaign’s clickspend will be billed directly by Google, you will need valid ' +
        'payment details on your account before Google will allow ads to run.',
    },

    {
      title: 'Where can I get invoices for my Google Ads account spend?',
      description:
        'Google provide detailed steps on how to get an invoice, statement or payment receipt from ' +
        `your Google Ads account <a href='https://support.google.com/google-ads/answer/1723045?hl=en-GB' " +
        "target='_blank'>here</a>.`,
    },
    {
      title: 'Do you offer white labeling for Google Ads management services?',
      description:
        'Yes, please speak to an account manager about this service!',
    },
    {
      title:
        'Do you offer white labeling for Facebook Ads management services?',
      description:
        'Regrettably no, we are unable to offer this service due to the way Facbeook handles ' +
        'account access - all ads accounts & pages require direct requests from our Ocere Facebook accounts ' +
        'for our specialists to access.',
    },
  ],
} as Service;
