import {ManagedCampaigns} from '@/content/pages/managed-campaigns/index';

export default {
  icon: require('@/assets/icons/services/pricing_icon.png'),
  title: 'iGaming SEO',
  bullet: 'Looking for an SEO strategy/proposal for SEO Outreach?',
  url: '/casino-seo',
  meta: {
    title: '',
    // TODO
  },
} as ManagedCampaigns;
