import {Index} from '@/content/pages/persona';

export default {
  url: '/services/digital-pr',
  meta: {
    title: 'Digital PR Services | Ocere [2021!]',
    description:
      'Game-changing Digital PR strategies for brands looking to optimise their online profile. Click here to read more.',
  },
  green: {
    graphic: 'white-label',
    name: 'Digital PR',
    catchphrase: 'Digital PR',
    heading: 'Gain high-quality links and mentions to your website',
    paragraph: [
      'Over <a style="text-decoration: none;color: #fff;font-weight: 800;" href="https://ahrefs.com/blog/search-traffic-study/">90% of sites</a> never get any organic clicks from Google. The strength of your Digital PR strategy is the most crucial tool you have at your disposal as you look to grow and strengthen your online profile. By forging better connections with authoritative media outlets and bloggers — both local and wider reaching — as well as attracting organic traffic in greater numbers through high-quality content that demonstrates your site’s value, digital PR will create a stronger presence with which to grow and compete within your industry online.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>At Ocere, our high-impact Digital PR service is designed to optimise your online presence with the least amount of risk. We have worked on 1000&#39;s of campaigns with clients from a wide variety of industries, including finance, blockchain, e-commerce, iGaming and travel — ensuring that their visibility within their lucrative online market is constantly growing. </p>' +
      '<p>Our strategies cover all elements of digital PR, from idea and creative content, writing and issuing press releases, infographic creation, and blogger outreach, with results typically within a six to eight week period. With this service, you only pay for actual results, meaning you can have confidence in our approach from the get go.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver ' +
      'them. Many of our clients have had frustrating experiences with other agencies and are delighted ' +
      'to discover an entirely different experience when working with Ocere. If you are a digital ' +
      'marketing agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve ' +
      'your service to clients, have you considered white label SEO outreach service? If you are working ' +
      'as a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'How Do You Measure PR Success?',
      description:
        'Upon completion, we will provide you with a detailed report showing the SEO metrics (DA/DR) of all your live links and mentions. Of course, the main improvements you can expect to see are an increase in organic traffic, and a higher DA rating, which will be reflected in your SERP ranking and will, in turn, feed into the level of traffic coming into your site.',
    },
    {
      title: 'Are Press Releases Still Relevant for SEO in 2021?',
      description:
        'Digital PR rests on our ability to capture the attention of webmasters, bloggers and news sites. Webmasters receive staggering quantities of requests and press releases each week, and a concise, informative press release offers an excellent medium for getting your brand noticed. Ensuring that they are well-written and targeted towards relevant sites may not be as easy as it first seems, but press releases will continue to be a valuable tool for Digital PR and SEO for many years to come.',
    },
    {
      title: 'What Countries Does Ocere over?',
      description:
        'We have worked with over 3,000 clients in the UK, USA, Canada, Latin America (MX, BR), and across Europe. We have a multilingual team, so if your country is not listed, get in touch to find out more about what we can do for your brand.',
    },
    {
      title: 'How much does a PR campaign cost?',
      description:
        '<p>We offer competitive rates, and will only invoice for payment on live links and mentions. </p>' +
        '<ol>' +
        '<li>DA30+ £495</li>' +
        '<li>DA40+ £595</li>' +
        '<li>DA60+ £795</li>' +
        '<li>DA70+ £995</li>' +
        '<li>DA80+ £1495</li>' +
        '</ol>' +
        '<p>We don’t charge for any link below DA30, and are available to set a price cap on campaigns at your request.</p>' +
        '<ol>' +
        '<li>Online citations will be charged at £295 each.</li>' +
        '<li>(Optional) Google Survey: £500 paid directly to Google.</li>' +
        '<li>(Optional) Infographic Cost: £695 upfront.</li>' +
        '</ol>',
    },
    {
      title: 'Do You Have Experience with the iGaming Market?',
      description:
        'Yes, our experience in <a target="_blank" href="https://www.ocere.com/services/igaming-seo">the iGaming market</a> is extensive — we are one of the few SEO outreach and digital PR agencies to have comprehensive experience in <a target="_blank" href="https://www.ocere.com/services/content-creation">the igaming market</a>, and have been working with many of the top operators for more than five years.',
    },
  ],
} as Index;
