export default {
  'get-in-touch': [
    'Send us a message, Email or Skype / WhatsApp',
    'Fast response, even outside of office hours',
    'Speak to our UK based expert team',
    'Get pricing & full info on all services',
  ],
  'green-area': {
    catchphrase: 'International SEO',
    heading: 'Creative Content & Publishing Services',
    buttons: {
      orange: {
        url: '/services',
        text: 'View Services',
      },
      green: {
        url: '/get-in-touch',
        text: 'Get In Touch',
      },
    },
  },
  partners: [
    {src: require('@/assets/customers/12.png'), name: 'William Hill'},
    {src: require('@/assets/customers/6.png'), name: 'AirBnb'},
    {src: require('@/assets/customers/5.png'), name: 'eToro'},
    {src: require('@/assets/customers/11.png'), name: 'Lemonade'},
    {src: require('@/assets/customers/15.png'), name: 'Betway'},
    {src: require('@/assets/customers/4.png'), name: 'Next Insurance'},
    {src: require('@/assets/customers/10.png'), name: 'Unibet'},
    {src: require('@/assets/customers/1.png'), name: '888 Casino'},
    {src: require('@/assets/customers/7.png'), name: 'Ladbrokes'},
    {src: require('@/assets/customers/9.png'), name: 'Plarium'},
    {src: require('@/assets/customers/3.png'), name: 'Bingo.com'},
  ],
  serviceCards: [
    {
      title: 'SEO Outreach',
      subTitle: 'Over 3200 happy clients.',
      backgroundClass: 'building',
      url: '/link-building',
      contents:
        '- Tier 1 publications <br/>- Over 50,000 Publisher Relationships<br/>- International Publishing Network',
    },
    {
      title: 'Creative Content',
      subTitle: 'SEO-optimised content at scale.',
      backgroundClass: 'creation',
      url: '/services/content-creation',
      contents:
        '- Blogs, Articles, Guides <br/>- Fully SEO optimised<br/>- International Capability',
    },
    {
      title: 'Managed Services',
      subTitle: 'SEO Outreach, Content, Analytics',
      backgroundClass: 'services',
      url: '/services/managed-services',
      contents:
        '- Fully Bespoke service <br/>- World-class Account Management<br/>- Working to your precise requirements',
    },
  ],
  persona: [
    {
      graphic: 'white-label',
      url: '/link-building',
      title: 'SEO Outreach (Blogger Outreach)',
      subtitle: 'Serving SEO Teams, Companies and Agencies.',
      text:
        'With over 40,000 publisher relationships across all key verticals (tech, gaming, sport, ' +
        'finance, business and so much more) - Ocere are your go-to resource to hit your SEO ' +
        'and SEO Outreach goals. ' +
        'Using another agency already? Let us beat their pricing, delight you with our service ' +
        'and wow you with our smooth workflow. Many of the smartest agencies and in-house teams' +
        'use Ocere to hit their targets. Start a trial today.',
    },
    {
      graphic: 'end-user',
      url: '/end-user',
      title: 'End User SEO/PPC',
      subtitle: 'Take your business to the next level',
      text:
        'Accelerate the growth of your business through SEO and PPC and outperform the ' +
        'competition. Place an order online using one of our prebuilt packages, part of our SEO outreach ' +
        'services. If you prefer, you can get in touch for further advice on achieving your business goals.',
    },
    {
      graphic: 'referral-partners',
      url: '/referral-partners',
      title: 'Referral Partners',
      subtitle: 'Spread the word and earn commission',
      text:
        'Earn 10% lifetime commission on our services without any involvement. As a white label ' +
        'SEO reseller simply introduce us to contacts in need of Digital Marketing, and we’ll do the rest, ' +
        'leaving you to look for the next opportunity. Refer your first contact today.',
    },
    {
      graphic: 'white-label',
      url: '/white-label',
      title: 'White Label',
      subtitle: 'Offer our expertise to your clients',
      text:
        'Many of our clients are digital marketing agencies. They use our white label SEO service ' +
        'which can include building outreach, writing a single piece of content to writing original, unique ' +
        'optimised content for an entire website. Our DM white label agency clients typically use the ' +
        'collective experience and expertise of our marketing team to continuously improve the SEO of their ' +
        'clients’ websites. White label SEO reports are branded with your business logo.',
    },
  ],
  faq: [
    {
      title: 'What Constitutes an Effective SEO Strategy?',
      description: `SEO, or search engine optimisation, covers a broad range of methodologies and practices – all of which serve to facilitate the long-term growth and development of your brand’s visibility online.<br/>
      More specifically, it concentrates on both the creative and technical demands of maintaining an authoritative and highly competitive site – not to mention building and maintaining an international publishing network targeted towards your site’s industry and, more specifically, its target market.<br/>
      As a discipline, SEO must continually evolve alongside the internet itself. Its users, and the algorithms which spell the difference between a site’s success or anonymity, undergo significant changes year on year, which means that an effective SEO strategy will be one that prioritises every aspect of optimisation in equal measure, while taking an innovative, modern approach at every turn.`,
    },
    {
      title: 'What is SEO Outreach?',
      description: `SEO outreach is a term that, at its simplest, refers to a strategy that combines creative content with international publishing services, in order to boost your site’s visibility online. This represents one of the most effective practices for improving a site’s rankings on relevant search engine results pages – arguably one of the highest priorities for any brand, regardless of their industry or size – and, in turn, increasing their organic traffic over time.<br/>
      For any brand not pursuing SEO outreach, their site will remain largely self-contained and insular. A strong SEO outreach strategy offers a proactive alternative to obscurity, and an indispensable tool for seeing sustainable and tangible growth and development over time.<br/>
      Any effective SEO outreach strategy will be one that prioritises both high quality, creative content, and sourcing strong, international publishing opportunities in equal measure.<br/>
      These days, this practice plays such a pivotal role for businesses across all key verticals that bespoke outreach campaigns are now incredibly intricate and highly nuanced beasts. For this reason alone, utilising the creative content and publishing services of an experienced outreach agency offers the most direct route to the tangible results any site aspires to.`,
    },
    {
      title: 'Is SEO Outreach Still Relevant in 2021?',
      description: `Yes, SEO outreach is integral to any successful digital marketing strategy. Much of Google’s algorithm for ranking search engine results remains a mystery, but we do understand that Google reserves the first page of results for sites that have, over time, actively worked to demonstrate a clear authority over their market. Valuable, relevant and original content is much more likely to be shared and referenced, which is why high quality backlinks from authentic publishers act as strong evidence to Google that your site’s content holds value for readers.<br/>
      Google’s algorithms grow more intelligent and discerning each year, and sites with a strong, diverse publishing history consistently perform better on relevant results pages. And, beyond the many benefits SEO outreach holds for search engine algorithms, a long-term, bespoke strategy allows you to give a louder voice to your brand, and to do it justice through quality content that will contribute to the digital realm beyond the parameters of your own site.`,
    },
    {
      title: 'How Much Does SEO Outreach Cost?',
      description: `The answer to this question is entirely dependent upon the scope of your strategy. At Ocere, our prices are market-led, and our bespoke outreach campaigns are scalable, depending on your budget and desired outcome.<br/>
      Our multilingual content services and international network of over 50,000 publisher relationships, not to mention our expertise in all aspects of building modern and effectual SEO strategies, ensures that your site is able to take advantage of decades’ worth of knowledge and experience garnered from the very forefront of the industry.<br/>
      As such, our team can work with you to build a strategic SEO plan to suit your business’s needs, goals, and budget.`,
    },
    {
      title: 'How Does Creative Content Benefit Your Site?',
      description: `On the surface level, creative content allows you to engage a wide and relevant readership by offering discussion, information, or insight that pertains closely to your market and brand.<br/>
      Beyond that, strong creative content has the potential to significantly impact your visibility online. Like internet users themselves, search engine algorithms are highly adept at identifying those sites and articles which ‘stand out’ from the pack, and offer a definitively strong resource to prospective readers.<br/>
      Together, both of these factors mean that, for any site, original and creative content stands a necessity – and a long-term investment into the health of your online presence.<br/>
      Furthermore, superior content represents one of the vital keys to unlocking strong publishing opportunities. In essence, quality begets quality, and creative content that prioritises originality and value is the only way to develop a longstanding relationship with authentic and trusted online publishers.`,
    },
    {
      title:
        'What Industries Stand to Benefit from Creative Content and Publishing Services?',
      description: `We have worked with thousands of clients across all key verticals, devising strategic SEO campaigns that produce tangible and sustainable results for their sites. While some businesses operating online today will encounter a far greater level of competition when trying to build visibility, everything is relative, and the beauty of bespoke outreach campaigns is that they are designed to address the unique demands of any business, operating at any level – and in any industry.<br/>
      At Ocere, we operate at the very forefront of some of the most competitive markets out there. Our iGaming experience, which extends from Central and Eastern Europe to Latin America, the United States, Canada, Western Europe and the Nordics, represents one of our greatest strengths, but we continue to pave the way for a diverse client base seeking visibility in their particular markets.`,
    },
    {
      title: 'Why Choose Ocere?',
      description: `As a leading provider of bespoke outreach campaigns and technical SEO, our team holds a keen understanding of the demands of building, maintaining and growing a successful and lucrative website in 2021. Our experience spans more than a decade, and all of the most competitive verticals operating today – ensuring that we are best-placed to offer fresh and innovative solutions to our clients’ ambitions for visibility and authority within their target markets.<br/>
      Our international publishing network is more than 50,000 strong, and continues to grow with us – a fact which enables us to tailor our creative content and publishing services to suit your site’s unique position and goals, and ensure tangible, authentic and long-term results for you.<br/>
      Most importantly, our close-knit team shares a passion for developing strong creative content and publishing services, and developing long-lasting relationships with clients looking to make a significant impact online.<br/>
      Now, how can we help you? Set up a trial with us today, and see how we can help you grow`,
    },
  ],
};
