let callbackComponents = []
function loadedComponent(component) {
  return window[component]
}

const dynamicLoadScript = (component, src, callback) => {
  const keyScript = 'script' + component
  const existingScript = document.getElementById(keyScript)
  const cb = callback || function() {}
  if (!existingScript) {
    const script = document.createElement('script')
    script.src = src // src url for the third-party library being loaded.
    script.id = keyScript
    document.body.appendChild(script)
    callbackComponents.push(cb)
    const onEnd = 'onload' in script ? stdOnEnd : ieOnEnd
    onEnd(script)
  }

  if (existingScript && cb) {
    if (loadedComponent(keyScript)) {
      cb(null, existingScript)
    } else {
      callbackComponents.push(cb)
    }
  }

  function stdOnEnd(script) {
    script.onload = function() {
      // this.onload = null here is necessary
      // because even IE9 works not like others
      this.onerror = this.onload = null
      for (const cb of callbackComponents) {
        cb(null, script)
      }
      callbackComponents = []
    }
    script.onerror = function() {
      this.onerror = this.onload = null
      cb(new Error('Failed to load ' + src), script)
    }
  }

  function ieOnEnd(script) {
    script.onreadystatechange = function() {
      if (this.readyState !== 'complete' && this.readyState !== 'loaded') return
      this.onreadystatechange = null
      for (const cb of callbackComponents) {
        cb(null, script) // there is no way to catch loading errors in IE8
      }
      callbackComponents = []
    }
  }
}

export default dynamicLoadScript
