import {orderBy} from 'lodash';
const blogsFiles = require.context('./', true, /\.ts$/);
const exclude = ['index', 'model'];
const blogs = blogsFiles.keys().reduce((blogs, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  if (exclude.indexOf(moduleName) === -1) {
    const value = blogsFiles(modulePath);
    // @ts-ignore
    blogs.push(value.default);
  }
  return orderBy(blogs, ['order']);
}, []);

export default blogs;
