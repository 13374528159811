





















































































import {footer, ocere} from '@/content';
import {Component, Vue, Watch} from 'vue-property-decorator';
import LinkedIn from '@/assets/icons/linkedin.vue';
import Facebook from '@/assets/icons/facebook.vue';
import Skype from '@/assets/icons/skype.vue';
import Whatsapp from '@/assets/icons/whatsapp.vue';
import moment from 'moment';

@Component({components: {LinkedIn, Facebook, Skype, Whatsapp}})
export default class extends Vue {
  social = ocere.social;
  categories = footer.categories;
  disclaimers: any = footer.disclaimers;
  contacts = footer.contacts;

  get year(): string {
    return moment().format('YYYY');
  }

  @Watch('$route.path', {immediate: true})
  hideSiteMap(path) {
    if (path === '/') {
      this.disclaimers = footer.disclaimers.slice(0, -1);
    } else {
      this.disclaimers = footer.disclaimers;
    }
  }
}
