













import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class extends Vue {
  @Prop() partners!: Array<{ src: string; name: string }>;
}
