import blogger_outreach from '@/content/pages/link-building/blogger-outreach';
import international_link_building from '@/content/pages/link-building/international-link-building';
import link_packages from '@/content/pages/link-building/link-packages';
import casino_link_building from '@/content/pages/link-building/casino-link-building';

import content_creation from '@/content/pages/content-creation/content-creation';

import link_building from '@/content/pages/managed-campaigns/link-building';
import casino_link_building_m from '@/content/pages/managed-campaigns/casino-link-building';
import digital_pr from '@/content/pages/managed-campaigns/digital-pr';
import seo_packages from '@/content/pages/managed-campaigns/seo-packages';
import google_ads_packages from '@/content/pages/managed-campaigns/google-ads-packages';
import digital_marketing_packages from '@/content/pages/managed-campaigns/digital-marketing-packages';
import casino_seo from '@/content/pages/managed-campaigns/casino-seo';
import online_reputation_management from '@/content/pages/managed-campaigns/online-reputation-management';

export {default as home} from './pages/home';
export {default as persona} from './pages/persona';
export {default as linkBuilding} from './pages/link-building';
export {default as contentCreation} from './pages/content-creation';
export {default as managedCampaigns} from './pages/managed-campaigns';
export {default as blogs} from './blogs';

export const ocere = {
  email: 'info@ocere.com',
  phone: {
    uk: '01242 525557',
    us: '800-810-2458',
  },
  social: {
    facebook: 'https://www.facebook.com/ocere',
    linkedin: 'https://www.linkedin.com/company/ocere',
    whatsapp: 'https://wa.me/447970454619',
    skype: 'skype:ocereremi?chat',
  },
  reasons: [
    'Pre-built packages proven and refined over time.',
    'Full support from our team of UK based account managers via phone, Skype, Slack, email and LiveChat.',
    'Over 3000 successful projects completed since 2009.',
    'Google Partner certification and Google Elevator company',
    'Smooth delivery, transparent systems and timely reporting.',
    'Easy online ordering systems or more bespoke solutions.',
    'Consistent 9.2 Trustpilot review score.',
    'Global reach with a strong client base in the UK, USA and Israel.',
    'Easy payment via credit/debit card, bank transfer, PayPal or Bitcoin.',
  ],
};

export const header = {
  links: [
    {
      url: '/link-building',
      title: 'SEO Outreach',
      list: [international_link_building, link_packages, casino_link_building],
    },
    {
      url: '/services/content-creation',
      title: 'Creative Content',
      list: [content_creation],
    },
    {
      url: '/services/managed-services',
      title: 'Managed Services',
      list: [
        link_building,
        casino_link_building_m,
        digital_pr,
        seo_packages,
        google_ads_packages,
        digital_marketing_packages,
        casino_seo,
        online_reputation_management,
      ],
    },
  ],
};

export const footer = {
  categories: [
    {
      title: 'Quick Links',
      links: [
        {url: '/', title: 'Home'},
        {url: '/about-us', title: 'About Us'},
        {url: '/blog', title: 'Blog'},
      ],
    },
    {
      title: 'Main Menu',
      links: [
        {url: '/white-label', title: 'SEO Outreach'},
        {url: '/referral-partners', title: 'Referral Partners'},
        {url: '/end-user', title: 'End User SEO/PPC'},
        {url: '/services', title: 'Services'},
      ],
    },
  ],
  contacts: [
    {
      url: 'https://goo.gl/maps/KLtiAS9uWibF9Y4L7',
      title:
        'Ocere Ltd. Formal House,<br/>60 St George’s Pl, Cheltenham,<br/>GL50 3PN, United Kingdom',
    },
    {url: 'mailto:info@ocere.com', title: 'info@ocere.com'},
    {url: 'tel:01242 525557', title: '01242 525557'},
    {url: 'tel:800-810-2458', title: '800-810-2458'},
  ],
  disclaimers: [
    {title: 'Privacy Policy', url: '/privacy-policy'},
    {title: 'Terms & Conditions', url: '/terms'},
    {title: 'Sitemap', url: '/sitemap'},
  ],
};

export const sidebar = [
  {
    id: 'orders',
    title: 'Orders',
    exclude: ['provider'],
    child: [
      {
        id: 'newOrder',
        icon: require('@/assets/icons/sidebar/plus.svg'),
        url: '/create',
        title: 'New',
        // isParent: true,
        // child: [
        //   {
        //     id: 'newOnline',
        //     icon: require('@/assets/icons/sidebar/plus.svg'),
        //     url: '/create',
        //     title: 'Online Order',
        //   },
        //   {
        //     id: 'newManaged',
        //     icon: require('@/assets/icons/sidebar/plus.svg'),
        //     url: '/create/managed-service',
        //     title: 'Managed Service',
        //   },
        // ],
      },
      {
        id: 'viewOrder',
        icon: require('@/assets/icons/sidebar/list.svg'),
        url: '/client-dashboard',
        title: 'View',
      },
    ],
  },
  {
    id: 'account',
    title: 'My Account',
    child: [
      {
        id: 'profile',
        icon: require('@/assets/icons/sidebar/user.svg'),
        url: '/client-dashboard/account',
        title: 'My Profile',
      },
      {
        id: 'logout',
        icon: require('@/assets/icons/sidebar/turn-off.svg'),
        title: 'Logout',
        url: 'javascript:void(0)',
      },
    ],
  },
  {
    id: 'support',
    title: 'Contact Support',
    child: [
      {
        id: 'createTicket',
        icon: require('@/assets/icons/sidebar/ticket.svg'),
        url: '#',
        doFunc: () => $('#openModalCreateTicket').click(),
        title: 'Create Ticket',
      },
      {
        id: 'ticketList',
        icon: require('@/assets/icons/sidebar/list.svg'),
        url: '/tickets',
        title: 'View',
        exclude: ['provider', 'client'],
      },
    ],
  },
];

export const sidebarPermission = [
  {
    id: 'lead',
    title: 'Leads',
    exclude: ['client', 'operations', 'provider'],
    child: [
      {
        id: 'newLead',
        icon: require('@/assets/icons/sidebar/plus.svg'),
        url: '/lead?',
        title: 'New',
        doFunc: () => $('#openModalCreateLead').click(),
      },
      {
        id: 'viewLead',
        icon: require('@/assets/icons/sidebar/list.svg'),
        url: '/lead',
        title: 'View',
      },
    ],
  },
  {
    id: 'clients',
    title: 'Clients',
    exclude: ['client', 'provider'],
    child: [
      {
        id: 'newClient',
        icon: require('@/assets/icons/sidebar/plus.svg'),
        url: '/client/create',
        title: 'New',
      },
      {
        id: 'viewClient',
        icon: require('@/assets/icons/sidebar/list.svg'),
        url: '/client',
        title: 'View',
      },
    ],
  },
];
