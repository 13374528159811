















import {Component, Vue, Prop} from 'vue-property-decorator';
import {Service} from '@/content/pages/services';

@Component
export default class extends Vue {
  @Prop() service!: Service;
}
