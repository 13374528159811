
















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Service} from '@/content/pages/services';
import LinedBulletPoints from '@/components/services/lined-bullet-points.vue';

const map = {
  packages: {
    href: '#pricing',
    label: 'Pricing',
  },
  how_it_works: {
    href: '#how-it-works',
    label: 'How it works',
  },
  key_benefits: {
    href: '#key-benefits',
    label: 'Key benefits',
  },
  examples: {
    href: '#examples',
    label: 'Examples',
  },
  faq: {
    href: '#frequently-asked-questions',
    label: 'FAQs',
  },
};

@Component({
  components: {
    LinedBulletPoints,
  },
})
export default class extends Vue {
  @Prop() service!: Service;

  get links() {
    return Object.keys(this.service)
      .map(property => {
        // @ts-ignore
        if (this.service[property]) {
          // @ts-ignore
          return map[property];
        }
      })
      .filter(link => link);
  }
}
