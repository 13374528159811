import {Index} from '@/content/pages/persona';

export default {
  url: '/services/igaming-seo',
  meta: {
    title: 'iGaming SEO & SEO Outreach Services | Ocere [2021!]',
    description:
      'SEO services designed specifically to help clients in the iGaming industry increase their online presence organically. Click here to get started.',
  },
  green: {
    graphic: 'white-label',
    name: 'iGaming SEO',
    catchphrase: 'iGaming SEO',
    heading: 'Gain high-quality links and mentions to your website',
    paragraph: [
      'The iGaming industry is thriving. By some estimates, its value will more than double between 2017 and 2024; by 2029, it is expected to surpass a <a style="text-decoration: none;color: #fff;font-weight: 800;" href="https://www.finance-monthly.com/2019/06/diversity-and-innovation-driving-the-igaming-industry-towards-1-trillion/#:~:text=The%20market%20valuations%20and%20predictions,before%202029%2C%20potentially%20by%202026.">global worth of $1 trillion</a>. The internet has truly revolutionised the gambling industry, and this progression is still ongoing — new sites and brands are constantly bursting onto the scene, looking to gain traction within a highly lucrative and diverse market.',
      'For this reason alone, visibility is one of the greatest issues facing brands today. Competition for the highest rankings on SERPs is high, and ensuring that you are able to fully realise the potential of your online presence is a full-time job.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>An effective and sustainable SEO strategy is crucial to facilitating continual growth online. Without it, sites will stagnate within relative obscurity, and fail to reach a good position in search engine results.</p>' +
      '<p>At Ocere, we have built an impressive client base within the iGaming industry, working in sports and Esports betting, online casino, roulette, casino slots and many more. By building strong SEO strategies that specifically target relevant audiences and search engine algorithms, we have seen great success in raising Domain Authority (DA), and increasing organic traffic.</p>' +
      '<h2 class="c-h2">What Languages Do You Offer?</h2>' +
      '<p>In over ten years, we have amassed a client base of over 3,000, achieving great success with our SEO services in English, French, Portugese, Spanish, German, and Italian. We have also worked within Scandinavian and Russian markets.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver ' +
      'them. Many of our clients have had frustrating experiences with other agencies and are delighted ' +
      'to discover an entirely different experience when working with Ocere. If you are a digital ' +
      'marketing agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve ' +
      'your service to clients, have you considered white label SEO outreach service? If you are working ' +
      'as a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'What is iGaming SEO?',
      description:
        'iGaming SEO, or Search Engine Optimisation, is the process of important your site’s visibility on search engine results pages through techniques such as SEO outreach, social media advertising and content writing.',
    },
    {
      title: 'Why Do We Need SEO?',
      description:
        'According to a recent survey by Moz, a mere <a href="https://moz.com/blog/new-google-survey-results">7% of respondents</a> will venture beyond the first page of results. Our search habits on Google are largely guided by speed, convenience, and the fair assumption that the top results will be the most relevant and useful. So, how do we get to the first page of results? By utilising techniques that have been shown to appeal to search engine algorithms. There are ways of exhibiting to readers and algorithms that your site is of value, and SEO represents the most effective methodology for the sustainable growth of your online presence. Without it, other sites will stand a far greater chance of dominating the online market.',
    },
    {
      title: 'How is SEO Different within the iGaming Industry?',
      description:
        '<a target="_blank" href="https://www.ocere.com/services/seo-packages">The methodology for SEO</a> remains largely the same across all industries, but the iGaming is infamously one of the most difficult. In order to see steady, long-term growth within such a busy and competitive market, SEO must be expertly handled and monitored, and grounded within a strong understanding of the market. At Ocere, we have achieved considerable success within the iGaming industry, and have worked with many of the top operators for more than five years.',
    },
    {
      title: 'How Long Does it Take to Rank in Google?',
      description:
        'Some sites will advertise ‘quick fixes’ for SEO, but many of these practices risk incurring severe penalties from Google as they violate terms of service. When done right, SEO will begin to produce tangible results within two to three months, with the most significant changes taking place from six months. This growth is far more sustainable and effective than the results produced by rushed or underhand techniques.',
    },
  ],
} as Index;
