import {Service} from '@/content/pages/services/index';

export default {
  icon: require('@/assets/icons/services/link_icon.png'),
  graphic: require('@/assets/graphics/services/link_building_graphic.svg'),
  title: 'Blogger Outreach',
  bullet:
    'SEO outreach done properly. Authentic blogs with real audiences. Industry-leading turnaround times.',
  url: '/services/blogger-outreach',
  meta: {
    title:
      'Blogger Outreach Services - #1 SEO Outreach & Digital PR Agency UK [2021!]',
    description:
      'Blogger outreach services and SEO outreach for agencies from a leader in the field. Order links fom a trusted outreach agency.',
  },
  // shop: 'https://my.ocere.com/product-category/link-building/',
  days: 10,
  description: [
    'In-content SEO outreach on authentic and relevant blogs, helps boost your Google rankings above the ' +
      'competition. Our ‘blogger outreach’ team has outreached over 20,000 webmasters.',
    'Our blogger outreach service has an easy online order system. The entire process is completely transparent. ' +
      'Simply select your preferred site grade, target URL and desired anchor text – we’ll do the rest.',
    'Fast turnaround from 10+ days.',
  ],
  points: [
    'In-content Link To Your Site',
    '500 Word Content',
    '100% Authentic Outreach',
    'Fast Turnaround (10+ days)',
    'Ahrefs DR20 Average',
  ],
  packages: [
    {
      name: 'DA20+ Budget Tier',
      // url: 'https://my.ocere.com/product/blogger-outreach-da20/',
      price: {
        GBP: 60,
        USD: 85,
      },
      per: 'Price-match Guarantee',
      items: {
        Includes: {
          'In-content Link To Your Site': true,
          '500 Word Content': true,
          '100% Authentic Outreach': true,
          'Fast Turnaround (10+ days)': true,
          'Ahrefs Average': 'DR20+',
        },
        'Bulk Discounts': {
          '1 Placement': '£60',
          '25+ Placements': '£55',
          '50+ Placements': '£50',
        },
      },
    },
    {
      name: 'DA30+ Mid Tier',
      // url: 'https://my.ocere.com/product/blogger-outreach-da30/',
      price: {
        GBP: 80,
        USD: 110,
      },
      per: 'Price-match Guarantee',
      items: {
        Includes: {
          'In-content Link To Your Site': true,
          'Includes 500 word content': true,
          '100% Authentic Outreach': true,
          'Fast Turnaround (10+ days)': true,
          'Ahrefs Average': 'DR30+',
        },
        'Bulk Discounts': {
          '1 Placement': '£80',
          '25+ Placements': '£75',
          '50+ Placements': '£70',
        },
      },
    },
    {
      name: 'DA40+ High Tier',
      // url: 'https://my.ocere.com/product/blogger-outreach-da40/',
      price: {
        GBP: 165,
        USD: 210,
      },
      per: 'Price-match Guarantee',
      items: {
        Includes: {
          'In-content Link To Your Site': true,
          'Includes 500 word content': true,
          '100% Authentic Outreach': true,
          'Fast Turnaround (10+ days)': true,
          'Ahrefs Average': 'DR40+',
        },
        'Bulk Discounts': {
          '1 Placement': '£165',
          '25+ Placements': '£160',
          '50+ Placements': '£155',
        },
      },
    },
    {
      name: 'DA50+ Top Tier',
      // url: 'https://my.ocere.com/product/blogger-outreach-da50/',
      price: {
        GBP: 250,
        USD: 320,
      },
      per: 'Price-match Guarantee',
      items: {
        Includes: {
          'In-content Link To Your Site': true,
          'Includes 500 word content': true,
          '100% Authentic Outreach': true,
          'Fast Turnaround (10+ days)': true,
          'Ahrefs Average': 'DR50+',
        },
        'Bulk Discounts': {
          '1 Placement': '£240',
          '25+ Placements': '£235',
          '50+ Placements': '£230',
        },
      },
    },
  ],
  how_it_works: [
    {
      title: 'Outreaching Sites',
      description:
        `You'll provide the Anchor Text, Target URLs and quantity you require to our outreach ` +
        `agency team. We'll then start outreaching topically relevant sites, with strong authority. ` +
        'We already have 20,000 publisher relationships to help streamline this process. For a small charge ' +
        'you can approve sites upfront.',
    },
    {
      title: 'Creative Content',
      description:
        'Our UK content team will start writing your content immediately (typically 500 words ' +
        'per piece). The key here is to form a natural bridge between the blog and your target URL. ' +
        'We are experts at this. For a small charge you can approve content upfront.',
    },
    {
      title: 'Order Completion',
      description:
        `As a ‘blogger agency' we place thousands of content pieces per month. Outreach link ` +
        'building PR (press release) content is another element we regularly deploy. The content is quickly ' +
        'live. We send you a link report (via Email and Online Portal) which shows the live link, metrics ' +
        `(DA etc) and live date. We're on hand for any questions etc.`,
    },
  ],
  key_benefits: [
    {
      title: 'Authentic, Natural Sites',
      description:
        'Forget dodgy networks or "thin" blogs made for SEO purposes. We deal in authentic sites, ' +
        'with authentic audiences.',
    },
    {
      title: 'Fastest Turnaround',
      description:
        'We boast over 20,000 publisher relationships and sharper processes to turn orders around ' +
        'quicker.',
    },
    {
      title: 'Full Approval',
      description:
        'Unlike others - you can have full approval of all content and sites (+10% fee). Stay in ' +
        'control!',
    },
    {
      title: 'In Content Links',
      description:
        'All our links are naturally contained in the editorial. Forget footer links or sponsored ' +
        'links.',
    },
    {
      title: 'Price Match',
      description: `We'll match or beat any comparable offer from another company. No questions asked.`,
    },
  ],
  faq: [
    {
      title: 'What are blogger outreach services?',
      description:
        'Blogger outreach services is a catch-all phrase which develops and executes a strategy of ' +
        'working with other bloggers to create unique, relevant and authoritative content to promote brand, ' +
        'service or product awareness.',
    },
    {
      title: 'What is blogger outreach?',
      description:
        'Blogger outreach is a strategy of collaborating with bloggers to create authentic content ' +
        'to promote a product, brand or service by building organic, authoritative links to a website. ' +
        'Blogger outreach is an element of our SEO packages.',
    },
    {
      title: 'How do I contact bloggers?',
      description:
        'Your easiest and most direct route is via a blogger agency such as Ocere.',
    },
    {
      title: 'What are blogger outreach campaigns?',
      description:
        'A blogger outreach campaign is a method of encouraging promotion of your brand, product or ' +
        'service by collaborating with identified influencers in your target market.',
    },
    {
      title: 'Why is Blogger Outreach important?',
      description:
        'Blogger outreach is an element of an SEO strategy which is designed to get authoritative ' +
        'blogs talking your service, product or brand in relatively short timescale. Less time, less expense ' +
        'but more rapid exposure!',
    },
    {
      title: 'What is outreach tool?',
      description:
        'There are many influencer outreach and content marketing tools which a blogger agency will ' +
        'deploy to maximise efficiency of an outreach campaign, but the best tools are knowledge and ' +
        'experience of an ever evolving digital landscape.',
    },
    {
      title: 'How do you outreach?',
      description:
        'Blogger outreach is about building relationships with bloggers and influencers, so they ' +
        'will host your content to promote your brand, product or service on their websites or blog sites. ' +
        'As part of our SEO outreach services, Ocere will do all the outreach required.',
    },
    {
      title: 'How much does blogger outreach cost?',
      description:
        'Follow this link to Ocere’s blogger outreach services UK page for more detailed ' +
        'information about blogger outreach prices and what you get for your investment.',
    },
    {
      title: 'What is influencer outreach?',
      description:
        'Influencer outreach is a very powerful and effective alternative to traditional ' +
        'advertising. As a strategy for promoting awareness and developing credibility of your brand, ' +
        'it is difficult to beat.',
    },
    {
      title: 'What is outreach strategy?',
      description:
        'A blogger outreach strategy is a method of delivering and delivering ‘strategic ' +
        'communication’ of a consistent message to key target audiences, to partners and to draw in ' +
        'potential new customers.',
    },
    {
      title: 'How do you outreach to influencers?',
      description:
        'By identifying, reaching out to them by email or phone and then establishing trusting, ' +
        'working relationships with them. Alternatively, you could engage Ocere – with over 30,000 ' +
        'established relationships with influencers, we have done all the groundwork for you.',
    },
    {
      title: 'How do you pitch to an influencer?',
      description:
        'In email outreach to influencers, you will need a short, catchy and standout subject ' +
        'line, provide genuine stats, and some samples of unique content and then a proposal of working ' +
        'together, then hope you get a reply – or you could engage Ocere’s blogger outreach service.',
    },
    {
      title: 'How do you reach out to brands for collaboration?',
      description:
        'The first step when outreaching to brands is an email. Be cordial, be brief but outline ' +
        'what you want to do and what the features and benefits are to both parties.',
    },
    {
      title: 'How much do influencers charge?',
      description:
        'Influencers can charge anything from a few tens of pounds to many thousands of pounds.',
    },
    {
      title: 'How do I get influencers to promote my brand?',
      description:
        'Find influencers in your niche, outreach them, start building a relationship and create a ' +
        'perfect pitch – or you could engage Ocere’s influencer and blogger outreach service to save time, ' +
        'money and lots of frustration.',
    },
    {
      title: 'How do I ask my blog to promote my product?',
      description:
        'Include a link to your blog link in social media profiles such as Facebook, Twitter and ' +
        'Instagram etc. As soon as you publish any content on your blog, share that content on social media.',
    },
    {
      title: 'How can I become an influencer?',
      description:
        'Find or know your niche, choose your media channel, develop and distribute your content, ' +
        'grow your network and engage your followers. Alternatively, engage Ocere to do your outreach for you.',
    },
    {
      title: 'How can I promote my brand for free?',
      description:
        'Publish self made content on YouTube and other media channels, embrace several social ' +
        'media channels, optimise your brand’s website, start a blog, become a PR blogger and distribute ' +
        'for publication...the work never stops.',
    },
    {
      title: 'How can I promote free?',
      description:
        'Publish self made content on YouTube and other media channels, embrace several social ' +
        'media channels, optimise your brand’s website, start a blog, become a PR blogger and distribute ' +
        'for publication...the work never stops.',
    },
    {
      title: 'What is the cheapest way to advertise your business?',
      description:
        'Post authoritative, engaging content on your blog, build a free email list, create a ' +
        'Google My Business account, optimise your website, send out marketing texts to subscribers etc...',
    },
    {
      title: 'What are the three big local listing services?',
      description:
        'Yelp, Yell and Thomson Local - there is also Scoop, BT Tradespace, Hot Frog, Local Mole ' +
        'and so on...',
    },
    {
      title: 'What are outreach activities?',
      description:
        'Outreach activities cover a broad range such as email outreach, establishing relationships ' +
        'with influencers, brand promotion via blogging, unique creative content, PR blogging etc.',
    },
    {
      title: 'What are the benefits of outreach programs?',
      description:
        'Establish and increase brand awareness, promote products and/or services, develop customer ' +
        'relations, increase revenue streams thereby increasing profits and sustainability of the business.',
    },
    {
      title: 'How do you effectively outreach?',
      description:
        'Formulate a strategy, establish contact with influencers in your market place, develop ' +
        'relationships, create amazing, relevant and authoritative content to target audiences, partners ' +
        'and potential customers.',
    },
    {
      title: 'How do I get my blog noticed?',
      description:
        'Optimise the blog/website, constantly create new, authentic, relevant and authoritative ' +
        'content, leverage social media channels, speak the language of your target audience...or ' +
        'alternatively, engage Ocere to get your blog noticed fast.',
    },
    {
      title: 'How do you become a blogger influencer?',
      description:
        'Know your market and target audience, develop your content strategy, grow your network ' +
        'by engaging your followers and get your work shred by your followers. Alternatively, engage Ocere ' +
        'to get your blog noticed fast.',
    },
    {
      title: 'How can I get free content for my blog?',
      description:
        'Create and publish the content yourself. However, creating engaging, relevant optimised ' +
        'content takes a lot of time and research. While you are doing that you will not be able to work on ' +
        'any other element of your online marketing strategy.',
    },
    {
      title: 'What is email outreach?',
      description:
        'Email outreach is a process of sending out unsolicited emails to influencers – the main ' +
        'aim is to get them to review your website, products or services, or maybe gain links. Be careful ' +
        'you do not fall foul of current data protection legislation.',
    },
    {
      title: 'How do I find blogs?',
      description:
        'You can search online via Google, Bing or Yahoo for example, visit a blog directory, use ' +
        'Tumblr etc, or alternatively, use Ocere’s blogger outreach service or white label SEO service to ' +
        'cut out all the hard work.',
    },
    {
      title: 'What is outreach SEO outreach?',
      description:
        'Blogger outreach SEO outreach entails elements of email outreach, influencer outreach, ' +
        'social outreach and PR blogger outreach to people to get them to click on links pointing to your ' +
        'website, or to publish links on their websites which point to your website.',
    },
    {
      title: 'What is Backlink outreach?',
      description:
        'Backlink outreach involves reaching out, by email or other media, to bloggers, social ' +
        'media influencers and other website owners or managers, to proactively acquiring backlinks on ' +
        'their websites.',
    },
    {
      title: 'What is content outreach?',
      description:
        'Put simply, content outreach is a method of directly promoting your content which is ' +
        'intended to be presented to influential people whose audiences are similar to yours, so they will ' +
        'promote your content to their audiences.',
    },
    {
      title: 'How do you improve SEO outreach?',
      description:
        'Constantly investigate external links pointing to your website. Ensure none are broken. ' +
        'Search out sites suitable for SEO outreach which have good metrics. Keep on top of your ' +
        'competitors’ SEO outreach strategy. Keep building new relationships.',
    },
    {
      title: 'What is the best SEO outreach strategy?',
      description:
        'Reach out to owners of websites and blog sites. Know and understand your audience. ' +
        'Create and distribute amazing, relevant and authoritative content. Match content to websites. ' +
        'Employ social media channels to amplify and sustain your content.',
    },
    {
      title: 'How do I find blogs for SEO outreach?',
      description:
        'There are many directories which you can use to search out potential link sites or ' +
        'blogs – also use Google, Yahoo or Bing as a search tool. Alternatively, engage Ocere’s blogger ' +
        'outreach service and save yourself time and money.',
    },
    {
      title: 'How can I get free quality backlinks?',
      description:
        'Research and compile a list of bloggers and influencers relevant to your niche. Create ' +
        'relevant content which will appeal to them. Reach out to them. Hope they publish your content and ' +
        'link to your website.',
    },
    {
      title: 'How can I get free backlinks?',
      description:
        'See the answer to the question – ‘How can I get free quality backlinks?’',
    },
    {
      title: 'Do backlinks still work 2021?',
      description: 'The simple answer is Yes!',
    },
    {
      title: 'How do I find backlinks to my site?',
      description:
        'You can use industry standard tools such as Moz, AhRefs, Majestic and SEMRush etc, or ' +
        'you could engage Ocere’s blogger outreach service which includes finding backlinks.',
    },
    {
      title: 'How do I find my competitor backlinks?',
      description:
        'You can use industry standard tools such as Moz, AhRefs, Majestic and SEMRush etc, or ' +
        'you could engage Ocere’s blogger outreach service which includes finding backlinks.',
    },
    {
      title: 'How do I get thousands of backlinks?',
      description:
        'By hard work, lots of research, continuous blogger outreach and lots of written content ' +
        'submitted for publication. Alternatively, make it easy on yourself and work smarter with Ocere’s ' +
        'blogger outreach service.',
    },
    {
      title: 'How do I know if I have bad backlinks?',
      description:
        'By using industry standard tools such as Moz, AhRefs etc, or by engaging Ocere’s blogger ' +
        'outreach service to work on determining where and what bad links you have then developing a ' +
        'strategy for clearing them.',
    },
  ],
} as Service;
