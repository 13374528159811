import {Index} from '@/content/pages/persona';

export default {
  url: '/services/online-reputation-management',
  meta: {
    title: 'Online Reputation Management Services | Ocere [2021!]',
    description:
      'Online reputation management strategies for tangible, lasting results from an agency with more than 10 years experience. Click here for more info.',
  },
  green: {
    graphic: 'white-label',
    name: 'Online Reputation Management',
    catchphrase: 'Online Reputation Management',
    heading: 'Control and protect your digital reputation',
    paragraph: [
      'The strength of your digital reputation is built upon your brand’s performance in search results. A high ranking drives organic traffic, but poor social media activity, unfavourable reviews and a limited backlink profile will not only discourage human audiences, but search engine algorithms, too.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>At Ocere, our online reputation management services handle all aspects of improving and maintaining your digital presence, from our initial site, link and social media analysis to the creation of high quality content and backlinks designed to optimise the position your brand holds within organic search results.</p>' +
      '<h2 class="c-h2">What Does Online Reputation Management Include?</h2>' +
      '<p>Your digital public image is largely shaped by factors such as your authority on SERPs, content quality, the strength of your social media presence, and the overall impression left by customer reviews, and much more. A campaign that incorporates each of these elements and works to create a stronger and more positive impact through the creation of high quality content, links, press releases and postings.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver ' +
      'them. Many of our clients have had frustrating experiences with other agencies and are delighted ' +
      'to discover an entirely different experience when working with Ocere. If you are a digital ' +
      'marketing agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve ' +
      'your service to clients, have you considered white label SEO outreach service? If you are working ' +
      'as a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'Why is Online Reputation Management Important?',
      description:
        'Not only will negative publicity impact the prospective and existing clients, a poor online reputation will act as a significant handicap within search results. <a href="https://www.statista.com/statistics/262946/share-of-the-most-common-languages-on-the-internet/">Insight from Moz</a> indicates that more than 20% of prospective customers will be deterred from a brand if they encounter just one negative article during their search. Both Google and its users search for certain benchmarks of quality in your content and online activity; if these are not met, you will lose organic traffic, and Google will demote your site to an unenviable position on search results pages. With a comprehensive and far-reaching campaign, your digital presence can realise its full potential. For this reason, online reputation management is of critical importance to your brand.',
    },
    {
      title: 'Do Online Reputation Management Services Work?',
      description:
        'At Ocere, we have been creating targeted campaigns for thousands of clients for more than ten years. Our success has been underpinned by a modern approach to digital PR, an emphasis on <a target="_blank" href="https://www.ocere.com/services/blogger-outreach">quality creative content and SEO outreach</a>, and the need to create strategies that achieve long term, tangible results for our clients. Our online reputation management services offer the most effective solution to establishing strong and sustainable influence within your market.',
    },
    {
      title: 'How Long Does it Take to Build a Reputation?',
      description:
        'Lasting change takes time. Google’s algorithms are designed in such a way that they do not facilitate positive, overnight change — and any practices that promise rapid or instant results will likely damage your reputation just as quickly. Considerable work must be put into building trust and authority online. We typically expect to see our goal met within 5-7 months, though a thorough analysis of the specifics of your case will allow us to ascertain a more specific timeframe.',
    },
    {
      title: 'In What Industries Do You Specialise?',
      description:
        'We have had great success in providing creative and modern Digital PR solutions for clients in a wide range of industries and markets, including iGaming, tech, finance, blockchain, travel, and casino. Our multilingual team of writers have provided content for clients based all over the world, and we can tailor our services toward a diverse range of markets on request.',
    },
  ],
} as Index;
