





























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Service} from '@/content/pages/services';
import GetInTouch from '@/components/get-in-touch.vue';
import Explained from '@/components/services/explained.vue';
import ComparisonTablePackage from './comparison-table-package.vue';
// import ComparisonTableLinkPackage from './comparison-table-link-packages.vue';
import ComparisonTablePackageCC from './comparison-table-package-new.vue';
import Slider from './slider.vue';

@Component({
  components: {
    'comparison-table-package-cc': ComparisonTablePackageCC,
    ComparisonTablePackage,
    // ComparisonTableLinkPackage,
    Slider,
    GetInTouch,
    Explained,
  },
})
export default class extends Vue {
  @Prop() service!: Service;

  configNewUI = ['/services/content-creation'];
  configTouchUI = ['/services/blogger-outreach', '/services/link-packages'];

  get first() {
    return (this.service.packages || [])[0];
  }

  get checkNewPackageUI() {
    let flag = false;
    if (this.configNewUI.includes(this.service.url)) {
      flag = true;
    }
    return flag;
  }

  get checkTouchFormUI() {
    let flag = false;
    if (this.configTouchUI.includes(this.service.url)) {
      flag = true;
    }
    return flag;
  }
}
