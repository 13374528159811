import {Index} from '@/content/pages/persona';

export default {
  url: '/end-user',
  meta: {
    title: 'End User SEO & PPC Agency | SEO Outreach Services | Ocere',
    description:
      'Build a powerful online presence and grow your business with dependable end sser SEO and PPC services from Ocere. Increase sales and gain greater brand exposure today!',
  },
  green: {
    graphic: 'end-user',
    name: 'End User SEO/PPC',
    catchphrase: 'End User SEO/PPC',
    heading: 'Take your business to the next level',
    paragraph: [
      'Build a powerful online presence and grow your business with dependable digital marketing from ' +
        'Ocere. Increase sales, generate more leads, and gain greater brand exposure.',
      'Whether we’re taking over an in-house effort, replacing another agency, or creating an entirely new ' +
        'campaign – you can depend on us to keep things simple, whilst providing unparalleled expertise and ' +
        'meaningful results.',
    ],
  },
  content: {
    services: ['SEO Packages', 'Google Ads Packages', 'Blogger Outreach'],
    'How does it work?':
      '<p>Simply place an order for one of our prebuilt packages through our online ' +
      'system, which provides detailed information to help you decide which is right for you. Once we ' +
      'receive your order, we’ll begin work on your PPC, SEO or SEO outreach campaign right away, and ' +
      'provide login details to a private portal where you can keep track of everything. If you need ' +
      'advice on which services will best help you achieve your goals, or a more bespoke solution, get in ' +
      'touch today.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver them. ' +
      'Many of our clients have had frustrating experiences with other agencies and are delighted to ' +
      'discover an entirely different experience when working with Ocere. If you are a digital marketing ' +
      'agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve your ' +
      'service to clients, have you considered white label SEO outreach service? If you are working as a ' +
      'freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
} as Index;
