import {Index} from '@/content/pages/persona';

export default {
  url: '/services/emea-seo',
  meta: {
    title: 'EMEA SEO Services | Ocere [2021!] ',
    description:
      'Ocere’s modern EMEA SEO services ensure that your virtual presence is primed to succeed within valuable and diverse online audiences. Click here to learn more.',
  },
  green: {
    graphic: 'white-label',
    name: 'EMEA SEO',
    catchphrase: 'EMEA SEO',
    heading: 'Gain high-quality links and mentions to your website',
    paragraph: [
      'The region comprising Europe, the Middle East and Africa represents a substantial market, and optimising your online presence to engage with these significant and diverse audiences will mark a significant step forward for your brand.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>While the fundamental methodology for SEO remains largely consistent with international audiences, a strategy targeting populations so culturally and geographically diverse requires a wide scope in order to account for language barriers and cultural differences. Keyword research needs to be targeted, as does creative content – semantic differences must be handled carefully in order to provide audiences with content that feels authentic, and cultural nuances must be noted within every region being pursued through SEO. Similarly, technical aspects of your site must be optimised for international audiences.</p>' +
      '<p>At Ocere, our EMEA services focus on augmenting all aspects of international SEO, in order to ensure that your strategy is both viable, and successful. Gaining a foothold on SERPs beyond your local market requires considerable expertise in international SEO, which is what we have been to our substantial client base for more than ten years.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver ' +
      'them. Many of our clients have had frustrating experiences with other agencies and are delighted ' +
      'to discover an entirely different experience when working with Ocere. If you are a digital ' +
      'marketing agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve ' +
      'your service to clients, have you considered white label SEO outreach service? If you are working ' +
      'as a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'What Does SEO Include?',
      description:
        'All practices required to ensure that your site is set up for internet users and Google’s algorithms, in order to make a strong impact within search engine results and see significant growth in your site’s organic traffic. Our services include thorough keyword and competitor research – both of which will vary across regions – as well as high quality creative content, site health analysis, social signals, and link profile analysis and augmentation.',
    },
    {
      title: 'How Can I Promote My Website in Another Country?',
      description:
        'By utilizing a strong, <a target="_blank" href="https://www.ocere.com/services/seo-packages">well-informed SEO strategy</a> backed by comprehensive and fresh market analysis and competitor research. The competition for organic search traffic is high, and ensuring that you are implementing a strategy that specifically targets a high-ranking position with relevant keywords is the only way to get ahead of your competitors. Similarly, one of the best ways to ensure that you are engaging foreign internet users is to create strong content written by natural speakers; a simple translation is transparent and limited in its ability to appeal to readers, which is why all of <a target="_blank" href="https://www.ocere.com/services/content-creation">Ocere’s content creators</a> work in their native language.',
    },
    {
      title: 'What Does Organic Traffic Mean?',
      description:
        'Organic traffic represents the visitors your site garners through targeted online searches. Sites that rank highly within search engine results are more than likely to see the highest levels of organic traffic, which will continue to augment their perceived authority with Google.',
    },
    {
      title: 'What is the Purpose of Keyword Research?',
      description:
        'Keywords and phrases comprise the most lucrative search terms applicable to your site, for the purpose of attracting traffic that is most relevant to you. Through strong research, it is possible to identify these keywords and implement them within your content. This will demonstrate to Google’s complex algorithm – and to your prospective audiences – that you offer a valuable resource worthy of a high ranking on SERPs.',
    },
    {
      title: 'What Industries Do You Work With?',
      description:
        'Over the last 10+ years, we have worked with a wide variety of clients from a range of backgrounds. Some of our greatest strengths lie in iGaming, casino, Fintech, Blockchain and AI, in addition to travel, start-ups, financial services, and much more.',
    },
  ],
} as Index;
