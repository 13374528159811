<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 256 256"
  >
    <path
      :fill="color"
      mask="url('#s')"
      d="M38 21.99c20.33-14.67 49.08-15.93 70.73-3.38 24.41-4.23 50.22-.21 72 11.66 24.31 13.06 43.4 35.49 52.29 61.63 6.11 17.69 7.53 36.92 4.35 55.35 7.82 13.75 10.53 30.3 7.36 45.81-3.35 17.11-13.9 32.62-28.49 42.15-20.2 13.58-47.95 14.35-68.99 2.17-23.43 4.03-48.14.54-69.34-10.28-24.64-12.38-44.29-34.28-53.9-60.13-6.96-18.47-8.71-38.79-5.4-58.23-7.99-13.99-10.58-30.9-7.17-46.64C14.82 46.02 24.55 31.41 38 21.99z"
    />
    <mask id="s">
      <rect x="0" y="0" width="100%" height="100%" :fill="color" />
      <path
        fill="black"
        d="M113.41 60.77c15.99-2.42 32.84.03 47.11 7.77 6.24 3.46 12.28 8.14 15.18 14.86 1.89 4.42 2.1 10.19-1.39 13.89-3.78 3.87-9.95 5.06-15.02 3.43-5.87-2.46-8.79-8.61-13.77-12.27-6.26-5.16-14.69-6.55-22.58-6.41-5.77.25-11.95 1.56-16.16 5.8-3.89 4.15-5.33 11.26-1.54 15.93 3.58 4.13 9.11 5.67 14.18 7.08 9.67 2.42 19.45 4.37 29.16 6.66 10.1 2.31 20.3 6.73 26.97 14.96 11.5 14.9 8.91 38.53-5.39 50.73-11.44 10.13-27.35 12.93-42.17 13.02-15.82-.23-32.73-3.47-44.89-14.29-6.1-5.5-11.23-13.8-9.39-22.29 2.01-8.44 13.6-11.64 20.17-6.45 5.02 4.03 7.74 10.16 12.58 14.37 5.79 5.31 13.86 7.42 21.58 7.23 7.57.2 15.61-2.12 20.8-7.88 3.53-3.89 5.4-9.81 3.11-14.79-1.88-4.68-6.71-7.23-11.33-8.49-8.52-2.23-17.1-4.26-25.62-6.51-9.36-2.29-19.09-4.38-27.14-9.96-6.28-4.17-11.04-10.68-12.57-18.1-2.57-11.64-.73-25.07 7.73-33.96 7.74-8.61 19.22-12.61 30.39-14.33z"
      />
    </mask>
  </svg>
</template>

<script>
export default {
  props: {
    name: 'skype',
    color: {
      default: 'white',
    },
  },
};
</script>
