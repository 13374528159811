import { render, staticRenderFns } from "./lined-bullet-points.vue?vue&type=template&id=0b422b9e&scoped=true&"
import script from "./lined-bullet-points.vue?vue&type=script&lang=ts&"
export * from "./lined-bullet-points.vue?vue&type=script&lang=ts&"
import style0 from "./lined-bullet-points.vue?vue&type=style&index=0&id=0b422b9e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b422b9e",
  null
  
)

export default component.exports