import {Blog} from '@/content/blogs/model';

export default {
  url: 'link-building-without-duplication',
  title: 'Link Building Without Duplication',
  category: 'Link Building',
  // metaTitle: '',
  // metaDescription:'',
  thumbnail:
    'https://images.ctfassets.net/p5gt7dkd4wn3/3iAZBKka3uDE3SYg5qQ72N/4fa285a703740c01e610d62dbce50bfd/link-building-14.jpg',
  description: [
    {
      type: 'paragraph',
      title: 'Link Building Without Duplication',
      content: [
        'A recent audit by <a  href="https://raventools.com/blog/duplicate-content/">Raven</a> found that 29% of the 200 million pages checked featured some duplicated content, and just over 20% of titles were repeated elsewhere. It is only natural that the quality, relevance and value of this content differs drastically between sites - are Google\'s algorithms not already prepared for some duplication, and capable of sifting the good from the bad?',
      ],
    },
    {
      type: 'paragraph',
      title: 'What Really is Duplicate Content?',
      content: [
        'External duplicate content refers to instances of identical or <a href="https://support.google.com/webmasters/answer/66359?hl=en">‘appreciably similar’</a>’ content appearing on two or more sites. While external content is an excellent tool for augmenting your online presence and attracting greater numbers to your site through high-quality information and other content, it\'s originality and value remain important not only to readers, but to Google. ',
      ],
    },
    {
      type: 'image',
      url:
        'https://images.ctfassets.net/p5gt7dkd4wn3/2wotBPC4IVwUNixeZaYcDT/e32b921f253c37948c2eff39ecf4ede5/Link_Building_6.jpg',
    },
    {
      type: 'paragraph',
      title: 'Why is Duplicate Content Bad for SEO?',
      content: [
        `Google has well over 100 Trillian pages in it's index, and the fact that there is bound to be a fair amount of similarity, duplication, and accidental reprinting means that there are no specific penalties in place for duplication. That said, it does still pose a problem: the algorithm is in place to ensure only high quality resources are displayed on SERP's, and it will likely impact your rankings to varying degrees.`,
        'When content duplication occurs, then rather than creating two or more unique and high quality pieces of content, you are effectively diluting the merit of one piece between two or more external sites, thus making it less valuable than one piece of content used on just one site. Google will struggle to decide which site is more valuable on the SERP, which will likely result in neither one attaining a high ranking. As with many aspects of effective SEO, quality over quantity is generally considered a much more lucrative approach.',
      ],
    },
    {
      type: 'image',
      url:
        'https://images.ctfassets.net/p5gt7dkd4wn3/1KO8a3purWnOHYHydtmtjn/be3038a526088cca32715afcdcc1aedf/Link_Building8.jpg',
    },
    {
      type: 'paragraph',
      title: 'How to Avoid Content Duplication',
      content: [
        'Total originality, of course, is simply not possible - and this is proven by the staggering proportion of identical content available online. There are a number of methods for preventing duplicate content from derailing your SEO strategy, such as letting Google know not to index the page through meta tags. This will prevent the page from being included on SERPs, and prevent it from clashing with other pages that contain the same piece of content.',
        'Typically, however, the simplest solution is also the most obvious. Investing more into the creation of high quality content that speaks for itself will offer readers valuable information, and ultimately serves to bring greater numbers of traffic to your site.',
        `Your external content and link building strategy exists in order to demonstrate to readers - and, of course, Google's algorithm - that your site offers a wealth of relevant, up-to-date information up to the task of completing with similar, high ranking sites. Duplication offers speed over substance, and although not directly penalised by Google's terms of use, it runs the risk of harming your site's ranking in the long run.`,
      ],
    },
  ],
} as Blog;
