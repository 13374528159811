import {apiClient, handleResp} from './utils';
import moment from 'moment';
const TicketService = {
  doGetTickets() {
    return handleResp(apiClient().get(`/tickets`));
  },
  doGetAllTickets() {
    return handleResp(apiClient().get(`/tickets/all`));
  },
  doGetTicket(ticketId) {
    return handleResp(apiClient().get(`/tickets/${ticketId}`));
  },
  doCreateTicket(ticket: any) {
    return handleResp(apiClient().post(`/tickets`, {ticket}));
  },
  doUpdateTicket(ticketId, ticket) {
    return handleResp(apiClient().patch(`/tickets/${ticketId}`, {ticket}));
  },
  doCancelTicket(ticketId) {
    return handleResp(apiClient().delete(`/tickets/${ticketId}`));
  },
  formatInfo(ticket) {
    return {
      id: ticket.id,
      subject: ticket.subject,
      detail: ticket.detail,
      group: ticket.in_group,
      status: ticket.status,
      useId: ticket.user_id,
      orderId: ticket.order_id,
      createdAt: moment(ticket.created_at).local().format('Do MMM YYYY'),
      dueDate: ticket.due_date
        ? moment(ticket.due_date).local().format('yyyy-MM-DD')
        : null,
      dueDateDpl: ticket.due_date
        ? moment(ticket.due_date).local().format('Do MMM YYYY')
        : null,
      notes: ticket.notes,
      user: ticket.user,
    };
  },
};
export default TicketService;
