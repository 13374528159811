import {Blog} from '@/content/blogs/model';

export default {
  url: 'do-you-really-need-online-reputation-management',
  title: 'Do You Really Need Online Reputation Management?',
  category: 'Reputation Management',
  // metaTitle: '',
  // metaDescription:'',
  thumbnail:
    'https://images.ctfassets.net/p5gt7dkd4wn3/5cCn36xib0HYUYTPCf1TEc/e106f5521a794ca0532363515bf933e5/Reputation_Management.jpg',
  description: [
    {
      type: 'paragraph',
      title: 'Do You Really Need Online Reputation Management?',
      content: [
        'Now more than ever, your online reputation has a significant hand in influencing your ability to engage with internet users. From your social media presence to dedicated review sites, continued vigilance is essential to ensuring that relevant searches do not yield negative results.',
        'Certain aspects of your online presence remain largely out of your hands; reviews and negative attention from news sites will make up a certain percentage of any brand’s online profile, from a local store with nothing but a fleeting presence on social media, to the world’s largest corporations.',
        'Back in the days before the internet, a negative review had limited scope for damage; it relied on readers all hitting upon the same article – tomorrow’s recycling – or, of course, word of mouth. Nowadays, any negative attention remains on the web – and has the constant potential to end up high in the rankings of branded searches or, worse still, go viral.',
      ],
    },
    {
      type: 'image',
      url:
        'https://images.ctfassets.net/p5gt7dkd4wn3/3OcM69sEgpArYHNULEP5Kx/c1a034a14d82ee331ad3600dde3147f8/Reputation.jpg',
    },
    {
      type: 'paragraph',
      title: 'Mitigating Negative Attention',
      content: [
        'Your visibility on Google’s results page is largely dependent on trust; Google’s algorithm wants to trust you are authentic and valuable enough to rank highly, much like internet users visiting your site. While both audiences have, for the most part, different benchmarks by which they judge your site’s value, they are reciprocal. Greater levels of organic traffic influence rankings, and rankings influence your ability to garner this traffic.',
        'Building trust with both camps is done through reputation management. For instance, no one knows the extent to which reviews impact SEO, but <a  href="https://support.google.com/business/answer/7091?hl=en">Google’s support page</a> has made clear that:',
        '<i>“Responding to reviews shows that you value your customers and the feedback that they leave about your business. High-quality, positive reviews from your customers will improve your business’s visibility and increase the likelihood that a potential customer will visit your location.”</i>',
        'Reviews account for just one aspect of your online reputation, and all aspects stand a high chance of influencing your ability to engage with internet users and grow traffic – a factor which categorically influences search rankings.',
        'Critical or damaging news are an obvious contender for doing harm; if your brand is mentioned explicitly, then this content stands a high chance of appearing on branded searches alongside your own site, or being disseminated on social media channels.',
      ],
    },
    {
      type: 'image',
      url:
        'https://images.ctfassets.net/p5gt7dkd4wn3/EtkPNq05gwnmyA2nGdeE9/a3dae6be33e70ae255dddc9b19ea1a79/Reputation2.jpg',
    },
    {
      type: 'paragraph',
      title: 'Generating Positive Attention',
      content: [
        'The other side to online reputation management – augmenting your presence through the creation of positive content, improving social media usage, and giving greater weight to your brand in ways that will appeal to high quality audiences – is of equal importance.',
        'Rather than sitting idly until negative attention is sent your way, the best method for ensuring a fundamentally positive online reputation is one that consistently seeks to make the most of the platform itself. Online reputation management is an ongoing process which seeks to create a diverse and affirmative portfolio that dominates your online presence – and, in a more practical sense, makes a steady impact on your search engine rankings through the creation of backlinks, and the use of well-researched keywords.',
        'It is often said that your online reputation remains largely out of your hands, and this may well be true for brands experiencing an unexpected slew of negative attention. But, with a committed and deliberate approach to growing your online presence through positive reinforcement and a strong emphasis on quality, creating a strong reputation on SERPs and within relevant markets is entirely possible.',
      ],
    },
  ],
} as Blog;
