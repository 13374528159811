import {apiClient, handleResp} from './utils';
import {startCase, map, get} from 'lodash';
import moment from 'moment';
import {separateNumber} from '@/utils';
const OrderService = {
  doGetOrders() {
    return handleResp(apiClient().get('/orders'));
  },
  doGetOrder(id: string) {
    return handleResp(apiClient().get('/orders/' + id));
  },
  doCreateOrder(data: any) {
    return handleResp(apiClient().post('/orders/', data));
  },
  doUpdateOrder(id, data) {
    return handleResp(apiClient().patch('/orders/' + id, data));
  },
  doCancelOrder(id) {
    return handleResp(apiClient().delete('/orders/' + id));
  },
  formatInfo(order) {
    return {
      id: order.id,
      orderNumber: order.tracking_number,
      startDate: moment(order.start_date).local().format('Do MMM YYYY'),
      endDate: moment(order.end_date).local().format('Do MMM YYYY'),
      paymentStatus: startCase(order.status),
      service: startCase(order.service),
      notes: order.notes,
      orderedLinkBuilding: get(order, 'ordered_link_buildings', []),
      orderedContentCreation: get(order, 'ordered_content_creations', []),
      totalCost: `£ ${separateNumber(order.total_cost)}`,
    };
  },
};
export default OrderService;
