<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="4 4 43 43"
  >
    <path
      d="M4.867 43.305l2.696-9.836a18.934 18.934 0 01-2.536-9.489C5.031 13.516 13.547 5 24.016 5a18.882 18.882 0 0113.43 5.566A18.86 18.86 0 0143 23.992c-.004 10.469-8.523 18.98-18.984 18.98-.004 0 0 0 0 0h-.008c-3.18 0-6.301-.796-9.074-2.308zm0 0"
      mask="url('#w')"
      :fill="color"
    />
    <path
      d="M19.27 16.047c-.356-.793-.73-.809-1.07-.82-.278-.012-.595-.012-.91-.012-.313 0-.83.117-1.263.594-.437.472-1.664 1.62-1.664 3.957 0 2.332 1.703 4.59 1.938 4.906.238.312 3.285 5.258 8.105 7.16 4.008 1.578 4.824 1.266 5.692 1.188.87-.083 2.808-1.149 3.203-2.258.394-1.106.394-2.055.277-2.254-.117-.2-.433-.317-.91-.555-.473-.238-2.805-1.383-3.242-1.543-.434-.156-.75-.234-1.067.238-.316.473-1.226 1.543-1.504 1.86-.277.316-.55.355-1.027.12-.473-.237-2-.737-3.812-2.355-1.41-1.257-2.364-2.808-2.641-3.285-.277-.472-.031-.73.207-.968.215-.211.477-.555.715-.832.234-.278.312-.473.473-.79.156-.316.078-.593-.04-.832-.117-.238-1.039-2.582-1.46-3.52zm0 0"
      :fill="color"
    />
    <mask id="w">
      <rect x="0" y="0" width="100%" height="100%" :fill="color" />
      <path
        d="M35.176 12.832A15.676 15.676 0 0024.02 8.207c-8.704 0-15.786 7.074-15.79 15.773 0 2.98.836 5.883 2.415 8.395l.375.598-1.594 5.82 5.972-1.566.579.343c2.421 1.438 5.199 2.2 8.03 2.2h.009c8.695 0 15.773-7.079 15.777-15.778a15.66 15.66 0 00-4.617-11.16zm0 0"
        fill="black"
      />
    </mask>
  </svg>
</template>

<script>
export default {
  props: {
    name: 'whats-app',
    color: {
      default: 'white',
    },
  },
};
</script>
