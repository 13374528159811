import Vue from 'vue';
import Router from 'vue-router';
import {
  persona,
  linkBuilding,
  contentCreation,
  managedCampaigns,
  blogs,
} from '@/content';
import {link_building, multilingual_seo} from '@/content/pages/persona';
import {blogger_outreach} from '@/content/pages/link-building';
import {includes} from 'lodash';

import Home from '@/views/Home.vue';
import About from '@/views/About.vue';
import Persona from '@/views/Persona.vue';
import Service from '@/views/Service.vue';
import Services from '@/views/Services.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import TermsAndConditions from '@/views/TermsAndConditions.vue';
import GetInTouch from '@/views/GetInTouch.vue';
import {empty} from '@/utils';

import store from '@/store';
import ThankYou from '@/views/ThankYou.vue';
import SiteMap from '@/views/SiteMap.vue';

Vue.use(Router);
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from): any {
    if (to.hash) {
      return new Promise(resolve =>
        setTimeout(() => {
          const el = document.querySelector(to.hash);
          if (el) {
            el.scrollIntoView({
              behavior: 'smooth',
            });
          }
          resolve(null);
        }, 1)
      );
    } else {
      return {x: 0, y: 0};
    }
  },
  routes: [
    {path: '/', component: Home},
    // {
    //   path: '/login',
    //   component: () => import('@/views/Login.vue'),
    // },
    // {
    //   path: '/signup',
    //   component: () => import('@/views/SignUp.vue'),
    //   meta: {
    //     isDoLogout: true,
    //   },
    // },
    // {
    //   path: '/confirmation',
    //   meta: {
    //     isDoLogout: true,
    //   },
    //   component: () => import('@/views/Confirmation.vue'),
    // },
    // {
    //   path: '/forgot-password',
    //   meta: {
    //     isDoLogout: true,
    //   },
    //   component: () => import('@/views/ForgotPassword.vue'),
    // },
    // {
    //   path: '/reset-password',
    //   meta: {
    //     isDoLogout: true,
    //   },
    //   component: () => import('@/views/ResetPassword.vue'),
    // },
    // {
    //   path: '/setup-password',
    //   meta: {
    //     isDoLogout: true,
    //   },
    //   component: () => import('@/views/ResetPassword.vue'),
    // },
    // {
    //   path: '/billing',
    //   name: 'Billing',
    //   component: () => import('@/views/Billing.vue'),
    // },
    // {
    //   path: '/client-dashboard',
    //   component: () => import('@/views/ClientDashboard.vue'),
    //   meta: {
    //     authRequired: true,
    //     isDashboard: true,
    //   },
    //   children: [
    //     {
    //       path: '',
    //       name: 'Orders',
    //       meta: {
    //         exclude: ['provider'],
    //       },
    //       component: () => import('@/components/orders/order-list.vue'),
    //     },
    //     {
    //       path: 'account',
    //       name: 'My Account',
    //       // redirect: '/client-dashboard/account',
    //       component: () => import('@/components/client/client-profile.vue'),
    //     },
    //   ],
    // },
    {
      path: '/blog',
      component: () => import('@/views/Blog.vue'),
      children: [
        {
          path: '',
          name: 'Blog',
          component: () => import('@/components/blog/blog.vue'),
        },
        ...blogs.map(blog => ({
          // @ts-ignore
          path: blog.url,
          props: {blog},
          component: () => import('@/components/blog/blog-detail.vue'),
        })),
        // {
        //   path: ':slug',
        //   name: 'Blog Detail',
        //   component: () => import('@/components/blog/blog-detail.vue'),
        // },
      ],
    },
    // {
    //   path: '/order',
    //   name: 'Order',
    //   meta: {
    //     isDashboard: true,
    //     authRequired: true,
    //     exclude: ['provider'],
    //   },
    //   component: () => import('@/views/ClientDashboard.vue'),
    //   children: [
    //     {
    //       path: ':orderId',
    //       name: 'Order Detail',
    //       component: () => import('@/components/orders/order-detail.vue'),
    //     },
    //   ],
    // },

    // {
    //   path: '/tickets',
    //   meta: {
    //     isDashboard: true,
    //     authRequired: true,
    //     exclude: ['provider', 'client'],
    //   },
    //   component: () => import('@/views/ClientDashboard.vue'),
    //   children: [
    //     {
    //       path: '',
    //       name: 'Ticket',
    //       component: () => import('@/components/ticket/ticket.vue'),
    //     },
    //     {
    //       path: ':ticketId',
    //       name: 'Ticket Detail',
    //       component: () => import('@/components/ticket/ticket-detail.vue'),
    //     },
    //   ],
    // },
    // {
    //   path: '/client',
    //   meta: {
    //     isDashboard: true,
    //     authRequired: true,
    //     exclude: ['client', 'provider'],
    //   },
    //   component: () => import('@/views/ClientDashboard.vue'),
    //   children: [
    //     {
    //       path: '',
    //       name: 'Information',
    //       component: () => import('@/components/client/information.vue'),
    //     },
    //     {
    //       path: 'create',
    //       name: 'Create Client',
    //       // redirect: '/client-dashboard/account',
    //       component: () => import('@/components/client/client-profile.vue'),
    //     },
    //     {
    //       path: 'update/:id',
    //       name: 'Update Client',
    //       // redirect: '/client-dashboard/account',
    //       component: () => import('@/components/client/client-profile.vue'),
    //     },
    //     {
    //       path: ':clientId/order/:orderId',
    //       name: 'Client Order Detail',
    //       component: () => import('@/components/orders/order-detail.vue'),
    //     },
    //     {
    //       path: ':clientId',
    //       name: 'Client Detail',
    //       component: () => import('@/components/client/client-detail.vue'),
    //     },
    //   ],
    // },
    // {
    //   path: '/lead',
    //   meta: {
    //     isDashboard: true,
    //     authRequired: true,
    //     exclude: ['client', 'operations', 'provider'],
    //   },
    //   component: () => import('@/views/ClientDashboard.vue'),
    //   children: [
    //     {
    //       path: '',
    //       component: () => import('@/components/lead/lead.vue'),
    //       name: 'Lead',
    //     },
    //     {
    //       path: ':slug',
    //       name: 'Lead Detail',
    //       component: () => import('@/components/lead/lead-detail.vue'),
    //     },
    //   ],
    // },
    // {
    //   path: '/create',
    //   component: () => import('@/views/Blank.vue'),
    //   meta: {
    //     name: 'Create',
    //   },
    //   children: [
    //     {
    //       path: '',
    //       name: 'Create',
    //       component: () => import('@/views/CreateTemplate.vue'),
    //     },
    //     {
    //       path: 'managed-service',
    //       name: 'Create Managed Service',
    //       component: () => import('@/views/CreateManagedService.vue'),
    //     },
    //     {
    //       path: 'blogger-outreach',
    //       name: 'Blogger Outreach',
    //       component: () => import('@/views/BloggerOutreach.vue'),
    //     },
    //     {
    //       path: 'content-writing',
    //       name: 'Content Writing (Standard)',
    //       component: () => import('@/views/ContentWriting/index.vue'),
    //     },
    //     {
    //       path: 'content-writing-pro',
    //       name: 'Content Writing (Pro)',
    //       meta: {
    //         isContentPro: true,
    //       },
    //       component: () => import('@/views/ContentWriting/index.vue'),
    //     },
    //     {
    //       path: 'order-summary',
    //       name: 'Summary',
    //       component: () => import('@/views/OrderSummary.vue'),
    //     },
    //   ],
    // },
    {path: '/thank-you', component: ThankYou},
    {path: '/sitemap', component: SiteMap},
    {path: '/about-us/:selected?', component: About},
    {path: '/privacy-policy', component: PrivacyPolicy},
    {path: '/get-in-touch', component: GetInTouch},
    {path: '/terms', component: TermsAndConditions},
    {path: '/services', component: Services},
    ...persona.map(persona => ({
      path: persona.url,
      props: {persona},
      component: Persona,
    })),
    ...linkBuilding.map(service => ({
      path: service.url,
      props: {service},
      component: Service,
    })),
    ...contentCreation.map(service => ({
      path: service.url,
      props: {service},
      component: Service,
    })),
    ...managedCampaigns.map(service => ({
      path: service.url,
      props: {service},
      component: Service,
    })),
    {
      path: '/content/sleep-infographic',
      name: 'Sleep Infographic',
      component: () => import('@/views/SleepInfographic.vue'),
    },
    {
      path: '/us/link-building',
      component: Persona,
      props: {persona: link_building},
    },
    {
      path: '/us/blogger-outreach',
      component: Service,
      props: {service: blogger_outreach},
    },
    {
      path: '/us/multilingual-seo',
      component: Persona,
      props: {persona: multilingual_seo},
    },
    {path: '*', beforeEnter: (to, from, next) => next('/')},
  ],
});

router.beforeEach((to, from, next) => {
  // Check needed logout
  if (to.matched.some(record => record.meta.isDoLogout)) {
    store.dispatch('doLogout');
  }

  if (
    to.matched.some(record => record.meta.authRequired) &&
    (empty(store.state.user) || empty(store.state.user.token))
  ) {
    return next({
      path: '/login',
    });
  }

  if (
    !empty(store.state.user) &&
    !empty(store.state.user.token) &&
    to.path === '/login'
  ) {
    return next({
      path: '/client-dashboard',
    });
  }

  // check permission
  if (
    to.matched.some(
      record =>
        !empty(record.meta.exclude) &&
        includes(record.meta.exclude, store.state.user.role)
    )
  ) {
    next('/');
  }

  const check = (pattern: string) => {
    const index = to.path.lastIndexOf(pattern);
    if (index > 0) {
      return index;
    }
  };
  const index = check('http://') || check('https://');
  if (index) {
    window.location.href = to.path.substring(index);
  } else {
    next();
  }
});

export default router;
