import {ManagedCampaigns} from '@/content/pages/managed-campaigns/index';

export default {
  icon: require('@/assets/icons/services/pricing_icon.png'),
  title: 'SEO Outreach',
  url: '/link-building',
  meta: {
    title: '',
    // TODO
  },
} as ManagedCampaigns;
