

































































































































































































import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/page-title.vue';

@Component({
  components: { PageTitle },
  metaInfo: {
    title: 'Our Privacy Policy | Ocere',
    meta: [
      {
        content: 'View the privacy policy for Ocere Ltd, including all information regarding the data we collect from you and what we do with it. Click here.',
        hid: 'description',
        name: 'description',
      },
    ],
  },
})
export default class extends Vue {}
