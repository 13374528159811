import {Blog} from '@/content/blogs/model';

export default {
  url: 'blogger-outreach--cutting-through-the-noise',
  title: 'Blogger Outreach: Cutting Through the Noise',
  category: 'Blogger Outreach',
  // metaTitle: '',
  // metaDescription:'',
  thumbnail:
    'https://images.ctfassets.net/p5gt7dkd4wn3/43LOSbQ1Bwl2SZlXsf8vlX/b1ce50eda055df1104c52d8fa56181bc/Blog_-_Thumbnail_7.jpg',
  description: [
    {
      type: 'paragraph',
      title: 'Blogger Outreach: Cutting Through the Noise',
      content: [
        'Digital PR - successfully compiling a varied and reliable network of bloggers, webmasters and publishers - is a vitally important part of optimising your online presence and seeing an impressive boost to your SERP ranking, and to the levels of organic traffic arriving on your site.',
        'With this network in place, the potential to augment your profile through a strong backlinking strategy grows significantly, but successful blogger outreach takes many hours - years, even - to pull off. ',
        'What makes blogger outreach so difficult is the sheer volume of requests webmasters receive each and every week for guest content containing valuable backlinks. Cutting through the noise of competitors and mass requests, and forging those vital connections to good quality sites, is no small feat. ',
        'It is for this reason that Private Blog Networks (PBNs) and paid links continue to be employed by some sites; though they risk being penalised by Google, they offer a fast, quantitative alternative to hours of blogger outreach.',
      ],
    },
    {
      type: 'image',
      url:
        'https://images.ctfassets.net/p5gt7dkd4wn3/1Acev73Hb9mjQgJY540TpC/918c8a92a9acc84899bf214691de49fb/Blogging.jpg',
    },
    {
      type: 'paragraph',
      title: 'What Makes it So Difficult to Get Right?',
      content: [
        'In 2018, Vuelo conducted a study into the UK blogger landscape, and found that just under 65% of bloggers stated that only one pitch a week (or less) lead to any content appearing on their blog. In the same study, it was revealed that 32% of bloggers were being contacted in excess of seven times a week.',
        `It is clear, then, why bloggers outreach can begin to feel like something of a fool's errand; the hours reaped are sometimes difficult to justify the rewards, which is why so many companies now turn to a dedicated Digital PR company with an exisiting network of webmasters, effectively sidestepping the need to deal with so much rejection and so many hours lost. `,
      ],
    },
    {
      type: 'image',
      url:
        'https://images.ctfassets.net/p5gt7dkd4wn3/3m5pb0OOU84Y0toLpP5O3K/f476528c2a145dd0bf0d791ae8bb4534/Blogger_-_New.jpg',
    },
    {
      type: 'paragraph',
      title: 'Standing Out',
      content: [
        `There are scores of 'How To' guides out there offering suggestions on improving results from blogger outreach, and building more successful connections through mass emails. The issues we face with outreach are the same we face with all aspects of SEO - an ostensibly formulaic approach does little to hide a lack of inherent value, and will quickly discredit your cause.`,
        'With an ever-growing network of over 20,000 website owners, bloggers and publishers, we have found an approach rooted in transparency offers the best results. Clarity and concision ensure better engagement; together, they offer an effective way of building a lasting blogger relationship through trust, and a more straightforward approach to Digital PR and seo outreach.',
        'Larger blogs, in particular, are overwhelmed by requests each week, and balancing personability and a discernible passion for your client with economy of language poses a difficult challenge to any blogger outreach team - and a successful approach will take considerably more time than many companies initially allow. And considering the fact that <a href="https://techjury.net/blog/blogging-statistics/#gref">almost 80% of internet users read blogs</a>, understanding how to employ this balance on a mass scale is more important than ever before to broadening your reach online, reaching a wider and more relevant audience, and, ultimately, improving those all-important SERP rankings. ',
      ],
    },
  ],
} as Blog;
