


















import moment from 'moment';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Service} from '@/content/pages/services';

@Component
export default class extends Vue {
  @Prop() service!: Service;

  get date() {
    return moment().add(this.service.days, 'days').format('dddd Do MMMM YYYY');
  }
}
