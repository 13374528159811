import {Index} from '@/content/pages/persona';

export default {
  url: '/services/ai-seo',
  meta: {
    title: 'AI SEO Services | Ocere [2021!] ',
    description:
      'Click here to read more about Ocere’s AI SEO services, which hold considerable experience in achieving tangible results for clients working in the AI industry.',
  },
  green: {
    graphic: 'white-label',
    name: 'AI SEO',
    catchphrase: 'AI SEO',
    heading: 'Gain high-quality links and mentions to your website',
    paragraph: [
      'Artificial intelligence represents one of the most exciting and versatile industries in the world. With the market for AI software expected to surpass $125 billion by 2026, and revolutionary applications for a diverse and ever-growing list of industries across the globe, AI offers a lucrative field in which global corporations and new start-ups alike compete to stand at the forefront of innovation.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>Consequently, a strong SEO strategy is essential to any site looking to maintain – and improve – their visibility online. Low rankings significantly limit your ability to capture the attentions of investors and relevant markets, and now more than ever it is imperative that you implement a sustainable and consistent approach toward strengthening your online presence.</p>' +
      '<p>At Ocere, our AI SEO services target the most valuable online markets through a creative and modern approach to all aspects of optimisation, both on- and off-site. We have achieved considerable success for thousands of clients, and focus on creating unique and innovative strategies for growth within the artificial intelligence industry.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver ' +
      'them. Many of our clients have had frustrating experiences with other agencies and are delighted ' +
      'to discover an entirely different experience when working with Ocere. If you are a digital ' +
      'marketing agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve ' +
      'your service to clients, have you considered white label SEO outreach service? If you are working ' +
      'as a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'Is SEO Still Relevant in 2021?',
      description:
        'Certainly – and particularly within AI, where new start-ups and global corporations alike are forever seeking to solidify their status within the industry, and to spearhead its advancement into so many areas of our lives. The competition for top billing on search engine results pages is higher than ever before, which means that your site demands a proactive, <a target="_blank" href="https://www.ocere.com/services/seo-packages">creative and far-reaching SEO strategy</a> in order to keep pace with – and outrank – competitors.',
    },
    {
      title: 'What is the Best Way to Do SEO For Your Website?',
      description:
        'SEO is an umbrella term for a number of different methodologies used to improve your site’s functionality, reach, and its authority, which is what directly impacts your ranking on Google’s search results. To that end, the best approach toward optimising your site is to take a comprehensive approach toward improving technical aspects, such as page-load speed and your XML sitemap, and focusing on turning it into a rich source of <a target="_blank" href="https://www.ocere.com/services/content-creation">high quality content</a>, reinforced by natural keyword usage.',
    },
    {
      title: 'What is Off Page SEO?',
      description:
        'Off page SEO comprises any strategic elements that extend beyond the boundaries of your own site, and seek to improve your presence within national or international audiences. This includes SEO outreach, social media marketing, and PPC management, all of which serve to boost your visibility to human audiences, and to Google’s algorithm.',
    },
    {
      title: 'Do You Hold Experience within the AI Industry?',
      description:
        'Yes, at Ocere we have achieved substantial results for sites operating within the AI industry, helping clients to improve their reach, authority and organic traffic levels through our modern and creative approach to SEO.',
    },
    {
      title: 'Is SEO Worth the Money?',
      description:
        'There are billions of indexed webpages online, and <a href="https://www.statista.com/statistics/941054/number-of-ai-companies-worldwide-by-country/#:~:text=The%20statistic%20shows%20the%20number,the%20United%20States%20was%202%2C028.">over 2,000 AI companies in the United States</a> alone. Nowadays, owning a website is not enough for your brand; considerable work needs to be put into extending your reach and augmenting your authority in order to ensure that you are not at risk of being overshadowed by your competitors. SEO is undoubtedly worth the investment. It comprises some of the most essential elements for any effectual business management strategy, and will serve to create the online platform you need for meeting your objectives.',
    },
  ],
} as Index;
