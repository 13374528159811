import {Index} from '@/content/pages/persona';

export default {
  url: '/services/blog-content-writing',
  meta: {
    title: 'Blog Content Writing Services | Ocere [2021!] ',
    description:
      'Ocere’s blog content writing services create high quality resources for relevant readerships, and boost your authority in search results. Click here for more.',
  },
  green: {
    graphic: 'white-label',
    name: 'Blog Content Writing',
    catchphrase: 'Blog Content Writing',
    heading: 'Gain high-quality links and mentions to your website',
    paragraph: [
      'Blogs represent an indispensable platform for sites. Not only do they allow you to engage and retain a highly valuable readership, but they also serve to boost your authority by providing important resources deserving of a high ranking within search engine results.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>Ocere’s blog content writing services focus on quality and creativity, from topic creation to execution. Beyond meeting requirements for keyword implementation and volume, our team of writers focus on producing work that will truly engage readers, not just appeal to search engine algorithms. We have massive capability in creating thoughtful, well-researched and original pieces in a considerable range of languages, all of which are written by native speakers.</p>' +
      '<h2 class="c-h2">What is the Purpose of Blogs?</h2>' +
      '<p>Blogs offer one of the most productive platforms for sites seeking to augment their authority within search engine results, offer well-written and creatively executed content that informs, guides and interests relevant audiences, post content that will be shared and credited by other authoritative sites, and not only boost organic traffic, but also engage that traffic and ensure that your brand has the voice it deserves.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver ' +
      'them. Many of our clients have had frustrating experiences with other agencies and are delighted ' +
      'to discover an entirely different experience when working with Ocere. If you are a digital ' +
      'marketing agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve ' +
      'your service to clients, have you considered white label SEO outreach service? If you are working ' +
      'as a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'Why Should You Have a Blog on Your Website?',
      description:
        'In terms of SEO, blogs demonstrate to Google’s algorithm that your site offers a valuable resource for those seeking information beyond the services your brand offers. You are able to address common queries, discuss relevant topics, and offer insight into areas in which your market holds interest. Essentially, your blog can seek to fulfil much of the criteria Google looks for when determining which sites deserve the highest rankings on SERPs. Blogs offer value to internet users in much the same way. They can also make your site easier to find on SERPs, and by driving up organic traffic through relevant searches, you can augment your status within the results themselves, and continue to see growth and greater engagement from a wider audience.',
    },
    {
      title: 'What Makes Content Shareable?',
      description:
        'Originality, relevance, clarity and succinctness. Consider the content you would like to find online, and be the site to put it out there – or let Ocere’s writing team do it for you.',
    },
    {
      title: 'How Do You Write a Blog Post 2021?',
      description:
        'Starting with topic creation, you need to find original and creative ways of addressing relevant subjects, backed by considerable research into market trends, your competitors, and keywords and phrases that perform well on Google. Next, you need to write engaging and well-researched content that demonstrates value not only to human readers, but to Google’s algorithm, too. Utilise images and headings, support the content with up to date statistics (did you know that over <a href="https://hostingtribunal.com/blog/how-many-blogs/#gref">2 million blog posts go live each day</a>?) and then proofread that content before posting. Alternatively, you can engage <a target="_blank" href="https://www.ocere.com/services/content-creation">Ocere’s high quality blog content writing services</a>, saving yourself many hours at the keyboard.',
    },
    {
      title: 'In What Languages Do You Offer Blog Content Writing?',
      description:
        'Our multilingual in-house writing team regularly produce content for English, Spanish, French, German, Finnish, Italian, Portuguese, and Swedish audiences, and we can produce content – always written by native speakers – in plenty more languages on request.',
    },
  ],
} as Index;
