import {apiClient, handleResp} from './utils';
import {empty} from '@/utils';
const LeadService = {
  doGetLeads() {
    return handleResp(apiClient().get('/leads'));
  },
  doGetLead(id: string) {
    return handleResp(apiClient().get('/leads/' + id));
  },
  doCreateLead(data) {
    return handleResp(apiClient().post('/leads/', data));
  },
  doUpdateLead(id, data) {
    return handleResp(apiClient().patch('/leads/' + id, data));
  },
  formatInfo(lead) {
    const noteCount = empty(lead.notes) ? 0 : lead.notes.length;
    const latestNote = noteCount === 0 ? {} : lead.notes[noteCount - 1];
    return {
      id: lead.id,
      companyName: lead.company_name,
      companySite: lead.company_website_url,
      billingCountry: lead.billing_country,
      product: lead.product,
      phoneNumber: lead.phone_number,
      email: lead.email,
      firstName: lead.first_name,
      lastName: lead.last_name,
      estimatedMonthlyValue: lead.estimated_monthly_value,
      status: lead.status,
      noteCount: empty(lead.notes) ? 0 : lead.notes.length,
      latestNoteDate: latestNote.created_at,
      latestNoteDetail: latestNote.content,
      firstContact: lead.created_at,
      notes: lead.notes,
    };
  },
};
export default LeadService;
