import {Index} from '@/content/pages/persona';

export default {
  url: '/white-label',
  meta: {
    title: 'White Label Digital Marketing Services | Ocere [2021!]',
    description:
      'White lable SEO and PPC Services for those wishing to Build recurring revenue and save time and money by outsourcing to a trusted agency. Click here to learn more',
  },
  green: {
    graphic: 'white-label',
    name: 'White Label',
    catchphrase: 'White Label SEO Packages',
    heading: 'Offer our expertise to your clients',
    paragraph: [
      'Build recurring revenue by offering additional services to your clients, or save time and money by ' +
        'outsourcing existing services to us.',
      'Ocere can deliver dependable SEO and PPC solutions that you can mark up by 20-100%.',
      'You’ll get full support every step of the way, as well as proposals and ongoing monthly reporting ' +
        'in your own branding.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>Simply provide details of a client with the budget and need for digital ' +
      `marketing services, and we'll create a free website review or proposal in your branding. ` +
      'If you have clients who are already happy to proceed, you can simply select from one of our ' +
      'prebuilt packages and we’ll begin work right away on your behalf. Once a client’s campaign is ' +
      'underway, you can monitor activity through a private agency portal, and we’ll also provide ' +
      'monthly reports for you to send on to them.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver them. ' +
      'Many of our clients have had frustrating experiences with other agencies and are delighted to ' +
      'discover an entirely different experience when working with Ocere. If you are a digital marketing ' +
      'agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve your ' +
      'service to clients, have you considered white label SEO outreach service? If you are working as ' +
      'a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'What is white label SEO?',
      description:
        'White label SEO is the process where an established digital marketing agency will work ' +
        'marketing and SEO campaigns for the clients of other marketing companies, freelancers etc, who in turn ' +
        'will brand the work as their own.',
    },
    {
      title: 'What is a white label report?',
      description:
        'White label SEO reports are professionally prepared reports which are delivered to the client DM agency or freelancer. Said reports detail the progress of any SEO campaign and are branded with the client agency business logo for seamless transition to the client agency’s client(s).',
    },
    {
      title: 'What is a white label agency?',
      description:
        'A white label agency is a DM agency which offers digital marketing services to another agency, so for example, Agency A will work on any element of digital marketing for Agency Bs clients, and agency B will pass off the work as their own.',
    },
    {
      title: 'What is white label SEO compared to private label SEO?',
      description:
        'A digital marketing agency offering SEO services under their own brand is considered private label. On the other hand, for example, an agency or freelancer or other white label SEO reseller will be selling another agency’s work and presenting it as their own work.',
    },
    {
      title: 'What is white label search engine optimisation?',
      description:
        'White label search engine optimisation is the process where one SEO agency (agency A) will help optimise another agency’s (agency B) client(s) website without agency Bs clients’ knowledge.',
    },
    {
      title: 'Is white label legal?',
      description: 'Yes! White label SEO is perfectly legal.',
    },
    {
      title: 'What is white label in websites?',
      description:
        'For example, a web developer will create a website for a digital marketing agency or freelancer who will then in turn sell this website as a completed package under their own (agency or freelancer) where that service is not normally provided. It can work in reverse where a digital marketing agency might work on behalf of a web developer who otherwise would not sell SEO services as an element of their regular work.',
    },
    {
      title: 'Why does this happen?',
      description:
        'Many companies, agencies and freelancers working in internet marketing and website development interchange and exchange services without the need for heavy investment in areas of expertise in which they do not specialise. One such service is providing a <a href="https://www.ocere.com/services/blogger-outreach">blogger outreach service</a> to a web developer so they can offer this service to their clients without the need for heavy investment in expertise, knowledge and professional tools etc.',
    },
    {
      title: 'What are the advantages of white label SEO services?',
      description:
        'The single biggest advantage of being a white label SEO reseller is it allows much more freedom and flexibility in the way in which they are able provide diverse SEO packages, <a href="https://www.ocere.com/services/link-packages">SEO outreach packages</a> and white label PPC services on demand without the need for heavy investment, such as staff, staff training, IT hardware, office furniture and office space etc.',
    },
    {
      title: 'How much can I save using white label SEO?',
      description:
        'It is difficult to quantify; however, research and on-going work suggests that a DM agency and/or freelancer can save anything between 30 to 50 percent when engaging white label SEO over directly employed staff.',
    },
    {
      title: 'Why are white label SEO partnerships so effective?',
      description:
        'The additional staff, knowledge and expertise are on hand only when you need to engage them from your partner white label supplier. The bottom line is your bottom line benefits greatly.',
    },
    {
      title: 'What should I look for in a white label SEO supplier?',
      description:
        'A white label SEO should at the very least offer a white label SEO reseller on-page SEO and audits of websites, keyword research, SEO outreach, <a target="_blank" href="https://www.ocere.com/services/content-creation">creative content</a>, keyword rank tracking, detailed reporting and PPC.',
    },
    {
      title: 'What is white label SEO services?',
      description:
        'Basically, white label services can include any digital marketing elements such as PPC, SEO outreach, creative content, keyword rank tracking and detailed SEO reporting, either singly or as bundled SEO packages which helps improve the SERP ranking an online visibility of a website or webpage.',
    },
    {
      title: 'What is white label PPC?',
      description:
        'White label PPC services is a an umbrella term used to describe when a white label agency provides a complete Google AdWords (PPC) campaign for a white label SEO reseller’s client. The SEO reseller will brand it as their own work, so the client will not have any knowledge that a third party is involved.',
    },
    {
      title: 'What is white label reseller?',
      description:
        'A white label SEO reseller is a person (freelancer for example) or a digital marketing or PR company as other examples, who will sell SEO services of a white label SEO agency but re-branded as their own work.',
    },
    {
      title: 'What is white label web design?',
      description:
        'White label web design is, typically, a web design service which originates from a web developer but which is sold by companies such as digital marketing agencies, freelancers and PR agencies to their own clients but branded as their own work.',
    },
    {
      title: 'Is SEO services interchangeable?',
      description:
        'Website developers, digital marketing agencies, freelancers and PR agencies often beck up each others’ work with white label services as a tried and trusted method of marketing services, knowledge and expertise without having to make heavy investment in staff and the incumbent overheads involved.',
    },
    {
      title: 'What is difference between white hat and black hat SEO?',
      description:
        'Black hat SEO is the practice of gaining higher SERP positions by using unethical tactics which, while delivering short term gain will ultimately incur penalty points from search engines such as Google, Bing and Yahoo for example.  USE BLACK HAT SEO AT YOUR PERIL.',
    },
    {
      title: 'What are black hat SEO techniques?',
      description:
        'Keyword stuffing, cloaking, using private link networks, doorway pages, invisible text and adding unrelated keywords and duplicating content are just a few examples. Black hat SEO is the complete antithesis of white label SEO and SHOUD BE AVOIDED AT ALL COSTS.',
    },
    {
      title:
        'How can I tell if I am being offered ‘black label’ masquerading as white label SEO?',
      description:
        'If you are quoted prices for SEO work which seems ‘too good to be true’ there may well be an element or two of black hat SEO – BEWARE! If your website suddenly drops like a stone in the search engine rankingS, you may well have been ‘black hatted’.',
    },
  ],
} as Index;
