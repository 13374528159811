import {Index} from '@/content/pages/persona';

export default {
  url: '/services/international-link-building',
  meta: {
    title: 'International SEO Outreach Services | Ocere [2021!]',
    description:
      'International SEO outreach and blogger outreach services to target a wide range of international marketplaces. Click here to learn more.',
  },
  green: {
    graphic: 'white-label',
    name: 'International SEO Outreach',
    catchphrase: 'International SEO Outreach',
    heading: 'Gain high-quality links and mentions to your website',
    paragraph: [
      'One of the main purposes of SEO is for you to capitalise fully on your online presence by expanding your site’s reach to the full perimetres of your market. Without expanding beyond your native country, you cannot fully realise the potential of digital marketing and, as a result, will miss out on capturing the attention your business deserves.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?': `
                <p>International SEO outreach requires a great deal of time, knowledge, and creativity to get right.</p>
                <ul>
                    <li><strong>UK</strong> (English)</li>
                    <li><strong>Germany</strong> (German)</li>
                    <li><strong>Spain</strong> (Spanish)</li>
                    <li><strong>Italy</strong> (Italian)</li>
                    <li><strong>France</strong> (French)</li>
                    <li><strong>Belgium</strong> (French / Dutch)</li>
                    <li><strong>Nordics</strong> – <strong>Sweden</strong>(Swedish) ,
                    <strong>Finland</strong> (Finnish) , <strong>Denmark</strong> (Danish)</li>
                    <li><strong>Latin America</strong> – <strong>Brazil</strong> (Brazilian Portuguese) ,
                    <strong>Mexico</strong> (Spanish) , <strong>Argentina</strong> (Spanish)</li>
                    <li><strong>USA</strong> (English)</li>
                    <li><strong>Australia</strong> (English)</li>
                    <li><strong>+ more on request</strong></li>
                </ul>
                <p>At Ocere, we have worked on many international SEO outreach strategies, enabling a variety of businesses to grow beyond their domestic market. Backed by more than 10 years of experience in providing SEO outreach services to a diverse array of industries, our in-house multilingual writing team, and a global network of over 20,000 webmasters, Ocere’s international SEO outreach services continue to provide our clients with tangible and lucrative results.</p>
            `,
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver ' +
      'them. Many of our clients have had frustrating experiences with other agencies and are delighted ' +
      'to discover an entirely different experience when working with Ocere. If you are a digital ' +
      'marketing agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve ' +
      'your service to clients, have you considered white label SEO outreach service? If you are working ' +
      'as a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'How do You Target an International Audience?',
      description:
        'SEO outreach is a powerful tool for drawing a greater audience to your site, but the scope of your strategy matters. Language, market behaviour, colloquialisms and social media habits all differ significantly between regions, cultures and countries, and the most effective SEO outreach approach will be the one that aims to cover a diverse audience. One of the most important — and time-consuming — requirements for international SEO outreach is <a target="_blank" href="https://www.ocere.com/services/blogger-outreach">blogger outreach</a>. Backlinks should be placed on sites with high authority, and this will differ significantly around the globe. A wide and varied network of webmasters from which to obtain backlinks is essential and, as a result, an in-depth understanding of that particular country or region’s high-ranking sites.',
    },
    {
      title: 'How do You Translate Keywords?',
      description:
        'You could simply translate the keywords for your domestic market, but this won’t prove to be the most effective strategy. Local colloquialisms, nuances and trends all shape the effectiveness of particular keywords, which is why it is important to work with a team who understands how to approach international SEO outreach in a way that will prove most rewarding. The same goes for all written content — a high quality translation will be one that assimilates colloquialisms and dialects particular to your target region, rather than simply repeating, word-for-word, what was written in your own language.',
    },
    {
      title: 'How Many Backlinks Do I Need?',
      description:
        'There is no specific number — a good strategy will continue to monitor and grow your backlinks as time goes by, in order to keep up with the evolving online landscape. In recent years, <a target="_blank" href="https://contentmarketinginstitute.com/2015/04/google-judges-quality/">Google’s algorithm has begun to favour quality over quantity</a>; sites with high authority and rankings on SERPs will serve you much better in the long run. It is for this reason that having an extensive network of webmasters to reach out to is essential.',
    },
    {
      title: 'Is International SEO Outreach Worthwhile?',
      description:
        'At Ocere, we have worked with many clients looking to get the most out of their online profile. For some of these clients, they began with only a local presence, and with the help of an international SEO outreach campaign were able to expand their market, generate more organic traffic to their site, and grow on an international scale. So, yes — international SEO outreach is certainly worthwhile.',
    },
  ],
} as Index;
