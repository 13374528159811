import {Index} from '@/content/pages/persona';

export default {
  url: '/services/fintech-seo',
  meta: {
    title: 'Fintech SEO Services | Ocere [2021!]',
    description:
      'SEO services designed to help cleitn in the Financial Technology marketplace increase their online presence. Click to read more.',
  },
  green: {
    graphic: 'white-label',
    name: 'Fintech SEO',
    catchphrase: 'Fintech SEO',
    heading: 'Gain high-quality links and mentions to your website',
    paragraph: [
      'On a global scale, Fintech is predicted to grow to a market value of $309.98 billion by 2023. The market is wide and diverse, but companies cannot hope to thrive within it — or, more importantly, compete with the ever growing list of financial powerhouses, tech giants, and start-ups seeking to make headway within the industry — without constantly ensuring that their digital presence is optimised for the rapidly evolving and highly competitive online landscape. Visibility is integral to success, but it is also impossible for brands not yet following a comprehensive and modern strategy for search engine optimisation.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>Ocere’s Fintech SEO services have achieved exceptional results for clients. Our strategies are creative and far-reaching, handling on-site optimisation, competitor analysis, consistent SEO outreach, outreach, creative content and monthly reporting to ensure your strategy is bearing fruit.</p>' +
      '<h2 class="c-h2">Is SEO Relevant for Fintech?</h2>' +
      '<p>As of February 2020, <a href="https://www.statista.com/statistics/893954/number-fintech-startups-by-region/">more than 20,900 Fintech startups</a> were recorded in the Americas, EMEA and APAC. The industry offers unprecedented opportunities for rapid growth within diverse markets, but SERPs are saturated with sites vying for visibility and organic audiences. As a result, SEO is essential to your ability to keep pace within the industry — without it, sites will continue to plummet down search engine results.</p>' +
      '<h2 class="c-h2">Do You Hold Experience in the Fintech Industry?</h2>' +
      '<p>Yes — we have worked with clients operating all over the world, and consistently achieve impressive results for their sites’ organic traffic levels, and their rankings on SERPs.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver ' +
      'them. Many of our clients have had frustrating experiences with other agencies and are delighted ' +
      'to discover an entirely different experience when working with Ocere. If you are a digital ' +
      'marketing agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve ' +
      'your service to clients, have you considered white label SEO outreach service? If you are working ' +
      'as a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'How Do I Optimise My Website for Google?',
      description:
        'Ensure keywords are researched and used consistently. Address accessibility issues — performance and page load speed, headers, and image optimisation. Consider the E-A-T model for content and ensure that it offers value for human readers, as well as factors in Google’s algorithm. <a target="_blank" href="https://www.ocere.com/services/blogger-outreach">Obtain plenty of high-quality backlinks</a> through an effective outreach strategy. Alternatively, Ocere has been offering these services to clients for more than ten years, and can ensure your site is optimised for organic searches on your behalf, which will save you from time, money, and setbacks.',
    },
    {
      title: 'Does SEO Really Work?',
      description:
        'SEO is the most effective practice for ensuring visibility and authority in search engine results. Like anything, however, it has to be completed to an exceedingly high standard in order to yield worthwhile results, and the industry is rife with poor practices — so called ‘black hat’ techniques — that are liable to cause significant issues for sites in the long term. At Ocere, our services utilise sustainable, high-impact techniques, and we offer simplicity and transparency every step of the way. This has proven highly effective for thousands of clients across a wide range of industries.',
    },
    {
      title: 'How Much Does SEO Cost?',
      description:
        'This is largely dependent on your objective, timescale, and location. Ocere offers SEO packages at fixed prices, in addition to bespoke price SEO services. We can offer plenty of advice and guidance about choosing a strategy that will offer the best results for your site.',
    },
  ],
} as Index;
