






































































import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Service } from '@/content/pages/services';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import EventBus from '@/content/EventBus.ts';

@Component({
  components: {
    VueSlider,
  },
})
export default class extends Vue {
  @Prop() service!: Service;

  wordCount = 500;
  articleValue = 10;

  wordCountData = [250, 500, 750, 1000, 1500, 2000];
  articleData: any = [];

  pushData() {
    localStorage.setItem(
      'ContentCreation',
      JSON.stringify({
        wordCount: this.wordCount,
        articleValue: this.articleValue,
      }),
    );
    EventBus.$emit('sliderData', {
      wordCount: this.wordCount,
      articleValue: this.articleValue,
    });
  }

  @Watch('wordCount')
  wordCountChanged(value: number) {
    this.pushData();
  }

  @Watch('articleValue')
  articleValueChanged(value: number) {
    this.pushData();
  }

  @Watch('service')
  serviceChanged() {
    if (this.service.slider) {
      this.pushData();
    }
  }

  get date() {
    return moment().add(this.service.days, 'days').format('dddd Do MMMM YYYY');
  }

  protected created() {
    for (let index = 1; index <= 200; index++) {
      this.articleData.push(index);
    }
    const oldData = localStorage.getItem('ContentCreation');
    if (oldData) {
      const tmp = JSON.parse(oldData);
      this.wordCount = tmp.wordCount;
      this.articleValue = tmp.articleValue;
    }
  }

  protected mounted() {
    if (this.service.slider) {
      this.pushData();
    }
  }

  // protected destroyed() {
  //   EventBus.$emit('offSliderData');
  // }
}
