























import {Component, Vue} from 'vue-property-decorator';
import PageTitle from '@/components/page-title.vue';
import ServiceContainerWrapper from '@/components/services/service-container-wrapper.vue';
import LinedBulletPoints from '@/components/services/lined-bullet-points.vue';
import {ocere} from '@/content';
import FrequentlyAskedQuestions from '@/components/services/frequently-asked-questions.vue';

@Component({
  components: {
    FrequentlyAskedQuestions,
    LinedBulletPoints,
    ServiceContainerWrapper,
    PageTitle,
  },
  metaInfo: {
    title: 'SEO, PPC and Link Building Services | Ocere [2021!]',
    meta: [
      {
        name: 'description',
        content:
          'View and order our complete range of SEO, PPC, and link building services, as well as content and more. Click here.',
      },
    ],
  },
})
export default class extends Vue {
  reasons = ocere.reasons;
}
