import {Blog} from '@/content/blogs/model';

export default {
  url: 'is-diversity-important-to-your-backlink-profile',
  title: 'Is Diversity Important to Your Backlink Profile',
  category: 'Link Building',
  // metaTitle: '',
  // metaDescription:'',
  thumbnail:
    'https://images.ctfassets.net/p5gt7dkd4wn3/2YzjSR0BkaxWRYKQHAW1U/d421166bffa33c13dfedb4fe8bdb808c/Diversity2.jpg',
  description: [
    {
      type: 'paragraph',
      title: 'Is Diversity Important to Your Backlink Profile?',
      content: [
        'Backlink diversity is by no means a new concept - in fact, it has been used to varying degrees since the very early days of search engine optimisation. As with many aspects of digital PR, however, there continues to be no universal consensus on the value it holds for SEO.',
      ],
    },
    {
      type: 'paragraph',
      title: 'What is Link Diversity?',
      content: [
        `Your backlinks are there to augment your online profile by demonstrating to Google that your site and it's contents offer a valuable resource to readers; it is a crucial tool to bring in more organic traffic and, ultimately, to raising your ranking on the search engine results page (SERP).`,
        'Link diversity is, in general terms, the practice of avoiding too much uniformity in the links you build. In part, it means obtaining backlinks from a wider variety of sites and domains - still ensuring that they are always relevant to your own content - and ensuring that you are not overly-consistent with factors such as link placement within the content.',
        'It also means creating a healthy mix of Dofollow and Nofollow links. When it encounters Nofollow links, Google does not crawl the linked page (your site) and, as such, your ranking is not affected by the connection, but they do still bring traffic to your site which is, obviously, of great benefit to your online profile.',
      ],
    },
    {
      type: 'image',
      url:
        'https://images.ctfassets.net/p5gt7dkd4wn3/5gcAW9Hiv3vvZKIJLdwof4/419cc2a5eb367a5938a73d4e3d90e907/Diversity.jpg',
    },
    {
      type: 'paragraph',
      title: 'Should I Aim for Diversity?',
      content: [
        `Knowing this, creating a good backlink profile can feel like a bit of a Goldilocks mission - the need to create a solid, advantageous network with quality sites, and the need to ensure that you are creating the rich, diverse and organic online presence that is, ultimately, what Google's algorithms are searching for.`,
        'Why not, the argument goes, aim only for those sites that have a very high DA rating? Google will recognise a backlink profile of high ranking sites, and reward your own site accordingly.',
        `And the argument holds, to a certain extent. Afterall, the entire purpose of rigorous and strategic link building is to make Google aware of the fact that your site is offering high quality content to readers, and that other high quality sites have recognised it as a valuable resource. But, as we are all aware, Google is incredibly wise to these tactics. It's algorithms are forever being updated in order to sift through the many sites being too transparent in their attempts to improve their rankings, and a lack of diversity is considerably more transparent than a backlink profile comprising a wide range of sites, top level domains, and a healthy mix of Dofollow and Nofollow links.`,
        'It is useful to consider high ranking sites, such as Twitter. It is only natural that Twitter has an incredible diverse backlink profile, as sites with high and low DAs frequently reference pages and Tweets as important resources for their readers. ',
        `In a landscape where credibility and organicity are valued over metrics, and an ever-increasing number of sites are seeking to garner positive attention from Google's algorithm, diversity will only continue to grow in importance. Similarly, Nofollow links, and obtaining links from sites with varying DA ratings, will continue to generate more traffic from a wider readership - another valuable tool for increasing your ranking on the SERPs. `,
      ],
    },
  ],
} as Blog;
