import {LinkBuilding} from '@/content/pages/link-building/index';

export default {
  icon: require('@/assets/icons/services/link_packages_icon.png'),
  graphic: require('@/assets/graphics/services/link_building_graphic.svg'),
  title: 'Bespoke Outreach Campaigns',
  bullet:
    'For those with a specific budget for links. Choose your quality metrics and we take care of the rest!',
  url: '/services/link-packages',
  meta: {
    title: 'SEO Outreach Packages | Ocere [2021!]',
    description:
      'SEO Outreach packages for those with a specific monthly budget. Select your price point and we will deliver the best value links for your budget. Click here.',
  },
  // shop: 'https://my.ocere.com/product-category/link-building/',
  days: 14,
  description: [
    'Our Bespoke Link Packages can help you obtain valuable links and mentions for your business.',
    `We can work to your exact requirements and preferences - with a fully bespoke service.`,
    'Our team can create all content, conduct the research and engage in the outreach to help grow your presence online.',
    'Simply fill out the form below to let us know your requirements.',
  ],
  points: [
    'Authentic Blogger Outreach',
    'Choose your metrics (DA or Traffic)',
    'Content Writing Included',
    'Fast Turnaround (14+ days)',
  ],
  packages: [
    {
      name: 'Link Package 1',
      price: {
        USD: 1295,
        GBP: 895,
      },
      items: {
        Content: {
          'Full Content Creation': true,
          'Outreaching Webmasters': true,
          'Fast Turnaround Time': true,
          'Authority Links': true,
          'Price Match': true,
          'Approve Sites/Content Option (+10% Fee)': true,
        },
        'Choose your grade': {
          DA20: 16,
          DA30: 12,
          DA40: 7,
          'DA50+': 5,
        },
      },
    },
    {
      name: 'Link Package 2',
      price: {
        USD: 1995,
        GBP: 1495,
      },
      items: {
        Content: {
          'Full Content Creation': true,
          'Outreaching Webmasters': true,
          'Fast Turnaround Time': true,
          'Authority Links': true,
          'Price Match': true,
          'Approve Sites/Content Option (+10% Fee)': true,
        },
        'Choose your grade': {
          DA20: 25,
          DA30: 20,
          DA40: 10,
          'DA50+': 7,
        },
      },
    },
    {
      name: 'Link Package 3',
      price: {
        USD: 4995,
        GBP: 3795,
      },
      items: {
        Content: {
          'Full Content Creation': true,
          'Outreaching Webmasters': true,
          'Fast Turnaround Time': true,
          'Authority Links': true,
          'Price Match': true,
          'Approve Sites/Content Option (+10% Fee)': true,
        },
        'Choose your grade': {
          DA20: 65,
          DA30: 55,
          DA40: 26,
          'DA50+': 18,
        },
      },
    },
    {
      name: 'Link Package 4',
      price: {
        USD: 9995,
        GBP: 7795,
      },
      items: {
        Content: {
          'Full Content Creation': true,
          'Outreaching Webmasters': true,
          'Fast Turnaround Time': true,
          'Authority Links': true,
          'Price Match': true,
          'Approve Sites/Content Option (+10% Fee)': true,
        },
        'Choose your grade': {
          DA20: 150,
          DA30: 120,
          DA40: 60,
          'DA50+': 40,
        },
      },
    },
  ],
  how_it_works: [
    {
      title: 'Outreaching Sites',
      description:
        `You'll provide the Anchor Text, Target URLs and quantity you require. We'll then start ` +
        'outreaching topically relevant sites, with strong authority. We already have 40,000 publisher ' +
        'relationships to help streamline this process. For a small charge you can approve sites upfront.',
    },
    {
      title: 'Creative Content',
      description:
        'Our UK content team will start writing your content immediately (typically 500 words per ' +
        'piece). The key here is to form a natural bridge between the blog and your target URL. We are ' +
        'experts at this. For a small charge you can approve content upfront.',
    },
    {
      title: 'Order Completion',
      description:
        `We place thousands of content pieces per month. We'll get the content live quickly, and ` +
        'then send you a link report (via Email and Online Portal) which shows the live link, metrics ' +
        `(DA etc) and live date. We're on hand for any questions etc.`,
    },
  ],
  key_benefits: [
    {
      title: 'Authentic, Natural Sites',
      description:
        'Forget dodgy networks or "thin" blogs made for SEO purposes. We deal in authentic sites, ' +
        'with authentic audiences.',
    },
    {
      title: 'Fastest Turnaround',
      description:
        'We boast over 40,000 publisher relationships and sharper processes to turn orders ' +
        'around quicker',
    },
    {
      title: 'Full Approval',
      description:
        'Unlike others - you can have full approval of all content and sites (10% fee). ' +
        'Stay in control!',
    },
    {
      title: 'In Content Links',
      description:
        'All our links are naturally contained in the editorial. Forget footer links or ' +
        'sponsored links.',
    },
    {
      title: 'Price Match',
      description: `We'll match or beat any comparable offer from another company. No questions asked.`,
    },
  ],
  faq: [
    {
      title:
        `What's the difference between these packages and the Individual Blogger Outreach service ` +
        'you offer?',
      description:
        'Our link packages cater for the many clients who have a specific amount they can allocate ' +
        'each month. Often, you can get better value with a package, and they are also easier to administer.',
    },
    {
      title: 'What is Blogger Outreach, and what is SEO Outreach?',
      description:
        '<b>Blogger Outreach</b> is the process of building relationships with bloggers and influencers. ' +
        'This is typically for the purpose of promoting content on behalf of clients. ' +
        '<b>SEO Outreach</b> is the process of acquiring backlinks from external websites and blogs to ' +
        'your website. This has significant benefits with Google and helps you to rank higher in search ' +
        'results. Since the Google Penguin update in 2012, there has been a movement towards ' +
        'higher-grade blogger outreach as a means for SEO outreach. ' +
        'Nowadays, the 2 terms are almost interchangeable.',
    },
    {
      title: `Is SEO outreach risky these days? How can I ensure it's done safely?`,
      description:
        `Don't confuse affordable SEO outreach services with 'dodgy SEO outreach services'. ` +
        'If done carelessly, low-quality SEO outreach can actually cause damage to your website within ' +
        'Google. A website’s link profile is one of the most influential elements of a website’s search ' +
        'ranking and quality metrics. Often, poor quality links tend to become broken links in a link ' +
        'profile, and these will count negatively to your website’s overall ranking. This is why it is more ' +
        'important to seek out only high quality, relevant sites from which to acquire links. Additionally, ' +
        'care should be taken to make all activity, when undertaking a SEO outreach outreach campaign, ' +
        'appear as natural as possible.',
    },
    {
      title:
        'Can you work to other metrics - SEMrush traffic/keywords, AHrefs DA, Trust Flow (TF), ' +
        'Citation Flow (CF) etc?',
      description:
        'Yes, absolutely. As a long establoished SEO and SEO outreach company, we run campaigns ' +
        'using all of these metrics and more. We can prepare example sites based on your specific ' +
        'requirements. Many of our bigger clients have individual quality metrics to which we adhere.',
    },
    {
      title: `Do we buy our links? I've heard buying links is bad.`,
      description:
        '<p>We are neither buying, nor selling links. We are managing the <a target="_blank" href="https://www.ocere.com/services/content-creation">creative content</a> and outreach ' +
        'process from start to finish. This is what you pay for. The process involves: </p>' +
        '<ol>' +
        '<li>Researching relevant target sites</li>' +
        '<li>Contacting these sites</li>' +
        '<li>Creating a useful and interesting piece of content</li>' +
        '<li>Assisting to get that content piece placed on the website</li>' +
        '</ol>' +
        '<p>Buying links typically refers to websites who openly advertise in order to sell links or ad ' +
        'space on their website. Google’s crawlers can pick up these footprints which is potentially ' +
        'damaging for you. ' +
        'As you can see, our process is editorial-led, and so avoids this risk completely.',
    },
    {
      title: 'How quick is the turnaround?',
      description:
        'On a small rush order, we can turn around things in approx 7-10 days. ' +
        'Typical outreach jobs of 10-20 links take about 20-30 days to complete. We have smart, ' +
        'cloud-based project management systems to assist turnaround.',
    },
    {
      title: 'Can we approve content or sites before you place the posts?',
      description:
        'Yes, absolutely. This can add more time and complexity to the process, so is subject ' +
        'to a 10% fee.',
    },
    {
      title: 'How do you determine what a "good quality” site is?',
      description:
        'This is often led by the client’s requirements. Typically, clients are looking for ' +
        'sites with a high Domain Authority and/or a high Trust Flow. If budget allows, we’d also recommend ' +
        'looking for sites with good levels of SEMrush traffic.',
    },
    {
      title:
        'Do you own these sites? Is this just a big PBN (Private blog network)?',
      description:
        'Absolutely not. These sites are authentic, owned by real webmasters, with genuine ' +
        'audiences. We do not recommend, nor become involved with private blog networks. PBNs are the type ' +
        'of activity that can flag your site with Google and cause problems.',
    },
    {
      title: 'Can I place a large order with multiple clients/websites?',
      description:
        'Of course. Many of our clients are agencies with multiple clients. You can purchase link ' +
        'packages and spread them across various clients. Just specify the URLs within the order.',
    },
    {
      title: 'What discounts can you offer for bulk orders?',
      description:
        'We are confident to price match any comparable order. For bulk orders we want to be ' +
        'co-operative, so speak to us and we’ll see what we can do for you.',
    },
    {
      title: 'What verticals are you strong in / weak in?',
      description:
        'We have coverage across all major verticals, but have particular strength in business, ' +
        'technology, retail, finance, automotive, ecommerce/shopping, gaming, and sports. We also have ' +
        'big capability in the casino/gambling section.',
    },
    {
      title: 'Who writes the content, how long is it, and what is it about?',
      description:
        'Ocere has a dedicated team of writers. Researching, writing, proofreading and publishing ' +
        'articles can be extremely time consuming and challenging – we have the streamlined systems in place ' +
        'to deal with this so you don’t have to. We serve a wide range of industries, including technology, ' +
        'gaming, Ecommerce, professional services and more. We also work with many design, web and digital ' +
        'agencies, helping to service their clients. We work primarily with businesses in the USA, UK, ' +
        'Israel and Europe, and 70% of our clients use us remotely.',
    },
    {
      title: 'What is your monthly capacity?',
      description:
        'We have capability to produce and deliver thousands of content pieces per month and get ' +
        'them placed on high quality blogs, so capacity is not an issue.',
    },
    {
      title: 'How many webmaster relationships do you have?',
      description:
        'We boast over 40,000 webmaster relationships across all key verticals such as business, ' +
        'finance, technology, retail/shopping, sports, travel, leisure etc.',
    },
    {
      title: 'Why choose Ocere instead of other Outreach companies?',
      description:
        'We will not only match and beat any comparable quote, we can also turn orders around ' +
        'quicker. What’s more, we actually care about delivering an outstanding service and are on hand ' +
        'to help every step of the way. You might be interested to know that many other agencies use us ' +
        'and resell with a markup. So why not simply come to us directly?',
    },
    {
      title:
        'Are these placements permanent? What happens if they get removed?',
      description:
        'These are authentic sites; we do not own or control them, but our aim is to secure ' +
        'permanent placement. It’s only in rare cases that posts do get removed. When that does happen, ' +
        'because you buy backlink packages from Ocere in good faith, we will follow up to try and ' +
        're-secure placement, which is achievable 90% of the time.',
    },
    {
      title: 'Can you work in the online gambling sector?',
      description:
        'Absolutely! We have significant strength here - and already work with several of the big ' +
        'players, in the key markets: UK, DE, FR, ES, IT, PT, BR, SE, DK, NO, FI etc. We have strength in ' +
        'both gaming (casino, slots etc) sports betting (sports related) and esports betting (tech, news, ' +
        'lifestyle, sports).',
    },
    {
      title: 'What countries or languages can you offer this service?',
      description:
        'Our strength is in English speaking markets, but we do also operate successfully in ' +
        'German, French, Spanish, Portuguese, Italian and Scandinavian markets. On a lesser scale we have ' +
        'achieved results in Russian.',
    },
  ],
  package_new: [
    {
      id: 'DA',
      title: 'Domain Authority',
      description: 'Guaranteed Domain Authority of the target site.',
      startingAt: {
        GBP: 68,
        USD: 85,
      },
      turnaround: 'From 14 day turnaround time',
      unit: 'DA',
      items: [
        {
          id: 'DA20',
          value: '20',
          price: {
            GBP: 68,
            USD: 85,
          },
        },
        {
          id: 'DA30',
          value: '30',
          price: {
            GBP: 88,
            USD: 110,
          },
        },
        {
          id: 'DA40',
          value: '40',
          price: {
            GBP: 168,
            USD: 210,
          },
        },
        {
          id: 'DA50',
          value: '50',
          price: {
            GBP: 256,
            USD: 320,
          },
        },
        {
          id: 'DA60',
          value: '60',
          price: {
            GBP: 396,
            USD: 495,
          },
        },
      ],
    },
    {
      id: 'WT',
      title: 'Website Traffic',
      description: 'Guaranteed monthly organic traffic of the target site.',
      startingAt: {
        GBP: 156,
        USD: 195,
      },
      turnaround: 'From 14 day turnaround time',
      unit: 'Traffic/Mo',
      items: [
        {
          id: 'PT1000',
          value: '1,000',
          price: {
            GBP: 156,
            USD: 195,
          },
        },
        {
          id: 'PT5000',
          value: '5,000',
          price: {
            GBP: 236,
            USD: 295,
          },
        },
        {
          id: 'PT10000',
          value: '10,000',
          price: {
            GBP: 316,
            USD: 395,
          },
        },
        {
          id: 'PT25000',
          value: '25,000',
          price: {
            GBP: 400,
            USD: 500,
          },
        },
      ],
    },
  ],
} as LinkBuilding;
