




































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Package, Service } from '@/content/pages/services';
import EventBus from '@/content/EventBus.ts';
import { roundNearest } from '@/content/common';

@Component
export default class extends Vue {
  @Prop() package!: Package;
  @Prop() service!: Service;

  isUsingSliderData = false;
  sliderData = {
    wordCount: 250,
    articleValue: 1,
  };
  roundNearest = roundNearest;

  @Watch('service')
  serviceChanged() {
    if (this.service.slider) {
      this.isUsingSliderData = true;
    } else {
      this.isUsingSliderData = false;
    }
  }

  created() {
    EventBus.$on('sliderData', (data: any) => {
      this.isUsingSliderData = true;
      this.sliderData = data;
    });
  }

  // destroyed() {
  //   EventBus.$off('sliderData');
  // }
}
