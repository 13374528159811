import {
  isUndefined,
  isNull,
  isObject,
  isEmpty,
  isFunction,
  isString,
  some,
} from 'lodash';
export function generateSlug(data, separator) {
  separator = separator || '-';
  if (data != null && data) {
    if (!isString(data)) {
      data = JSON.stringify(data);
    }
    let slug = data.toLowerCase();
    // convert to english type
    slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
    slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
    slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
    slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
    slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
    slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
    slug = slug.replace(/đ/gi, 'd');
    if (separator) {
      slug = slug.replace(/[^a-zA-Z0-9]/gi, separator);
    } else {
      slug = slug.replace(/[^a-zA-Z0-9]/gi, '');
    }
    return slug;
  }
  return data;
}

export function separateNumber(d, s) {
  d = d + '';
  if (!s) {
    s = ',';
  }
  while (/(\d+)(\d{3})/.test(d.toString())) {
    d = d.toString().replace(/(\d+)(\d{3})/, '$1' + s + '$2');
  }
  return d;
}

export function toBoolean(string) {
  var areTrue = ['yes', 'true', true, 'y', 1, '1'];
  if (!empty(string)) {
    string = String(string).toLowerCase();
  }

  return areTrue.indexOf(string) > -1;
}
export function empty(obj) {
  if (isUndefined(obj) || isNull(obj)) {
    return true;
  }
  if (isObject(obj)) {
    return isEmpty(obj);
  }
  return obj === '';
}

export function getLabel(array, value) {
  const item = array.find(item => item.type === value);
  if (!empty(item)) {
    return item.label;
  }
  return '';
}

export function validURL(url) {
  const reg = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!reg.test(url);
}
export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export async function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const ua = navigator.userAgent.toLowerCase();
const m = ua.match(
  /(^|\s)(firefox|safari|opera|msie|chrome)[\/:\s]([\d\.]+)/
) || ['', '', '0.0'];
const browser = {
  opera: m[2] === 'opera',
  msie: m[2] === 'msie',
  safari: m[2] === 'safari',
  chrome: m[2] === 'chrome',
  firefox: m[2] === 'firefox',
  mozilla: /mozilla/.test(ua),
  version: (ua.match(/\sversion\/([\d\.]+)/) || ['', m[3]])[1],
};
export function insertJs(s, srcArrInput, cb = () => {}) {
  const srcArr = srcArrInput.filter(item => !empty(item));
  const d = window.document;
  const c = Array.prototype.slice.call(d.head.getElementsByTagName(s));
  if (srcArr.length === 0) {
    cb();
  } else {
    const src = srcArr[0];
    const id = getFileName(src);
    if (!some(c, item => item.id === id)) {
      const js = d.createElement(s);
      const fjs = c[c.length - 1];
      js.id = id;
      if (s === 'link') {
        js.href = src;
        js.rel = 'stylesheet';
      } else if (s === 'script') {
        js.src = src;
      }
      if (empty(fjs)) {
        document.head.appendChild(js);
      } else {
        fjs.parentNode.insertBefore(js, fjs.nextSibling);
      }
      // handle callback
      if (isFunction(cb)) {
        if (browser.opera && parseFloat(browser.version) < 9.8) {
          cb.call(window);
        } else {
          var isLoaded = false;
          js.onerror = () => {
            clearTimeout(reqTimeoutTimer);
            if (isFunction(cb)) {
              return cb({
                err: 0,
                message: src,
              });
            }
          };
          if (browser.msie) {
            var called = false;
            var ieLoadBugFix = function () {
              if (js.readyState === 'loaded' || js.readyState === 'complete') {
                if (getFileName(src) === js.id && !called) {
                  clearTimeout(ieLoadBugFixTimer);
                  clearTimeout(reqTimeoutTimer);
                  called = true;
                  isLoaded = true;
                  if (isFunction(cb)) {
                    srcArrInput.shift();
                    insertJs(s, srcArrInput, cb);
                  }
                }
              }
            };
            var ieLoadBugFixTimer = setTimeout(ieLoadBugFix, 100);
            js.onreadystatechange = ieLoadBugFix;
          }
          js.onload = () => {
            clearTimeout(reqTimeoutTimer);
            isLoaded = true;
            if (isFunction(cb)) {
              srcArrInput.shift();
              insertJs(s, srcArrInput, cb);
            }
          };

          var reqTimeoutTimer = setTimeout(() => {
            if (!isLoaded) {
              clearTimeout(reqTimeoutTimer);
              console.error('addjs: REQUEST TIMEOUT - src: ' + src);
              isLoaded = true;
              if (isFunction(cb)) {
                cb({
                  err: 400,
                  message: 'ERROR_REQUEST',
                });
              }
            }
          }, 5000);
        }
      }
    } else {
      srcArrInput.shift();
      insertJs(s, srcArrInput, cb);
    }
  }
}
export function getFileName(path) {
  var fileName = '';
  var patt = /\/([^\\\/:*\"<>|]+)$/g;
  var n = patt.exec(path);
  if (n !== null) {
    fileName = n[1];
  }
  return fileName;
}
