









import {Component, Vue} from 'vue-property-decorator';
import AppFooter from '@/components/app-footer.vue';
import AppHeader from '@/components/app-header.vue';
import Cookies from '@/components/cookies.vue';
@Component({
  components: {Cookies, AppHeader, AppFooter},
})
export default class App extends Vue {}
