var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"package"},[_c('div',{staticClass:"name"},[_c('h3',{staticClass:"desktop c-h3"},[_vm._v(_vm._s(_vm.package.name))])]),(_vm.package.price)?_c('div',{staticClass:"cell pricing"},[(_vm.package.price.GBP)?_c('p',{staticClass:"quantity c-p"},[_vm._v("\n        £"+_vm._s(_vm.isUsingSliderData
            ? _vm.roundNearest(
                _vm.package.price.GBP *
                  _vm.sliderData.wordCount *
                  _vm.sliderData.articleValue,
                2
              )
            : _vm.package.price.GBP)+"\n        / $"+_vm._s(_vm.isUsingSliderData
            ? _vm.roundNearest(
                _vm.package.price.USD *
                  _vm.sliderData.wordCount *
                  _vm.sliderData.articleValue,
                2
              )
            : _vm.package.price.USD)+"\n      ")]):_c('p',{staticClass:"quantity c-p"},[_vm._v(_vm._s(_vm.package.price))]),(_vm.package.per)?_c('p',{staticClass:"per c-p"},[_vm._v(_vm._s(_vm.package.per))]):_vm._e()]):_c('div',[_c('br'),_c('br')]),_vm._l((_vm.package.items),function(item,key,i){return [(i > 0)?_c('p',{staticClass:"cell c-p"},[_vm._v(" ")]):_vm._e(),_vm._l((item),function(label){return _c('div',{staticClass:"cell"},[(typeof label === 'boolean')?[(label)?_c('img',{staticClass:"image",attrs:{"src":require("@/assets/icons/tick.svg"),"alt":"tick"}}):_c('img',{staticClass:"image",attrs:{"src":require("@/assets/icons/cross.svg"),"alt":"cross"}})]:_c('p',{staticClass:"quantity c-p"},[_vm._v(_vm._s(label))])],2)})]}),(_vm.package.url)?_c('a',{staticClass:"place-order",attrs:{"href":_vm.package.url}},[_c('h3',{staticClass:"c-h3"},[_vm._v("Order")])]):_c('router-link',{staticClass:"place-order",attrs:{"to":"/get-in-touch"}},[_c('h3',{staticClass:"c-h3"},[_vm._v("\n        "+_vm._s(_vm.package.labelAction ? _vm.package.labelAction : 'Get In Touch')+"\n      ")])])],2),(_vm.package.url)?_c('router-link',{staticClass:"get-in-touch",attrs:{"to":"/get-in-touch"}},[_vm._v("or Get in Touch")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }