








































































































import {home} from '@/content';
import {Component, Vue, Watch} from 'vue-property-decorator';
import GreenAreaContainer from '@/components/green-area-container.vue';
import DmGraphic from '@/assets/graphics/dm-graphic.vue';
import CustomersContainer from '@/components/home/customers-container.vue';
import FrequentlyAskedQuestions from '@/components/services/frequently-asked-questions.vue';
import TitleContentWrapper from '@/components/title-content-wrapper.vue';
import {empty} from '@/utils';

interface ICustomer {
  src: string;
  name: string;
}

@Component({
  components: {
    TitleContentWrapper,
    FrequentlyAskedQuestions,
    CustomersContainer,
    DmGraphic,
    GreenAreaContainer,
  },
  metaInfo: {
    title: 'Link Building Services | #1 UK Blogger Outreach Agency [2021!]',
    meta: [
      {
        name: 'description',
        hid: 'description',
        content:
          'Ocere are a top digital marketing agency offering unbeatable link building, SEO, and PPC services to a wide range of international clients as both a direct partner and white label service. Click here for more info.',
      },
    ],
  },
})
export default class extends Vue {
  content = home;
  green = home['green-area'];
  emailInput = '';
  serviceCards = home.serviceCards;
  firstCustomers = home.partners.slice(0, Math.ceil(home.partners.length / 2));
  secondCustomers = home.partners.slice(-Math.ceil(home.partners.length / 2));

  isUserLogged = false;

  @Watch('$store.state.user', {immediate: true})
  async watchUSer(uInfo) {
    this.isUserLogged = !empty(uInfo);
  }
}
