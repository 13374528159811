



































import {Component, Vue} from 'vue-property-decorator';
import {employees} from '@/content/pages/about';

@Component
export default class extends Vue {
  get employees() {
    return employees.filter(employee => employee.calendly);
  }
}
