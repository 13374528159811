import {Index} from '@/content/pages/persona';

export default {
  url: '/services/blockchain-link-building',
  meta: {
    title: 'Blockchain SEO Outreach Services | Ocere [2021!]',
    description:
      'Our blockchain SEO outreach services can augment your profile through high quality backlinks from relevant, high DA sites. Click here to get started.',
  },
  green: {
    graphic: 'white-label',
    name: 'Blockchain SEO Outreach',
    catchphrase: 'Blockchain SEO Outreach',
    heading: 'Gain high-quality links and mentions to your website',
    paragraph: [
      'According to insight from Deloitte, more than <a style="text-decoration: none;color: #fff;font-weight: 800;" href="https://www2.deloitte.com/content/dam/Deloitte/se/Documents/risk/DI_2019-global-blockchain-survey.pdf">50% of respondents</a> to their 2019 survey stated that blockchain technology is now considered a critical priority. The sharp rise of the blockchain industry is staggering but, given its many applications across countless sectors, this recent upsurge — and the heady projections for the future — has long been an inevitability.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>With such rapid global uptake, however, comes considerable competition for authority. The industry remains in its infancy, and blockchain’s influence continues to evolve at an unprecedented speed. As such, a creative and proactive SEO strategy is crucial to emulating this progress, growing your visibility on SERPs, and ensuring greater levels of organic traffic arriving at your site.</p>' +
      '<h2 class="c-h2">Is Buying Backlinks a Good Idea?</h2>' +
      '<p>Buying links goes against Google’s quality guidelines, and will likely result in your site being penalised, even if the short term results seem positive. This practise is used to manipulate page rankings in lieu of genuine quality, and it is not something we do at Ocere.</p>',
    'Why choose Ocere?':
      '<p>At Ocere, we have over ten years’ experience in designing lucrative SEO outreach strategies for thousands of clients operating in a wide range of industries, and have seen great success in blockchain SEO outreach. Our strategies utilise our substantial network of 20,000+ webmasters, a multilingual team of content creators, and a modern approach to obtaining links that will produce tangible, long term results.</p>',
  },
  faq: [
    {
      title: 'What is Blockchain SEO Outreach?',
      description:
        'Blockchain SEO outreach is the process of working to increase visibility online, both in terms of human audiences and search engine algorithms, by obtaining backlinks from high authority sites. These links effectively ‘pass authority’, and encourage Google to recognise your site as a valuable resource for SERPs. <a target="_blank" href="https://www.ocere.com/link-building">A good SEO outreach campaign</a> focuses on generating organic traffic to your site through legitimate, sustainable practices that demonstrate the value of your brand, and lead to a higher ranking in search engine results.',
    },
    {
      title: 'In What Countries Are Your SEO Outreach Services Available?',
      description:
        'We have a multinational client base of more than 3,000, and our team of writers includes native speakers in English, French, German, Spanish and Italian. We have also worked on campaigns for Scandinavian, Latin American, Australian, Russian, South African and North American markets.',
    },
    {
      title: 'How Important is Blockchain SEO Outreach?',
      description:
        'The market for Blockchain technology is constantly expanding as yet more businesses harness the many benefits and applications it holds for security, efficiency and growth. Blockchain SEO Outreach tackles the issue of visibility head on by addressing the qualitative factors that influence Domain Authority. Valuable content speaks for itself, but generating an organic readership requires more than the content itself. Search engine algorithms look for indicators of quality that go beyond your own website. By developing a strategy that builds upon your online profile through authoritative and relevant sites, you will see considerable improvement in traffic and search engine rankings.',
    },
    {
      title: 'How Long Does SEO Outreach Take?',
      description:
        'This is largely dependent on the size of the campaign, which can take anywhere between 7-10 days or 20-30 days to complete. Any tangible results from SEO outreach can take a few more weeks to appear, but this progress will be more sustainable, and of a higher quality than ‘shortcut’ SEO techniques.',
    },
  ],
} as Index;
