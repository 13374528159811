import {Index} from '@/content/pages/persona';

export default {
  url: '/referral-partners',
  meta: {
    title: 'Referral Partners | Ocere',
    description: `Build a significant income stream by connecting Ocere with companies in need of digital marketing, whether that's SEO outreach services, SEO or PPC. Click here for more info.`,
  },
  green: {
    graphic: 'referral-partner',
    name: 'Referral Partners',
    catchphrase: 'Referral Partners',
    heading: 'Spread the word and earn commission',
    paragraph: [
      'Become part of the fastest growing area of our business and build up a significant income stream by ' +
        'simply connecting us with companies in need of Digital Marketing.',
      'You’ll receive 10% commission on a client’s monthly spend and can feel confident in ongoing ' +
        'earnings thanks to our excellent track record for client retention, and campaign expansion.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>Simply introduce us to a contact with a need of any of our services, and we’ll ' +
      'get in touch to discuss their goals and create a tailored proposal. Once they start working with us, ' +
      `we'll pay you 10% of their monthly fee for the lifetime of their campaign, or 20% of their spend ` +
      'in free digital marketing work for your own business if you prefer. Either way, we’ll do all the ' +
      'work, while you sit back and reap the rewards.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver them. ' +
      'Many of our clients have had frustrating experiences with other agencies and are delighted to ' +
      'discover an entirely different experience when working with Ocere. If you are a digital marketing ' +
      'agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve your ' +
      'service to clients, have you considered white label SEO outreach service? ' +
      'If you are working as a freelance business adviser, perhaps adding a SEO outreach reseller ' +
      'service to your business model.</p>',
  },
} as Index;
