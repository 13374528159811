import {Index} from '@/content/pages/persona';

export default {
  url: '/services/editorial-link-building',
  meta: {
    title: 'Editorial SEO Outreach Services | Ocere [2021!] ',
    description:
      'Ocere’s editorial SEO outreach services provide clients with links from some of the most authoritative and wide-reaching sites online. Click here to read more.',
  },
  green: {
    graphic: 'white-label',
    name: 'Editorial SEO Outreach',
    catchphrase: 'Editorial SEO Outreach',
    heading: 'Gain high-quality links and mentions to your website',
    paragraph: [
      'At its core, SEO outreach offers one of the most valuable tools for demonstrating quality, knowledge, and authority within your specific market, and for providing Google’s algorithm with tangible evidence of the value your site offers to its users. Of course, some links are easier to come by than others and, more often than not, they are acquired at a steady rate each month through an effective blogger outreach strategy.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>The highest level of quality – and, in many ways, value – however, is found through editorial SEO outreach. In this way, your backlinks are obtained from top tier publications.</p>' +
      '<p>At Ocere, we have formed relationships with many top tier publications in order to offer our clients strong, diverse and unique SEO outreach strategies. We understand the requirements for obtaining links from some of the highest authority sites online, and can create content that truly speaks to the nature and value of the site, and to yours.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver ' +
      'them. Many of our clients have had frustrating experiences with other agencies and are delighted ' +
      'to discover an entirely different experience when working with Ocere. If you are a digital ' +
      'marketing agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve ' +
      'your service to clients, have you considered white label SEO outreach service? If you are working ' +
      'as a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'Why Are Top Tier Sites Valuable for SEO Outreach?',
      description:
        'The primary purpose of all SEO outreach strategies is to widen the scope of your online presence by obtaining backlinks from external sites, with a view to demonstrating your value to Google, and to generating more relevant traffic to your own site by attracting the attention of their audiences through strong content. Top tier sites garner considerable audiences. <a href="https://www.forbes.com/connect/audience/#:~:text=Every%20month%2C%20we%20reach%20more,%2C%20through%20video%2C%20and%20print.">Forbes, for instance, is able to boast a readership</a> of more than 140 million, all of whom expect a high level of quality, reliability and originality. As such, these sites offer the most lucrative outlet in terms of generating more authority within search engine results, and your growing readership.',
    },
    {
      title: 'Who Writes Your Content?',
      description:
        'We have a strong team of writers ready to create well-researched and original content in a wide range of formats and styles, from articles and guides to blog posts and press releases. Our team understands how to craft engaging pieces that will truly capture the attention of top tier audiences, in addition to appealing to the algorithmic requirements for higher rankings within search engine results.',
    },
    {
      title: 'In What Languages Can You Create Content?',
      description:
        'Ocere’s writers are all native speakers, allowing us to offer content in a wide range of languages, including English, German, Spanish, Italian, French and Portuguese.',
    },
    {
      title: 'How Do You Write High Quality Content?',
      description:
        'The best content is approached from a strong foundation of research, market understanding, keyword analysis and original topic creation. The work needs to be written with an authentic audience in mind, rather than merely being created to appeal to Google’s algorithm, and it should aim to address a relevant subject with a high level of understanding, and create a natural opportunity for your own backlink.',
    },
    {
      title: 'How Long Does Creative Content Take?',
      description:
        'Creating effective content takes a great deal of time and practice, which is why <a target="_blank" href="https://www.ocere.com/services/content-creation">Ocere’s creative content services</a> have proven so valuable to our clients. We are able to produce content in as little as three days, with prices starting at just £0.05 ($0.06) per word.',
    },
  ],
} as Index;
