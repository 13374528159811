import {Index} from '@/content/pages/persona';

export default {
  url: '/services/managed-services',
  meta: {
    title: 'Managed Digital Marketing Services | Ocere [2021!]',
    description:
      'Managed digital marketing services providing fully bespoke stargeies for those interested in SEO, SEO Outreach, and PPC. Get in touch today.',
  },
  green: {
    graphic: 'white-label',
    name: 'Managed Services',
    catchphrase: 'Managed Services',
    heading: 'Fully bespoke campaigns to your exact needs',
    paragraph: [
      'You want to beat your competitors, but may not the time, desire or resource to run everything internally?',
      'Are you looking for a company to come up with a strategy and then execute your SEO Outreach, Content, SEO or PPC efforts?',
      'The correct strategy, combined with consistent execution is the key to achieving results.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>With a “Managed Service” campaign, we’ll create a strategy for you, plan everything (obviously subject to your approval) and deliver exactly what you need. You can have as much or as little input as you desire. </p>' +
      '<p>This is a fully bespoke service – tailored to your exact needs. We’ll professionally project manage your project to ensure on-quality and on-time delivery – and the results you desire.</p>' +
      '<p>All reporting and workflow can be done to your exact requirement. We can use Google sheets, Office 365, any Project Management app, or anything else you use – just let us know!</p>' +
      '<p>Benefit from a dedicated and experienced account manager and proven delivery process for all forms of SEO and Digital Marketing.</p>' +
      '<p>All of our services are available as a “Managed Service”. We can create any unique mix of services to craft the ideal campaign for you. It’s also possible to supplement this with additional content/links via our online ordering platform.</p>' +
      '<p>Managed Services clients can still login to their client portal to get full visibility on all work (We are 100% transparent). Along with world-class support, via phone, Skype, Email or however you prefer.</p>' +
      '<p>Simply get in touch with us today – and we’ll craft a tailored proposal for your exact needs.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver ' +
      'them. Many of our clients have had frustrating experiences with other agencies and are delighted ' +
      'to discover an entirely different experience when working with Ocere. If you are a digital ' +
      'marketing agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve ' +
      'your service to clients, have you considered white label SEO outreach service? If you are working ' +
      'as a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'How do I create links in SEO?',
      description:
        'You can start a blog. You can ask for back links from influencers. You can reach out to webmasters of sites which accept guest blog posting. You can list your site in trustworthy directories. Alternatively, engage Ocere, a SEO outreach agency that has been delivering quality, sustainable SEO outreach services for over eleven years.',
    },
    {
      title: 'How do you create a link to your website?',
      description:
        'Reach out to influencers and ask them to create a link to your website? Submit your site to trusted local directories. Distribute content to Webmasters and owners of sites in complementary niches. Engage Ocere’s SEO SEO outreach services. We have developed highly successful SEO outreach strategies for thousands of clients in many diverse industries and markets.',
    },
    {
      title: 'Is SEO outreach still relevant to SEO?',
      description:
        'Yes! In fact, SEO outreach which deploys quality, relevant content is one of the cornerstones of any SEO strategy or blogger outreach service. SEO outreach packages from Ocere are constantly evolving to maximise search opportunities and each contain an outreach SEO outreach element drawn from our affordable SEO outreach services.',
    },
    {
      title: 'What is SEO outreach in off page SEO?',
      description:
        'Off page SEO SEO outreach is a strategy of <a target="_blank" href="https://www.ocere.com/services/blogger-outreach">reaching out to bloggers</a>, webmasters, owners of websites and other influencers to place unique, relevant content on their websites, which have a link pointing to your chosen webpage. It is often referred to as link outreach and often includes SEO outreach PR. There is an element of SEO outreach in all of Ocere’s SEO packages.',
    },
    {
      title: 'How do you do SEO on page?',
      description:
        'You have to start with keyword research and competitor research – lots of it. <a target="_blank" href="https://www.ocere.com/services/content-creation">Create quality, engaging and relevant content</a> in which to insert suitable keywords and search terms. You need to apply this to ALL pages of your website. Alternatively, engage Ocere to develop an on page SEO and SEO outreach strategy to save you time and money. We have many agency clients who use our white label SEO and SEO outreach services for their own clients.',
    },
    {
      title: 'What is link baiting?',
      description:
        'In a nutshell, it is the practice of creating quality content which is designed in such a way as to get other content producers to link to your content.',
    },
    {
      title: 'What are SEO outreach strategies?',
      description:
        'Reach out to site owners. Write brilliant content. Match your content to websites where it can be published. Employ social media to amplify your content. Alternatively, Ocere is a SEO outreach company with over 11 years experience of delivering complete SEO outreach services successfully to thousands of clients in many diverse markets and industries.',
    },
    {
      title: 'What are SEO outreach campaigns?',
      description:
        'Ocere’s SEO outreach service is typically the process of increasing the number of links which point to your website. This is achieved in a SEO outreach campaign by utilising assets which belong to the website. An asset can be anything from content, products, services or news for example to help acquire those links. Typically, Ocere’s SEO outreach campaigns, to achieve best results for clients, work on a month to month basis',
    },
    {
      title: 'What is SEO outreach strategy?',
      description:
        'A SEO outreach strategy is the overall strategic plan which a SEO outreach agency will develop to raise a website’s ranking and drive more targeted, organic traffic to a website. It will be based on research into popular (and not so popular) keywords and search phrases. Disavowing links, cleaning up links, competitor analysis and selecting suitable sites for publishing content are just a few elements of successful SEO outreach strategies. Successful SEO outreach requires a fluid approach and will develop and evolve over time.',
    },
    {
      title: 'What does SEO outreach mean?',
      description:
        'Quite simply, SEO SEO outreach is the strategy of acquiring links from other websites to your own website. Ocere’s SEO outreach packages have evolved (and continue to do so) over time to reflect the changes in search and how important building links is to the success of any SEO strategy.',
    },
    {
      title: 'What is tiered SEO outreach?',
      description:
        'Tiered SEO outreach simply means that when you acquire high quality links from Ocere’s SEO outreach services, which links are built from a variety of sources.',
    },
    {
      title: 'What is outreach SEO outreach?',
      description:
        'Quite simply, Ocere’s outreach SEO outreach service delivers authoritative written content which is published on high quality websites. Basically, we have reached out to many thousands of websites’ owners and webmasters and we are able to publish unique content on those websites with a link pointing to your website.',
    },
    {
      title: 'What is white hat SEO outreach?',
      description:
        'White hat SEO outreach is a strategy of driving more targeted, organic traffic to a website using SEO outreach, linking relevant content on other websites to your website. See more information about Ocere’s professional SEO outreach services on our SEO outreach page.',
    },
    {
      title: 'How do you create a high quality backlink?',
      description:
        'There is no easy way. You will need to Leverage public relations, write competitive, relevant quality content, reach out to website owners and webmasters to see if they will publish your content and constantly monitor the links when they have been published. Alternatively, you could let Ocere take the strain and use our link outreach service.',
    },
    {
      title: 'What is private blog network?',
      description:
        'A private blog network (PBN) is a group of interlinked websites, typically all sub-domains of a single URL. Typically, PBNs add little to no intrinsic value or long term value to the websites they link to. Ocere’s link outreach services deploy SEO outreach strategies that steer away from using PBNs.',
    },
    {
      title: 'What is black hat SEO outreach?',
      description:
        'Black hat SEO outreach refers to a set of practices which are deployed to increase the ranking of a web page or website in search engine returns. Black hat techniques violate the terms of service of Google, Bing, Yahoo and other search engines. Black hat is used as a term to describe the bad guys in SEO, and originated from references in western movies where the bad guys tended to wear black hats – a little potted history lesson for you.What is White Hat SEO?' +
        'White hat SEO is the complete opposite of black hat SEO and SEO Outreach – see the answer to the question ‘What is black hat SEO outreach?',
    },
    {
      title: 'What is best SEO technique?',
      description:
        'There is no one best SEO technique. A reputable link agency will (or should) tell you that you should focus on creating valuable, authoritative, engaging content. You will need to link outreach. You will need to research and then do some more research on keywords. There should be lots of competitor analysis and of course you should stay up to date with the ever changing Google search algorithm. If this sounds daunting (and it is) leave all the hard work to Ocere – Our best practice SEO and affordable SEO outreach services is based on years of (and ongoing) in depth research in to best practice digital marketing techniques.',
    },
    {
      title: 'What are the SEO tools?',
      description:
        'Ocere’s SEO outreach packages are the front end of a lot of research using industry standard tools such as Moz, Majestic, AhRefs, Keyword Finder and SEMRush. Using these tools we analyse search trends, organic traffic numbers, competitor analysis, keyword trends and other element of organic search so you don’t have to. Our complete SEO outreach services and SEO services are built around the professional tools we sue and a lot of hard earned knowledge and experience.',
    },
    {
      title: 'Is SEO Dead?',
      description:
        'No! Without deploying SEO to your website, you are just a flickering ember adrift in an ocean of bright searchlights, with little to no hope of being spotted. Don’t take the risk!',
    },
    {
      title: 'What are SEO strategies?',
      description:
        'SEO strategies are the processes of customising the content, headers, images, keywords and other elements of a website to achieve best results from search engine algorithms. These are the basics of the ‘signals’ your website emits which the search engine is ‘looking’ for. Any elements which are missing, invalid or incomplete will hold back the potential of your website.',
    },
    {
      title: 'How can I do SEO for free?',
      description:
        'Do lots of research! Master the intricacies and enigma that is the Google search algorithm (good luck with that one). Optimise your website. Create lots of meaningful, engaging, authoritative content. Outreach to webmasters and website owners. Build links. Monitor your external links - constantly. Then, if you have done all of this and more besides, you can pass Go and collect your £200. Then you carry on doing more of the same, day in, day out.',
    },
    {
      title: 'How do I build my SEO?',
      description:
        'Make a list of topics/products/services. Research the best long tail keywords for these topics/products/services. Build pages for each topic/product/service. Set up a blog. You should blog at least once a week to develop the web page and/or website authority. Create a SEO outreach plan. Stay up to date with current and future SEO practice and news items.',
    },
    {
      title: 'What is anchor text in SEO?',
      description:
        'Anchor text is the text contained in an article, blog entry, forum news item, list or other piece of text content which is clickable. The anchor text will have an embedded link to a landing page – it may be an internal link to another page on your own website, it may be to an external website or it may be a hyperlink on a piece of published content which points to your own website. It is the opposite of generic text.',
    },
    {
      title: 'What is a private website?',
      description:
        'A private website is, by its very name, typically open to invitation only. Registered partners, customers and other stake holders will be able to access only with a secure log in. It is the antithesis of a website which is accessible to the browsing public.',
    },
    {
      title: 'How do you make an anchor text link?',
      description:
        'Different applications will have different buttons and icons (maybe) but the general rule of thumb is as follows. Highlight the text which you want to use as the anchor text. Click the link icon which appears in the tool bar. Add the target landing page address in full in the relevant text field for which the anchor text will be clicked for navigation to said landing page. Click the save button. Your hypertext link has been created.',
    },
  ],
} as Index;
