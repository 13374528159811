import {Blog} from '@/content/blogs/model';

export default {
  url: 'why-meta-descriptions-are-still-important-in-2020',
  title: 'Why Meta Descriptions are Still Important in 2020',
  category: '',
  // metaTitle: '',
  // metaDescription:'',
  thumbnail:
    'http://images.ctfassets.net/p5gt7dkd4wn3/3gjf4jg7XEBLFga7CQh6uu/73ec7eee6fc550c3541a8801d1e223b5/SEO.jpg',
  description: [
    {
      type: 'paragraph',
      title: 'Why Meta Descriptions are Still Important in 2020',
      content: [
        'Along with the title, the meta description is the first glimpse any internet user will have of your page; it offers an opportunity to showcase the high quality content contained within it, and the value it holds for users searching the keywords and phrases.',
      ],
    },
    {
      type: 'paragraph',
      title: 'Do Meta Descriptions Affect Ranking?',
      content: [
        'No, <a href="https://webmasters.googleblog.com/2009/09/google-does-not-use-keywords-meta-tag.html">Google confirmed</a> back in 2009 that meta descriptions do not impact ranking, and there has been no discernible change in their algorithm since. In some instances, the description you provide won’t even be used by Google; if it does not appear to accurately summarise the contents of the page in question, it will be replaced by a sentence or two lifted from the content. There is, of course, nothing wrong with this, but a purpose-built description will do that bit more for compelling users to choose your site over another. ',
      ],
    },
    {
      type: 'image',
      url:
        'https://images.ctfassets.net/p5gt7dkd4wn3/7yI4mHgdXAZaBE7EnRw2uN/bff772cc058d89d0c5a50e0c1f5555d2/Meta.jpg',
    },
    {
      type: 'paragraph',
      title: 'What do Meta Descriptions Achieve?',
      content: [
        'Meta descriptions offer users a figurative window through which internet users can glimpse the contents of your site before clicking through. By effectively summarizing the page’s contents on the search engine results page, the meta description can help to draw in high quality and relevant traffic, and prevent extraneous users from raising your bounce rate and skewing Google’s Analytics. ',
        'Essentially, if meta descriptions offer pertinent information and capture the contents of the page, you stand a higher chance of drawing in the audiences you actually want. What’s more, these audiences stand a higher chance of finding genuine value in your site.',
        'And, while a meta description may not hold direct influence over your rankings on Google, user-click through and organic traffic do. Utilising the 160-character limit, ensuring that it is sufficiently accurate and compelling to attract new users, and that it contains a relevant keyword that appear in bold and attract the eye of searchers, continue to offer more benefit to your site.',
      ],
    },
    {
      type: 'image',
      url:
        'https://images.ctfassets.net/p5gt7dkd4wn3/Po5REHJZmeJ4woBc0CcuD/d5e5b64d9c15e0af99430ca972876caf/Meta2.jpg',
    },
    {
      type: 'paragraph',
      title: 'What is a Good Meta Description?',
      content: [
        'It should be concise, but offer internet users as much insight into the contents of the page as possible. Make use of the full 160-character limit, utilise keywords, and ensure brevity without being vague or passive. Google’s own meta description stands at 159 characters:',
        `“Search the world's information, including webpages, images, videos and more. Google has many special features to help you find exactly what you're looking for.”`,
        'As with Google’s, a good meta description will also compel users to click through to your site. A call to action, such as “Discover more”, “Read more” or “Click here to find out more”, will only serve to make your link more compelling to users. ',
      ],
    },
    {
      type: 'paragraph',
      title: 'Where Does the Meta Description Show Up?',
      content: [
        'If Google deems it applicable to the page, it will appear whenever that page appears in search results. Similarly, if your page is shared within a social media post, sites like Facebook and Twitter will also include your meta description; if you have not provided one then, like Google, it will typically be replaced by a key snippet from the content, which will be truncated by a…',
        'Putting together a meta description may not having any bearing on the page’s ranking, but it continues to offer an additional resource to anyone seeking greater volumes of high quality traffic to their page.',
      ],
    },
  ],
} as Blog;
