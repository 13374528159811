import {Index} from '@/content/pages/persona';

export default {
  url: '/services/cyber-security-seo',
  meta: {
    title: 'Cyber Security SEO Services | Ocere [2021!]',
    description:
      'SEO services designed to help those in the Cyber Security industry increase their online organic presence. Click to read more.',
  },
  green: {
    graphic: 'white-label',
    name: 'Cyber Security SEO',
    catchphrase: 'Cyber Security SEO',
    heading: 'Gain high-quality links and mentions to your website',
    paragraph: [
      'Cyber security represents an industry in perpetual forward motion. With a global market value <a style="text-decoration: none;color: #fff;font-weight: 800;" href="https://www.grandviewresearch.com/industry-analysis/cyber-security-market/">projected to surpass $167 billion by the close of 2020</a> — and an ever-evolving landscape of cyber attacks aimed toward our most sensitive information, both on a personal and commercial scale — the demand for cyber security services is only going to increase. As a result, competition for an increased share of a wide and diverse market will grow more intense, and sites failing to implement strong SEO practices will fail to bring in the high quality traffic.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>At Ocere, our Cyber Security SEO services will target relevant markets, building visibility and garnering greater volumes of organic traffic to your site. Our strategies target on- and off-site optimisation, high quality SEO outreach, keyword and competitor research, and continued monitoring with full transparency at every stage.</p>' +
      '<p>We have significant experience in Cyber, Tech and Finance verticals. We can help you plan, manage and execute a successful SEO outreach or SEO campaign. We have some 40,000 relationships with webmasters, news outlets, journalists and more to help you secure coverage for your website.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver ' +
      'them. Many of our clients have had frustrating experiences with other agencies and are delighted ' +
      'to discover an entirely different experience when working with Ocere. If you are a digital ' +
      'marketing agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve ' +
      'your service to clients, have you considered white label SEO outreach service? If you are working ' +
      'as a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'Why Do I Need SEO for My Website?',
      description:
        'The primary objective of any site is to increase traffic, and SEO offers by far the most comprehensive and fruitful strategy for meeting this end. Your ability to rank highly on search engine results pages, the quality of your on- and off-site content, the scope of your backlink profile, and your brand’s presence on social media channels are some of the most significant factors impacting traffic levels, and Ocere’s SEO services target each in turn.',
    },
    {
      title: 'What is Domain Authority?',
      description:
        'Your DA is a number between 1 and 100, used to ascertain how well you will rank within search engine results. Simply put, sites with the highest DA scores will rank the highest on relevant searches, and sites with a low DA will likely fall short of the first page of results altogether.',
    },
    {
      title: 'How Do I Stop Google Penalties?',
      description:
        'Google’s algorithm is designed to filter valuable and authentic sites from those that seek to manipulate rankings without offering anything of substance to internet users. ‘Blackhat’ seo techniques such as keyword stuffing and paying for backlinks can easily result in significant penalties for sites, which will cause a rapid decline in traffic levels, and much greater difficulty achieving a high rank on SERPs. The best way to avoid this happening — or to repair a damaged reputation with Google — is to follow an expertly designed SEO strategy comprising sustainable practices.',
    },
    {
      title: 'What is the Best SEO Strategy?',
      description:
        'One that seeks to augment your online presence by implementing informative and creative content, <a target="_blank" href="https://www.ocere.com/services/blogger-outreach">backlinks on high authority domains</a>, extensive market, competitor and keyword research, on-site optimisation, and strong social media marketing. Beyond that, continued monitoring — both for your live links and mentions, and keyword rank.',
    },
    {
      title: 'How Do You Write Search Engine Optimized Content?',
      description:
        'Begin with thorough competitor and keyword research and original ideas that will appeal to your market. Write <a target="_blank" href="https://www.ocere.com/services/content-creation">content that offers value to the reader</a>, augmented with strong keyword usage, images, and headers. For off-site content, you will then need outreach to relevant, high-authority publishers, webmasters and bloggers, and then constantly monitor live links. Alternatively, let Ocere handle every stage of the process for you.',
    },
  ],
} as Index;
