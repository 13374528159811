
















import { Component, Prop, Vue } from 'vue-property-decorator';
import EndUserGraphic from '@/assets/graphics/persona/end-user-graphic.vue';
import ReferralPartnerGraphic from '@/assets/graphics/persona/referral-partner-graphic.vue';
import WhiteLabelGraphic from '@/assets/graphics/persona/white-label-graphic.vue';

@Component({
  components: { EndUserGraphic, ReferralPartnerGraphic, WhiteLabelGraphic },
})
export default class extends Vue {
  @Prop() url!: string;
  @Prop() title!: string;
  @Prop() subtitle!: string;
  @Prop() text!: string;
  @Prop() graphic!: string;
}
