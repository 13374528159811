





































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Service} from '@/content/pages/services';

@Component
export default class extends Vue {
  @Prop() service!: Service;

  get exists() {
    return /service/.exec(this.service.url);
  }
}
