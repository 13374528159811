export const LICENSE_KEY_HOT_TABLE = 'non-commercial-and-evaluation';

export const makeIdString = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const makeIdStringCap = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const makeIdNumber = (length: number) => {
  let result = '';
  const characters = '0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const makeId = (length: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const roundNearest = (num: number, digit: number) => {
  if (!num || !digit) {
    return '0';
  }
  const exp = Math.pow(10, digit);
  const flatNumber = num * exp;
  const newNum = Math.round(flatNumber) / exp;
  return newNum.toLocaleString('en-US', {
    minimumFractionDigits: Number.isInteger(num) ? 0 : digit,
    maximumFractionDigits: Number.isInteger(num) ? 0 : digit,
  });
};
