











import {Component, Vue} from 'vue-property-decorator';
@Component({
  metaInfo: {
    title: 'Sitemap | Ocere',
    meta: [
      {
        content: 'View the Ocere sitemap.',
        hid: 'description',
        name: 'description',
      },
    ],
  },
})
export default class extends Vue {
  routes: any = [];
  mounted() {
    this.routes = (this.$router as any).options.routes;
  }
}
