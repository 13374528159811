import {Index} from '@/content/pages/persona';

export default {
  url: '/services/cbd-seo',
  meta: {
    title: 'CBD SEO Services | Ocere [2021!]',
    description:
      'SEO services designed to help those in the evolving CBD industry increase their online organic presence. Click to read more.',
  },
  green: {
    graphic: 'white-label',
    name: 'CBD SEO',
    catchphrase: 'CBD SEO',
    heading: 'Gain high-quality links and mentions to your website',
    paragraph: [
      'The CBD industry now features one of the most diverse and rapidly evolving markets online today. Once considered a niche product, growing awareness surrounding the many benefits CBD offers to our physical and mental well-being has catalysed a remarkable shift in public perception, leading to an industry with a <a style="text-decoration: none;color: #fff;font-weight: 800;" href="https://www.medgadget.com/2019/08/cbd-market-size-is-estimated-to-grow-at-a-cagr-of-125-5-by-2026-future-trends-insights-share-dynamics-and-global-cannabidiol-industry-overview.html/">projected CAGR of 125.5% by 2026.</a>',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>The food, beauty, and health industries — to name just a few — are all seeking new and original ways of integrating CBD into products in order to stake a claim to a thriving consumer market. As a result, both local and international brands must focus on building strong SEO strategies in order to solidify their online presence, gain traffic, and remain visible in a flourishing industry.</p>' +
      '<p>Ocere’s CBD SEO services offer modern, creative solutions to issues with visibility, low traffic, and authority on SERPs. We integrate on-site and off-site optimisation, high quality creative content, outreach, SEO outreach, social media channel marketing and competitor analysis to achieve steady and sustainable growth for your site.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver ' +
      'them. Many of our clients have had frustrating experiences with other agencies and are delighted ' +
      'to discover an entirely different experience when working with Ocere. If you are a digital ' +
      'marketing agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve ' +
      'your service to clients, have you considered white label SEO outreach service? If you are working ' +
      'as a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'What is CBD SEO?',
      description:
        'SEO comprises a number of techniques for ensuring that your online presence is optimised for relevant audiences, and Google’s algorithm, which is responsible for your site’s performance on search engine results pages (SERPs). <a target="_blank" href="https://www.ocere.com/services/seo-packages">An effective SEO strategy</a> will allow your site to rank higher in relevant searches, significantly increasing your ability to generate greater levels of organic traffic.',
    },
    {
      title: 'Why is SEO Important in 2021?',
      description:
        'The internet offers the most lucrative platform on which to engage with — and grow within — relevant markets, and as more and more brands seek to optimise their online profile (both on-and off-site), the top spots on SERPs are reserved for those who adopt comprehensive and high quality strategies that exemplify their value to internet users, and Google itself. Without SEO, sites are simply lost within low ranking positions, and will lose high quality traffic to competitors.',
    },
    {
      title: 'What is the Most Important Part of SEO?',
      description:
        'Essentially, a comprehensive, broad-based approach. SEO comprises many techniques and methodologies for augmenting your online presence, and by combining these in a way that targets relevant markets, you can begin to see tangible improvements in the quality and quantity of traffic coming to your site, and your ranking within search results. SEO outreach, <a target="_blank" href="https://www.ocere.com/services/content-creation">creative content and optimisation</a>, technical improvements to your site, outreach, and social media marketing are all integral to a strong online profile. At Ocere, we have been implementing these strategies — and providing impressive results for our clients — for more than ten years.',
    },
    {
      title: 'How Do I Start SEO?',
      description:
        'By committing hours upon hours to competitor research, site analysis, finding relevant and lucrative keywords, outreaching to authoritative webmasters, publishers, and bloggers, and creating high quality content optimised for search engines. Alternatively, take advantage of the insight and expertise offered by the team at Ocere, and save yourself plenty of time and money by focussing on core business.',
    },
    {
      title: 'In What Countries Do You Operate?',
      description:
        'We have worked with thousands of clients based all over the world, targeting both local and international markets. We work with a multilingual team of writers on hand to provide content optimised for audiences in the UK, Scandinavia, France, Germany, Russia, the USA, Latin America — and many more on request.',
    },
  ],
} as Index;
