<template>
  <transition name="fade" appear>
    <div class="cookie-notice content-wrapper" v-if="!cookies">
      <div class="content">
        <div>
          <p>
            We use cookies to personalise content and ads to analyse our
            traffic. We also share information about your use of our site with
            our advertising and analytics partners who may combine it with other
            information that you've provided to them or that they've collected
            from your use of their services.
          </p>
        </div>
        <button class="orange" @click="okay">Okay</button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'cookies',
  data() {
    return {
      cookies: true,
    };
  },
  methods: {
    okay() {
      window.localStorage.setItem('likes-cookies', 'true');
      this.cookies = true;
    },
  },
  mounted() {
    setTimeout(
      () => (this.cookies = !!window.localStorage.getItem('likes-cookies')),
      3000,
    );
  },
};
</script>

<style lang="scss" scoped>
.cookie-notice {
  position: fixed;
  bottom: 8px;
  left: 0;
  right: 0;

  .content {
    background-color: white;
    box-shadow: $shadow;
    border-radius: 8px;
    padding: 16px 24px;
    margin: 16px;
    display: flex;
    align-items: center;
    font-size: 14px;

    @include mobile-only {
      flex-direction: column;
      align-items: stretch;
      margin: 0;

      p {
        margin-bottom: 16px;
      }
    }

    @include tablet {
      div {
        margin-right: 32px;
      }
    }
  }
}
</style>
