import {Index} from '@/content/pages/persona';

export default {
  url: '/services/pr-link-building',
  meta: {
    title: 'PR SEO Outreach Services | Ocere [2021!]',
    description:
      'Digital PR SEO outreach services deigned to boost your SEO strategies and create increase visibilty for your brand. Click here to read more.',
  },
  green: {
    graphic: 'white-label',
    name: 'PR SEO Outreach',
    catchphrase: 'PR SEO Outreach',
    heading: 'Gain high-quality links and mentions to your website',
    paragraph: [
      'A comprehensive SEO outreach strategy is one of the most important factors influencing your organic search results, but <a style="text-decoration: none;color: #fff;font-weight: 800;" href="https://www.impactbnd.com/blog/seo-statistics#:~:text=55.24%25%20of%20pages,mobile%2C%2045%25%20from%20desktop.">more than 50% of webpages have not obtained any links</a>, which makes increasing visibility, traffic, and Domain Authority impracticable for countless sites and brands online today.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>Ocere’s high-impact Digital PR SEO Outreach Service helps your brand earn links and mentions from high-authority, trusted news portals and sites.We handle all of the strategy, brainstorming, content-creation and outreach upfront and without cost. You only pay for actual links achieved, which takes the risk out of Digital PR and allows you to scale up your online profile knowing that you can be confident in the results.</p>' +
      '<h2 class="c-h2">How Long Does SEO Outreach Take?</h2>' +
      '<p>Ocere’s PR SEO Outreach service is incredibly popular, which means that there can be minor variations in our time scales. In general, we have a turnaround time of just six to eight weeks, and will complete the service by sending a report detailing all links and mentions achieved, along with their SEO metrics (DA/DR or your other preference).</p>' +
      '<h2 class="c-h2">Do You Work in the iGaming Market?</h2>' +
      '<p>Yes, Ocere is one of the few SEO outreach and digital PR agencies to have <a target="_blank" href="https://www.ocere.com/services/igaming-seo">comprehensive experience in the igaming market</a>. We have been working with many of the top operators for 5+ years, to great success.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver ' +
      'them. Many of our clients have had frustrating experiences with other agencies and are delighted ' +
      'to discover an entirely different experience when working with Ocere. If you are a digital ' +
      'marketing agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve ' +
      'your service to clients, have you considered white label SEO outreach service? If you are working ' +
      'as a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'How Do You Create a SEO Outreach Strategy?',
      description:
        'The process first involves brainstorming. Depending on the level of input you wish to have, this stage of the process can be a collaborative effort, or you can leave it to us to come up with the ideas. However involved you choose to be, we will always wait for your approval before proceeding. Next, with your confirmation, we will move onto production. We will create press releases for outreach purposes, along with written content and infographics, and upload them to our secure, cloud-based project management systems for you to read and approve. Finally, we move on to outreach. We find the most relevant sites for your market, and leverage our press contacts in order to secure links and mentions on your behalf.',
    },
    {
      title: 'How Much Do Links Cost?',
      description:
        '<p>There is no upfront payment; you only pay for live links and mentions on completion of the campaign. Pricing is based on the Domain Authority of the sites from which we obtain links, as follows:</p>' +
        '<ol>' +
        '<li>We do not charge for any link below DA30</li>' +
        '<li>DA30+ £495</li>' +
        '<li>DA40+ £595</li>' +
        '<li>DA60+ £795</li>' +
        '<li>DA70+ £995</li>' +
        '<li>DA80+ £1495</li>' +
        '<li>Online citations will be charged at £295 each.</li>' +
        '<li>(Optional) Google Survey Cost: £500. This is paid directly to Google and does need to be paid upfront if you choose to do a survey (we recommend).</li>' +
        '<li>(Optional) Infographic Cost: £695. If you choose to do an infographic, this would be an extra cost upfront.</li>' +
        '</ol>' +
        '<p>Upon request and approval, we can look to set a cap for the campaign — at, say, £20k — to give you peace of mind. As soon as the campaign is completed, we will send you our report along with an invoice, which needs to be cleared within 7 days.</p>' +
        '<p>We operate a &quot;win-win&quot; philosophy, and from 1000&#39;s of discussions and campaigns with clients over the past 11 years, we have found our Pay on Results model to work the best.</p>',
    },
    {
      title: 'How Many Backlinks Do I Need?',
      description:
        'This is entirely dependent on your campaign, and we can offer advice on building your backlink profile when you get in touch. On the lower end of the scale, we would expect a minimum of 5-10 links, with many of our campaigns yielding between 20-30. One of our larger campaigns brought in 157 links, with an average DA of 69.',
    },
  ],
} as Index;
