import dynamicLoadScript from '@/utils/dynamicLoadScript';
import store from '@/store';
const initPaypal = () => {
  dynamicLoadScript(
    'paypalSdk',
    `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_CLIENT_ID}&currency=GBP&disable-funding=credit,card`,
    () => {
      store.dispatch('doInitializingPaypal');
    }
  );
};

const initExternalLib = () => {
  initPaypal();
};

export default initExternalLib;
