import {Index} from '@/content/pages/persona';

export default {
  url: '/multilingual-seo',
  meta: {
    title:
      'Multilingual SEO | #1 International SEO Outreach Agency | Ocere [2021!]',
    description:
      'Multilingual SEO services for those looking to increase their organic visibility in a range of international markets. Click here to get started.',
  },
  green: {
    graphic: 'end-user',
    name: 'Multilingual SEO',
    catchphrase: 'Multilingual SEO',
    heading: 'Is Multilingual SEO powering your website business?',
    paragraph: [
      'The whole point of SEO and content marketing is to expand your sphere of influence, increase sales ' +
        'and revenues and thereby increase profits.',
      'Ok, so you are doing well and prospering in your home ' +
        'country. Your audience is expanding, organic traffic is increasing and sales of products and ' +
        'services continue to climb.',
    ],
  },
  content: {
    'Now where to go?': `
                <p class="paragraph">Well, you may have considered expanding sales and services in to other countries; after all, we are continually told the Internet has broken down many of the barriers to global trade. So what is holding you back; why can’t you sell to consumers abroad when others are doing so and quite successfully?</p>
                <p class="paragraph">It is probably the issue of language – how do you communicate to new customers and enhance your potential revenues that don’t speak or read your language? Well, the answer is quite simple - <b>Multilingual SEO</b>. Deploying <b>multilingual SEO</b> will help your website achieve maximum visibility in the local search engines.</p>
                <p class="paragraph"><b>Global SEO</b> founded on <b>multilingual SEO</b> is only the beginning – of course, you will need the expertise of someone who speaks and writes the target language and can seamlessly manage a strategy which serves many cultures, languages and locations.  </p>
                <p class="paragraph">Let our <b>multilingual SEO</b> experts prepare your global SEO marketing strategy. We have a team of experienced, multilingual marketing specialist who create a unique strategy designed to give your business a significant competitive advantage in foreign markets. Our team will design a <b>global SEO</b> strategy to increase your brand’s exposure to local audience.</p>
                <p class="paragraph">Our team can write and communicate ideas and market solutions, all with the local nuances and colloquialisms, in the following languages:</p>
                <ul>
                    <li>French</li>
                    <li>German</li>
                    <li>Spanish (Spain, Latin America etc.)</li>
                    <li>Portuguese</li>
                    <li>Brazilian Portuguese</li>
                    <li>Italian</li>
                </ul>
                <br>
                <p class="paragraph">Whether you are looking for completely integrated SEO services or standalone <b>SEO writing</b>, <a href="https://www.ocere.com/link-building">SEO outreach services</a>, integrated <a href="https://www.ocere.com/services/seo-packages">SEO packages</a> or <b>PPC</b>, we deliver the very latest in multilingual SEO techniques, search behaviour in your target country location, complete with local language and cultural nuances which are specific to the locality.</p>
                <p class="paragraph">Ocere works for many companies which have international markets. Some of those companies we have been working for when their only market was their home country. We work for real estate businesses, construction companies, shipping companies and retail and fashion companies with a good mix of SMEs as well as multinational brands. We have solutions for virtually any industry or niche market.</p>
                <p class="paragraph">In particular, we have a specialist <a href="https://www.ocere.com/casino-seo">casino SEO</a> team which has had great success for our online gaming clients.</p>
                <p class="paragraph">We have to harness the aforementioned elements otherwise we could not create and deliver successful campaigns which connect to the local audience and drive your brand in their market.</p>
                <p class="paragraph">The basics of our <b>global SEO</b> are based on research into the following:</p>
                <ul>
                    <li>Local linguistic keyword research</li>
                    <li>The local market behaviour</li>
                    <li>Off page and on page optimisation</li>
                    <li>Optimisation of social media</li>
                    <li>Continual monitoring of all of the above</li>
                </ul>
                <br>
                <p class="paragraph">You may be wondering why you should choose to engage Ocere for your <b>global SEO</b>. Well, here are five good reasons to engage Ocere as your <b>multilingual SEO</b> partner:</p>
                <ul>
                    <li>Multi lingual natural speakers and writers working for you</li>
                    <li>Thorough research of the local market to formulate a coherent strategy</li>
                    <li>Efficient and timely delivery of <b>multilingual SEO</b> content</li>
                    <li>Affordable <b>digital marketing services</b> to suit all budgets</li>
                    <li>Regular, on time reporting of ALL elements of our services</li>
                </ul>
                <br>
                <p class="paragraph">If you are ready to take the next step, from being a local success to moving on to the global stage, Ocere’s <b>multilingual SEO</b> service should be your next port of call.</p>
                <p class="paragraph">Call or email us today to find out more about how we can help power you to international success.</p>
            `,
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver them. ' +
      'Many of our clients have had frustrating experiences with other agencies and are delighted to ' +
      'discover an entirely different experience when working with Ocere. If you are a digital marketing ' +
      'agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve your ' +
      'service to clients, have you considered white label SEO outreach service? If you are working as ' +
      'a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'How do you do SEO in other languages?',
      description:
        'First, decide which languages you want to target. Then you have to decide on whether to take a multilingual SEO approach, a multiregional SEO approach – or both. Decide which content to translate – you may not have to translate ALL your content.',
    },
    {
      title: 'What is SEO language?',
      description:
        'Multilingual SEO focuses on making sure content (off site and on site) is optimised for different languages and local search engines. focuses on optimizing content for different languages for the search engines. Setting out a strategy for multilingual or global SEO will enable local consumers to find your website in their native language.',
    },
    {
      title: 'How do I target my country SEO?',
      description:
        'You can use a country specific top level domain name. Use Google Search Console to specify your target country. Host the website locally if possible. Create off site and on site multilingual SEO optimised content.',
    },
    {
      title: 'What is multi language website?',
      description:
        'By definition, a multilingual SEO enabled website is one which provides written content in more than one language. As an example, an English language website might have content in French and German or more languages, depending on the target market.',
    },
    {
      title: 'Is SEO free?',
      description:
        'No! A digital marketing agency will charge you for any SEO work carried out for your website. Even if you do SEO yourself, there is a cost in time spent on keyword research, SEO copywriting, blogger outreach and other elements of work you would carry out yourself.',
    },
    {
      title: 'How do you do international SEO?',
      description:
        'You will need to specify your target region or country. Establish which language you will be writing for and establish language tabs. Understand the colloquialisms and nuances of the people and language you are targeting.',
    },
    {
      title: 'How do I make my website International?',
      description:
        'Optimise the onsite and offsite content for single and/or multiple languages. Avoid any rigidly inspired localised options. <a target="_blank" href="https://www.websitebuilderexpert.com/building-websites/how-to-build-a-multi-language-website/">Create multiple versions of your website in multiple languages</a>, or select specific pages on a single website which target the relevant language and the people that speak it.',
    },
    {
      title: 'How do you do country wise SEO?',
      description:
        'Use a country specific TLD. Specify a target location in Google Search Console. Host the website locally if possible. Write the relevant content in the language of the target country. Be prepared for multiple languages in use in a single country.',
    },
    {
      title: 'How do I promote my website internationally?',
      description:
        'Target your chosen language(s) with quality SEO writing and multilingual SEO. Then concentrate on social media marketing elements, signature branding, colloquial language and know your target audience. SEO is the same regardless of the language you write for you target audience.',
    },
    {
      title: 'What is ccTLD in SEO?',
      description:
        'A ccTLD is a country code top-level domain which defaults to a specific location. Top Level Domains (TLDs) can be geo-targeted to point at specific countries.',
    },
    {
      title: 'Which of the listed factors are important for local SEO?',
      description:
        'A secure website, fast page load speed, a website which is mobile friendly, optimised content, regular fresh multilingual SEO content.',
    },
    {
      title: 'What is global SEO?',
      description:
        'Global SEO is the correct SEO to deploy if you want your business to reach foreign markets, when you are trying to target an international audience.',
    },
    {
      title: 'What is SEO localisation?',
      description:
        'Localisation multilingual SEO helps connect your brand to location or country using words, colloquialisms and behaviours of an audience which resides in a particular country or region.',
    },
    {
      title: 'What is content localisation?',
      description:
        'Making sure your content is translated and adapted appropriately so it engages the target audience in their own language.',
    },
    {
      title: 'What are premium domains?',
      description:
        'Premium domains are typically domains which have high-value and have the potential to become popular web addresses. As with anything which has a high POTENTIAL value, the cost of domain registration and transfer may also be inflated.',
    },
    {
      title: 'What are domain codes?',
      description:
        'These are the codes for country locators, typically reserved for independent countries, sovereign states and/or dependent territories which can be identified with a country code.',
    },
    {
      title: 'What are top level domain names?',
      description:
        'Top Level Domain names are typicall associated with a country. For example, the TLD of France is .fr and for Germany it is .de.',
    },
    {
      title: 'Is SEO easy?',
      description:
        'SEO, and in particular multilingual SEO, is only easy when you know what you are doing. However, it can take a long time to master and even experienced SEO experts will often admit to learning something new most days. It is a continuous learning curve – as trends change, so the learning process has to take this in to account to maintain up to date knowledge.',
    },
    {
      title: 'Is coding required for SEO?',
      description: 'Coding knowledge is not required for SEO.',
    },
    {
      title: 'Why is SEO so important?',
      description:
        'Quality SEO adds trust and credibility to your website. The goal of any SEO campaign is to build trust, credibility, authority and relevance to your target audience. Without SEO, you have none of quality these attributes or markers.',
    },
    {
      title: 'How do you handle international clients?',
      description:
        'Be aware of time zone difference – your client may not like being called in the middle of the night when for you it is mid morning. Specify the currency in which you will be invoicing. Pay close attention to potential legal issues and ALWAYS be aware of cultural differences.',
    },
    {
      title: 'How do you target US traffic?',
      description:
        'Research keywords for the United States – this would be an element of global SEO is your business is located outside the United States. Use location specific keywords. Use sites for SEO outreach which have authority in that region.',
    },
    {
      title: 'How do I market my business internationally?',
      description:
        'Determine where international traffic is originating. Make sure your content is language appropriate for your target foreign market place. Be aware of trade barriers or tariffs on products – it could be a legal minefield if you do not research thoroughly.',
    },
    {
      title: 'How do you translate a website?',
      description:
        'You can use Google Translate (not the most accurate of tools) or better still, engage the services of a multilingual SEO specialist such as the team at Ocere.',
    },
    {
      title: 'How do you make a multilingual website?',
      description:
        'Create your website in your primary language. Engage a multilingual SEO specialist such as the team at Ocere to translate your content in to your chosen target language(s). Create multiple language versions of your primary website.',
    },
    {
      title: 'How do I target my country specific traffic?',
      description:
        'Geo targeting Google tool, host your website on a server in the target country or region, build back links in the colloquial language, use Google Places for localised SEO in your targeted area or engage the services of a multilingual SEO specialist such as the team at Ocere.',
    },
  ],
} as Index;
