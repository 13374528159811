import {Index} from '@/content/pages/persona';

export default {
  url: '/services/may-2020-changes-to-organic-visibility',
  meta: {
    title: 'May 2020 Changes to Organic Visibility | Ocere',
    description: `Learn about the impact of Google's May 2020 broad core algorithm update and discover why many brands and sites experienced unprecedented highs and lows in their rankings. Read more.`,
  },
  green: {
    graphic: 'white-label',
    name: 'May 2020 Changes to Organic Visibility',
    catchphrase: 'May 2020 Changes to Organic Visibility',
    heading: 'Gain high-quality links and mentions to your website',
    paragraph: [
      'During early May 2020, Google rolled out its first broad core algorithm update of the year. Both the impact on SERPs and the reception across Twitter and SEO discussion forums was significant, with many brands and sites experiencing unprecedented highs and lows in their rankings.',
      'The effects were certainly felt across the board — although some industries suffered from volatility in their SERP rankings more than others — so much so that Moz recorded the highest core update temperature since the summer of 2018.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>SEOs and SERP trackers all reported tempestuous conditions — and cryptic results — in Google’s search rankings. Within the first few days of this core update, Babycenter.com, gamesgames.com, and grubhub.com were among some of the greatest casualties, according to Moz’s <a href="https://docs.google.com/spreadsheets/d/1yIMiV6M0NXJZ6D05-OfAsbz0JYj9MIMjkVfOpdda69I/edit#gid=0">analysis</a>, while parents.com, everydayhealth.com, and play.google.com were among the best-faring sites by the end of day one.</p>' +
      '<p>There were a few red herrings. For instance, LinkedIn disappeared from search rankings entirely on the second day of the update, but this turned out to be the result of it being <a href="https://www.searchenginejournal.com/linkedin-temporarily-deindexed-from-google/366610/">de-indexed by mistake</a>. However, significant disruptions were felt across the board, with some considerable inconsistencies between days one and two as Google revised the update, and continued to play around with rankings.</p>' +
      '<p>There was wide criticism over Google’s new preference for ranking social media over sites containing quality content; <a href="https://www.searchenginejournal.com/google-may-2020-update-over/368962/">Pinterest</a>, in particular, began to dominate the SERPs for a number of keywords — even after the completion of the update.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver ' +
      'them. Many of our clients have had frustrating experiences with other agencies and are delighted ' +
      'to discover an entirely different experience when working with Ocere. If you are a digital ' +
      'marketing agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve ' +
      'your service to clients, have you considered white label SEO outreach service? If you are working ' +
      'as a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'Should We Worry About Our SEO Strategy?',
      description:
        'It only takes a brief look at the countless Tweets, reports and analyses that bubbled up during the first few weeks of May to see that, even by Google’s usual standards for broad core changes, this update was significant. No one particular industry was favoured — or penalised — by these changes to the algorithm; rather, part of the reason why this update caused so much stir across the SEO forums and Twitterverse is down to the far-reaching and mercurial impact it had on search engine rankings. Gamesgames.com, for instance, had suffered from a 50% loss by the end of day one, whilst play.google.com fared better than most other sites. Even now, there remains a great deal of confusion over the primary intentions of this update. Already, however, we are beginning to see more consistency in the metrics as the inflammatory weather surrounding this broad core algorithm update settles. And while much of Google’s algorithm remains a well-kept secret, we can be sure that it will continue to value high-quality, relevant and informative content on its SERPs. <a target="_blank" href="https://www.ocere.com/link-building">Obtaining quality backlinks from high authority sites</a>, strong keyword research, original content and accurate reporting remain integral to your SEO strategy, and to maintaining a high ranking with Google’s algorithm. Dramatic shifts brought about by changes to the algorithm are enough to cause a stir until the dust has settled, but it is unlikely to remain sustainable without a sound approach to SEO.',
    },
  ],
} as Index;
