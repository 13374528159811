import {Index} from '@/content/pages/persona';

export default {
  url: '/services/content-syndication',
  meta: {
    title: 'Content Syndication Services | Ocere [2021!] ',
    description:
      'Ocere’s content syndication services enable clients to find beneficial and relevant platforms for the republication of their content. Click here to read more.',
  },
  green: {
    graphic: 'white-label',
    name: 'Content Syndication',
    catchphrase: 'Content Syndication',
    heading: 'Gain high-quality links and mentions to your website',
    paragraph: [
      'Content syndication offers a valuable tool for garnering a greater readership, and ensuring that you see a greater ROI for the high-quality content created for your site. By seeking out sites that will republish your content for relevant audiences, you are able to use your creative and original content to much greater effect, make an impact beyond the perimeter and readership of your own site.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>At Ocere, we have a substantial database of over 20,000 webmasters, publishers and bloggers, which allows us to ensure that content syndication is completed to a standard befitting of the content itself. As with all aspects of digital marketing, we target the most productive audiences through high authority external sites.</p>' +
      '<h2 class="c-h2">What is Content Syndication?</h2>' +
      '<p>Syndicating your content means having other sites republishing your content, while providing full credit to you. It benefits both parties, as the syndication site is able to offer their readership high quality content, and you are able to broaden your readership and increase your online presence within relevant markets.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver ' +
      'them. Many of our clients have had frustrating experiences with other agencies and are delighted ' +
      'to discover an entirely different experience when working with Ocere. If you are a digital ' +
      'marketing agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve ' +
      'your service to clients, have you considered white label SEO outreach service? If you are working ' +
      'as a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'What is Considered Digital Content?',
      description:
        'Arguably, written content is the most common form of digital content – articles, essays, blog posts, and guides are all regularly utilised by sites to engage relevant markets. Videos, podcasts, images, infographics – and any other content that is created for and distributed among online platforms is considered digital content.',
    },
    {
      title: 'Is Syndicated Content the Same as Duplicated Content?',
      description:
        'Only insofar as both instances will appear similar or identical to Google. Intention is key, and, to some extent, Google understands how to recognise the intention behind replication. Duplicated content implies content that has been directly copied or altered in a way that it is deemed “appreciably similar” across multiple sites, with the sole purpose of raising search engine ranking without providing an attribution link. It is simply reusing a piece of content multiple times to effectively manipulate Google’s algorithm. Syndicated content features an attribution link, which shows that the subsequent publishers found value in the original and syndicated it for the benefit of their own readers, while still providing credit to the original.',
    },
    {
      title: 'What is Content Syndication in SEO?',
      description:
        'When content is syndicated, Google is still unlikely to display both sites – the original, and any syndications – in search results, in order to maintain diversity for internet users. However, if the syndicated content appears in the search results, then it will still contain a clear backlink to your site, which will prove highly beneficial to you. Content syndication enables you to get a greater ROI for a high quality piece of content. If you are able to engage a wider readership on content syndication sites, you stand to drive more traffic to your own site.',
    },
    {
      title: 'How Do You Do Content Syndication?',
      description:
        'After publishing <a target="_blank" href="https://www.ocere.com/services/content-creation">high quality, shareable content</a> to your own site, you need to begin reaching out to webmasters, publishers, and bloggers with relevant audiences in a way that sets you apart from the many other sites requesting syndication. At Ocere, we have formed relationships with over 20,000 webmasters, with whom we can liaise to find our clients the most beneficial platform for their content.',
    },
  ],
} as Index;
