import {Blog} from '@/content/blogs/model';

export default {
  url: 'why-seo-is-so-vital-to-small-businesses',
  title: 'Why SEO is So Vital to Small Businesses',
  category: 'SEO',
  metaTitle: 'Why SEO is So Vital to SMEs | Ocere',
  metaDescription:
    'Wondering whether your small business can take any benefit from SEO services? Click here to read more about local competition for visibility on SERPs.',
  thumbnail:
    'http://images.ctfassets.net/p5gt7dkd4wn3/664l5ypHO8ZQw4SOoke8Ex/09ee3903b0e556efa591c64da441051a/Google-on-mobile',
  description: [
    {
      type: 'paragraph',
      content: [
        'SEO has gained a massive amount of significance in recent years, and continues to dominate any discussion on digital marketing strategies. Since its inception, a weighty competition has sprung up between businesses across all industries to ensure not only that their visibility is not a casualty of others’ efforts, but also that they are consistently aiming towards the highest rankings on related searches. But, despite its ubiquity among larger enterprises, there remains some uncertainty among smaller, local businesses as to its potential for generating a high ROI. When the scope of your online presence is narrowed, and your focus is on local markets rather than national or international search engine users, can working with an SEO outreach agency prove to be a valuable decision, or nothing more than an overreaction to the pressures of digital marketing?',
      ],
    },
    {
      type: 'paragraph',
      title: 'Target Organic Searches',
      content: [
        'No digital marketing campaign would be complete without acknowledging the significance of appearing within organic search. Unlike advertisements and social media campaigns, users can feel that they have been brought to your site spontaneously, and as a direct result of your authority within relevant searches.',
        'Besides, unlike a standalone marketing campaign, a full SEO package offers a sustainable practice that directly impacts your ability to appear on one of the most beneficial online platforms: Google’s search results page.',
        `As of July 2020, Google’s search engine handled more than  <a href='https://www.statista.com/statistics/216573/worldwide-market-share-of-search-engines/' target='_blank'>85% of the world’s desktop search market</a>, which means that it has taken on the role as something of a gateway between the overwhelming majority of users seeking content relevant to your brand – in other words, prospective customers – and you. Investing in a good <a href='https://www.ocere.com/services/seo-packages'>SEO package</a> pushes your site closer to that gateway, and enters you into the running to receive a significant portion of that market through organic searches.`,
      ],
    },
    {
      type: 'image',
      url:
        'https://images.ctfassets.net/p5gt7dkd4wn3/664l5ypHO8ZQw4SOoke8Ex/09ee3903b0e556efa591c64da441051a/Google-on-mobile',
    },
    {
      type: 'paragraph',
      title: 'Boost Local Visibility',
      content: [
        `Every search results page on Google is created via the same algorithm. More than <a href='https://blog.hubspot.com/marketing/local-seo-stats' target='_blank'>95% of internet users</a> will go online to find a local business, and the same issues that keep large enterprises off the first page of results will be mirrored within the world of small or local businesses.`,
        'As a result, any business with an online presence – however niche or focused in its approach – needs to be ready to evolve alongside vacillating market habits, and to refine its approach as Google does the same to its algorithm for results pages. Failing to do so ensures that your site is, in many ways, a wasted investment. ',
        'In short, targeting local searches through strong keyword research and content creation is no less beneficial to small independent businesses than an international campaign is to some of the largest enterprises operating on the web today. While the playing field may be a microcosm, the rules – and the weight of the competition itself – remain the same.',
      ],
    },
    {
      type: 'paragraph',
      title: 'Create a Better Conversion Rate',
      content: [
        'Beyond generating greater visibility on SERPs, SEO ensures that your website facilitates a better conversion rate for new visitors.',
        'From technical aspects such as page loading speed and optimising the page for mobile, to ensuring that content is written in a way that appeals to both Google’s algorithm and human readers alike – thus keeping your bounce rate low – utilising the most effective SEO practices for your site will make a significant impact on your ability to grow your customer base, particularly as they find you through internet searches.',
      ],
    },
    {
      type: 'image',
      url:
        'https://images.ctfassets.net/p5gt7dkd4wn3/5fwu4NjClipMZcDAsrFKsp/8e5922404feac510aeeec4350b68108d/Conversions.jpg',
    },
    {
      type: 'paragraph',
      title: 'Prepare Your Online Presence for the Future',
      content: [
        'Whether or not you are aware of it, the chances are that your competitors are already investing efforts into their own SEO. For you, a direct competitor with no SEO in place, this means that they will consistently surmount your position on results pages. ',
        'What’s more, their gain is your loss – as more competitors reach those coveted spots on relevant searches, your site drops in the rankings. Even if you are making the first page of a localised search now, there is no protection against that position changing for the worse as your competitors’ efforts continue to pay off.',
        'If, for example, a number of competitors are making use of an agency’s blogger outreach service and link building packages, then their network will be steadily growing. By comparison, yours may as well be shrinking, as your site is buried further within the results pages.',
        'For small and medium businesses, working with a link agency will enable you to target one of the most lucrative online markets in the world. The sheer volume of local and relevant searches represents an invaluable asset to your brand, and utilising an agency’s expertise in blogger outreach, content creation, technical site optimisation, keyword research, and link building through tailored SEO packages will ensure tangible results that translate to higher rates of organic and relevant traffic to your site.',
        'SEO package prices vary significantly depending on the scope of your strategy, which is yet another reason why working with an SEO outreach agency remains essential, even for small and medium businesses. The necessity is constantly growing, and no site can afford to neglect their SEO.',
      ],
    },
  ],
} as Blog;
