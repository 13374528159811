






import { Component, Prop, Vue } from 'vue-property-decorator';
import ServiceContainer from '@/components/services/service-container.vue';
import services from '@/content/pages/services/index';

@Component({ components: { ServiceContainer } })
export default class extends Vue {
  @Prop() services!: string[];

  get filtered() {
    if (this.services) {
      return services.filter((service) =>
        this.services.includes(service.title),
      );
    } else {
      return services;
    }
  }
}
