import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import contentful from '@/lib/contentful.js';
import LeadService from '@/services/LeadService';
import ClientService from '@/services/ClientService';
import OrderService from '@/services/OrderService';
import TicketService from '@/services/TicketService';
import {orderBy} from 'lodash';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    permission: {
      account_manager: 'Account Manager',
      operations: 'Operations',
      admin: 'ADMIN',
      client: 'Client',
      provider: 'Provider',
    },
    clients: [],
    companies: [],
    orders: [],
    blogs: [],
    user: {},
    tickets: [],
    paypalInitialized: false,
  },
  mutations: {
    SET_TICKETS(state: any, tickets) {
      state.tickets = tickets;
    },
    SET_BLOGS(state: any, blogs) {
      state.blogs = blogs;
    },
    SET_COMPANIES(state: any, companies) {
      state.companies = companies;
    },
    SET_CLIENTS(state, clients) {
      state.clients = clients;
    },
    SET_ORDERS(state, orders) {
      state.orders = orders;
    },
    SET_USER(state, user) {
      state.user = {...state.user, ...user};
    },
    PAYPAL_INITIALIZED(state) {
      state.paypalInitialized = true;
    },
    LOG_OUT(state) {
      state.user = {};
    },
  },
  actions: {
    async fetchBlog({commit}) {
      try {
        const blogs = (
          await contentful.getEntries({content_type: 'blog'})
        ).items
          .map(item => item.fields)
          .sort((first, second) => first.datePublished < second.datePublished);

        commit('SET_BLOGS', blogs);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async fetchCompany({commit}) {
      try {
        const companies: any = [];
        const resp = await LeadService.doGetLeads();
        resp.leads.forEach(lead =>
          companies.push(LeadService.formatInfo(lead))
        );
        commit('SET_COMPANIES', companies);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async fetchClient({commit}) {
      try {
        const clients: any = [];
        const resp = await ClientService.doGetClients();
        resp.clients.forEach(client =>
          clients.push(ClientService.formatInfo(client))
        );
        commit('SET_CLIENTS', clients);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async fetchOrders({commit}) {
      try {
        const orders: any = [];
        const resp = await OrderService.doGetOrders();
        resp.orders.forEach(order =>
          orders.push(OrderService.formatInfo(order))
        );
        commit('SET_ORDERS', orderBy(orders, 'startDate', 'desc'));
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async fetchClientOrder({}, clientId: string) {
      try {
        const orders: any = [];
        const resp = await ClientService.doGetClientOrders(clientId);
        resp.orders.forEach(client =>
          orders.push(OrderService.formatInfo(client))
        );
        return orders;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async fetchTickets({commit, state}) {
      try {
        const tickets: any = [];
        const resp = await (state.user.role === 'account_manager'
          ? TicketService.doGetAllTickets()
          : TicketService.doGetTickets());
        resp.tickets.forEach(ticket =>
          tickets.push(TicketService.formatInfo(ticket))
        );
        commit('SET_TICKETS', tickets);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    setUserInfo({commit}, user) {
      commit('SET_USER', user);
    },
    doLogout({commit}) {
      commit('LOG_OUT');
    },
    doInitializingPaypal({commit}) {
      commit('PAYPAL_INITIALIZED');
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin],
});
