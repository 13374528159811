<template>
  <div>
    <top-section :service="service" />
    <comparison-table :service="service" />
    <calendar-panel :service="service" />
    <explained
      :service="service"
      v-if="isShowExplained"
      class="text-center content-wrapper"
    />
    <book-a-call />
    <how-it-works :service="service" />
    <key-benefits :service="service" />
    <why-choose :service="service" />
    <frequently-asked-questions :service="service" />
  </div>
</template>

<script>
import HowItWorks from '@/components/services/how-it-works.vue';
import KeyBenefits from '@/components/services/key-benefits.vue';
import FrequentlyAskedQuestions from '@/components/services/frequently-asked-questions.vue';
import CalendarPanel from '@/components/services/calendar-panel.vue';
import BookACall from '@/components/services/book-a-call.vue';
import WhyChoose from '@/components/services/why-choose.vue';
import TopSection from '@/components/services/top-section.vue';
import ComparisonTable from '@/components/services/comparison-table.vue';
import Explained from '@/components/services/explained.vue';

export default {
  props: ['service'],
  components: {
    ComparisonTable,
    TopSection,
    BookACall,
    CalendarPanel,
    FrequentlyAskedQuestions,
    HowItWorks,
    KeyBenefits,
    WhyChoose,
    Explained,
  },
  metaInfo() {
    return {
      title: this.service.meta.title,
      meta: [
        {
          name: 'description',
          content: this.service.meta.description || '',
        },
      ],
    };
  },
  computed: {
    isShowExplained() {
      const showExplained = ['/services/seo-packages'];
      return showExplained.includes(this.service.url);
    },
  },
};
</script>
