var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.service.slider)?_c('div',{staticClass:"content-wrapper slider p-0 w-100"},[_c('div',{staticClass:"content-writing-page p-3 m-0 shadow-none"},[_c('div',{staticClass:"word-count-action pb-3 mx-4 pt-4"},[_c('div',{staticClass:"word-count-label d-flex justify-content-between align-items-end mb-2"},[_c('div',{staticClass:"m-0"},[_vm._v("Choose word count:")]),_c('div',{staticClass:"d-flex align-items-end ml-1"},[_c('h4',{staticClass:"m-0 mr-2 text-bold"},[_vm._v(_vm._s(_vm.wordCount))]),_vm._v("\n          Words\n        ")])]),_c('vue-slider',{attrs:{"data":_vm.wordCountData,"processStyle":{ 'background-color': 'whitesmoke' },"dotStyle":{
          'background-color': '#FF9F1C',
          'border-color': '#FF9F1C',
          height: '18px',
          width: '18px',
          'margin-top': '-2px',
        },"railStyle":{
          'background-color': 'whitesmoke',
          height: '10px',
        },"marks":_vm.wordCountData,"stepStyle":{
          display: 'none',
        }},model:{value:(_vm.wordCount),callback:function ($$v) {_vm.wordCount=$$v},expression:"wordCount"}})],1),_c('div',{staticClass:"article-action pb-3 mt-5 pt-5 mx-4 mb-5"},[_c('div',{staticClass:"word-count-label d-flex justify-content-between align-items-end mb-2"},[_c('div',{staticClass:"m-0"},[_vm._v("Choose number of articles:")]),_c('div',{staticClass:"d-flex align-items-end ml-1"},[_c('h4',{staticClass:"m-0 mr-2 text-bold"},[_vm._v(_vm._s(_vm.articleValue))]),_vm._v("\n          Articles\n        ")])]),_c('vue-slider',{attrs:{"data":_vm.articleData,"processStyle":{ 'background-color': 'whitesmoke' },"dotStyle":{
          'background-color': '#FF9F1C',
          'border-color': '#FF9F1C',
          height: '18px',
          width: '18px',
          'margin-top': '-2px',
        },"railStyle":{
          'background-color': 'whitesmoke',
          height: '10px',
        },"marks":function (val) { return val % 50 === 0 || val === 1; },"stepStyle":{
          display: 'none',
        }},model:{value:(_vm.articleValue),callback:function ($$v) {_vm.articleValue=$$v},expression:"articleValue"}})],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }