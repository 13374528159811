import {LinkBuilding} from '@/content/pages/link-building/index';

export default {
  icon: require('@/assets/icons/services/pricing_icon.png'),
  title: 'International Publishing Network',
  url: '/services/international-link-building',
  meta: {
    title: '',
    // TODO
  },
} as LinkBuilding;
