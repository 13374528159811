import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { empty } from '@/utils';
import { get } from 'lodash';
export const baseURL = process.env.VUE_APP_OCERE_ROOT_API;
export function apiClient(options = {}) {
  let token;
  if (!empty(store.state.user)) {
    token = store.state.user.token;
  }
  return axios.create({
    baseURL,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...options,
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function handleResp(action) {
  try {
    const dataRet = await action;
    return dataRet.data;
  } catch (ex) {
    if (ex.response.status === 401) {
      router.push('/login');
    }
    throw new Error(
      `${get(ex, 'response.data.errors.messages', []).join('<br>')}`,
    );
  }
}
