










import { Component, Prop, Vue } from 'vue-property-decorator';
import { Employee } from '@/content/pages/about';

@Component
export default class extends Vue {
  @Prop() employee!: Employee;
  @Prop() url!: string;

  get fullname(): string {
    return this.employee.firstname + ' ' + this.employee.lastname;
  }
}
