





























































import {Component, Vue} from 'vue-property-decorator';
import axios from 'axios';
import Skype from '@/assets/icons/skype.vue';
import WhatsApp from '@/assets/icons/whatsapp.vue';
import {ocere} from '@/content';

@Component({components: {Skype, WhatsApp}})
export default class extends Vue {
  social = ocere.social;
  name = '';
  email = '';
  phone = '';
  message = '';
  private async submitForm() {
    const data = {
      name: this.name,
      email: this.email,
      phone: this.phone,
      message: this.message,
    };
    const response = await axios.post('https://formspree.io/xeqjdrqo', data);
    this.$router.push({path: '/thank-you'});
  }
}
