import {ManagedCampaigns} from '@/content/pages/managed-campaigns/index';

export default {
  icon: require('@/assets/icons/services/full_service_icon.png'),
  graphic: require('@/assets/graphics/services/full_service_graphic.svg'),
  title: 'Full Service Packages',
  bullet:
    'A powerful approach for those looking to achieve the best possible results across multiple services.',
  url: '/services/digital-marketing-packages',
  meta: {
    title: 'Digital Marketing Packages | Ocere [2021!]',
    description:
      'Browse a range of prebuilt digital marketing packages covering SEO, SEO outreach, PPC, creative content services, and more. Or get in touch today for a bespoke strategy.',
  },
  days: 14,
  description: [
    'Ocere’s digital marketing packages deliver results. Integrated campaigns (eg: SEO, PPC, content ' +
      'creation) are driven by quality content. We combine activities to catalyse them together to capture a ' +
      'larger share of your target market. We keep things simple; our digital marketing company packages are ' +
      'designed to deliver results. Compare digital packages based on our straightforward monthly fees.',
  ],
  points: [
    'Enhanced Results',
    'Services Compliment each other',
    'Multiple Customer Touchpoints',
    'Full Monthly Reporting',
    'X-Ray and Beat Competitors',
  ],
  packages: [
    {
      name: 'Full Service Package 1',
      // url: '/get-in-touch',
      // price: {
      //     USD: 1295,
      //     GBP: 895,
      // },
      items: {
        'On-Page Elements': {
          'Keyword Research': true,
          'Website Review': true,
          'On-Page SEO Recommendations': true,
          'Page Title Recommendations': true,
          'Meta Description Writing': true,
          'H1 Tags': true,
          'Text Content Review': true,
          'Image Alt Text Creation': true,
          'Implementation of SEO Recommendations': true,
          'Onsite Content - Blog Article': false,
          'Onsite Content - Landing Page': false,
          'Website Health Check': true,
        },
        'Digital Marketing Elements': {
          'Google Analytics Review': false,
          'Analytics Reporting': false,
          'Detailed Content Plan': false,
          'On-Site Content (Based on "Content Plan")': false,
          'Multiple Campaigns PPC Management': true,
          'Basic Remarketing': false,
          'Comprehensive Remarketing': false,
        },
        'Off-page Elements': {
          'Link Content Creation': 5,
          'High Quality Link Placements': 5,
          'Tier 2 Linking': 10,
          'Social Signals': 20,
          'PR Link + Creation': 0,
        },
      },
    },
    {
      name: 'Full Service Package 2',
      // url: '/get-in-touch',
      // price: {
      //     USD: 1995,
      //     GBP: 1495,
      // },
      items: {
        'On-Page Elements': {
          'Keyword Research': true,
          'Website Review': true,
          'On-Page SEO Recommendations': true,
          'Page Title Recommendations': true,
          'Meta Description Writing': true,
          'H1 Tags': true,
          'Text Content Review': true,
          'Image Alt Text Creation': true,
          'Implementation of SEO Recommendations': true,
          'Onsite Content - Blog Article': false,
          'Onsite Content - Landing Page': false,
          'Website Health Check': true,
        },
        'Digital Marketing Elements': {
          'Google Analytics Review': true,
          'Analytics Reporting': false,
          'Detailed Content Plan': false,
          'On-Site Content (Based on "Content Plan")': false,
          'Multiple Campaigns PPC Management': true,
          'Basic Remarketing': true,
          'Comprehensive Remarketing': false,
        },
        'Off-page Elements': {
          'Link Content Creation': 10,
          'High Quality Link Placements': 10,
          'Tier 2 Linking': 20,
          'Social Signals': 40,
          'PR Link + Creation': 0,
        },
      },
    },
    {
      name: 'Full Service Package 3',
      // url: '/get-in-touch',
      // price: {
      //     USD: 3495,
      //     GBP: 2595,
      // },
      items: {
        'On-Page Elements': {
          'Keyword Research': true,
          'Website Review': true,
          'On-Page SEO Recommendations': true,
          'Page Title Recommendations': true,
          'Meta Description Writing': true,
          'H1 Tags': true,
          'Text Content Review': true,
          'Image Alt Text Creation': true,
          'Implementation of SEO Recommendations': true,
          'Onsite Content - Blog Article': true,
          'Onsite Content - Landing Page': true,
          'Website Health Check': true,
        },
        'Digital Marketing Elements': {
          'Google Analytics Review': true,
          'Analytics Reporting': true,
          'Detailed Content Plan': true,
          'On-Site Content (Based on "Content Plan")': true,
          'Multiple Campaigns PPC Management': true,
          'Basic Remarketing': true,
          'Comprehensive Remarketing': false,
        },
        'Off-page Elements': {
          'Link Content Creation': 15,
          'High Quality Link Placements': 15,
          'Tier 2 Linking': 40,
          'Social Signals': 80,
          'PR Link + Creation': 2,
        },
      },
    },
    {
      name: 'Full Service Package 4',
      // url: '/get-in-touch',
      // price: {
      //     USD: 5250,
      //     GBP: 3995,
      // },
      items: {
        'On-Page Elements': {
          'Keyword Research': true,
          'Website Review': true,
          'On-Page SEO Recommendations': true,
          'Page Title Recommendations': true,
          'Meta Description Writing': true,
          'H1 Tags': true,
          'Text Content Review': true,
          'Image Alt Text Creation': true,
          'Implementation of SEO Recommendations': true,
          'Onsite Content - Blog Article': true,
          'Onsite Content - Landing Page': true,
          'Website Health Check': true,
        },
        'Digital Marketing Elements': {
          'Google Analytics Review': true,
          'Analytics Reporting': true,
          'Detailed Content Plan': true,
          'On-Site Content (Based on "Content Plan")': true,
          'Multiple Campaigns PPC Management': true,
          'Basic Remarketing': true,
          'Comprehensive Remarketing': true,
        },
        'Off-page Elements': {
          'Link Content Creation': 25,
          'High Quality Link Placements': 25,
          'Tier 2 Linking': 80,
          'Social Signals': 160,
          'PR Link + Creation': 4,
        },
      },
    },
  ],
  how_it_works: [
    {
      title: 'Initial Strategy',
      description:
        `You'll specify website, keyword ideas and goals (don't worry if you're unsure - we can ` +
        `advise!). We'll then conduct full keyword research, competitor research, discuss and then ` +
        `implement. The strategy and first month's execution are completed within the first 30 days. ` +
        'Our digital marketing agency packages work for you. You can have as much or as little involvement ' +
        'as you like.',
    },
    {
      title: 'Monthly Execution',
      description:
        `We'll execute this plan from Month 1 onwards. Typically this involves creating and ` +
        'implementing On-Page elements (page titles, meta tags etc), writing content and building external ' +
        'links towards your site. It can also include remarketing, content and paid ad design/refinement. ' +
        'Full reporting is given on every activity every month (via email and online portal).',
    },
    {
      title: 'Ongoing Refinement',
      description:
        `The web is fast moving. Competition doesn't stay still. We analyse current results, ` +
        `competitor results and refine the approach accordingly to get the best results. We'll be proactive ` +
        'on this, unlike 95% of other agencies who sit on their laurels. Again, you can have as much or as ' +
        'little involvement as you like.',
    },
  ],
  key_benefits: [
    {
      title: 'Increase Google Rankings',
      description:
        'Outrank your competition and move up the search engine ladder, sustainably.',
    },
    {
      title: 'Grow Organic Traffic',
      description:
        'Organic Traffic will always be hugely valuable and brings in users with the right intent.',
    },
    {
      title: 'Build Brand Awareness',
      description:
        'Take control of your online perception and exceed your competitors.',
    },
    {
      title: 'Beat Competitors',
      description:
        'We can X-ray their strategies and help your leapfrog them in the SERPs.',
    },
    {
      title: 'Bring in More Customers',
      description:
        'Organic will always be a valuable channel. Attract the right type of customers.',
    },
  ],
  faq: [
    {
      title: 'What is Digital Marketing?',
      description:
        'Digital marketing is a catch all term when used to describe online marketing using ' +
        'elements of social media (Facebook, Twitter etc) content marketing and search engine optimisation. ' +
        'Another term often used is online marketing – both terms are interchangeable. Both can be used to ' +
        'determine the difference between online methods of marketing (by an online marketing agency) ' +
        'compared to traditional media like direct mail, magazine and newspapers advertising.',
    },
    {
      title: 'How Would Ocere ‘Market’ My Business Online?',
      description:
        'Ocere’s digital marketing agency team consists of specialists who would liaise closely ' +
        'with you to draw up an online marketing plan. An integrated digital marketing strategy would be ' +
        'overseen by a dedicated account manager once the digital marketing campaign is agreed and commenced.',
    },
    {
      title: 'How Would This Be Achieved?',
      description:
        'The Ocere digital agency marketing team would, by using a combination of a comparison ' +
        'tool such as Google analytics, Ahrefs, Majestic and/or Moz for example, and accumulated knowledge, ' +
        'formulate a strategy which would be firmly aimed at your target market. The online digital ' +
        'marketing strategy would be directed to improve the ranking of your website and increase organic ' +
        'traffic by selective use of our bespoke marketing service and/or digital marketing packages. The ' +
        'backend team would investigate and research direct competitors and use any intelligence gathered to ' +
        'better market your website and online presence.',
    },
    {
      title: 'For How Long Does an SEO Digital Marketing Campaign Run?',
      description:
        'Campaigns are undertaken on a month to month basis and at the end of each monthly ' +
        'campaign, you will be provided monthly reports detaling everything we have undertaken and how the ' +
        'marketing campaign is performing. Ocere is a full service digital agency - we sell digital ' +
        'marketing agency packages to other digital marketing agencies, as well as helping many large ' +
        'companies and multi nationals to improve and maintain their online profiles. Additionally, we ' +
        'have helped hundreds of small businesses to grow into – well – larger businesses. With increased ' +
        'profitability resulting from increased targeted exposure and increased paying customers, our ' +
        'clients have reaped the rewards of partnering with Ocere for their digital marketing services ' +
        'solutions. Why not call or email today – you have nothing to lose and, by the results we have ' +
        'delivered for our clients, an awful lot to gain.',
    },
  ],
} as ManagedCampaigns;
