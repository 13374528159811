<template>
  <div class="persona">
    <green-area-container class="persona-green-wrapper">
      <h1>{{ persona.green.catchphrase }}</h1>
      <h2>{{ persona.green.heading }}</h2>
      <p v-if="typeof persona.green.paragraph === 'string'">
        {{ persona.green.paragraph }}
      </p>
      <p
        v-else
        v-for="paragraph in persona.green.paragraph"
        v-html="paragraph"
      ></p>
      <component :is="persona.green.graphic + '-graphic'" slot="graphic" />
    </green-area-container>
    <div class="content-wrapper">
      <main>
        <get-in-touch class="get-in-touch" />
        <section>
          <explained :service="persona" />
          <h2 class="c-h2">How does it Work?</h2>
          <div
            v-html="
              persona.content['How does it work?'] ||
              persona.content['Now where to go?']
            "
          ></div>
        </section>
      </main>
      <br style="clear: both;" />
      <br />
      <service-container-wrapper
        v-if="persona.content.services"
        :services="persona.content.services"
      />
    </div>
    <title-content-wrapper title="Why choose Ocere?">
      <div v-html="persona.content['Why choose Ocere?']"></div>
    </title-content-wrapper>
    <frequently-asked-questions v-if="persona.faq" :service="persona" />
  </div>
</template>

<script>
import GreenAreaContainer from '@/components/green-area-container.vue';
import EndUserGraphic from '@/assets/graphics/persona/end-user-graphic.vue';
import ReferralPartnerGraphic from '@/assets/graphics/persona/referral-partner-graphic.vue';
import WhiteLabelGraphic from '@/assets/graphics/persona/white-label-graphic.vue';
import ServiceContainerWrapper from '@/components/services/service-container-wrapper.vue';
import SplitView from '@/components/split-view.vue';
import GetInTouch from '@/components/get-in-touch.vue';
import FrequentlyAskedQuestions from '@/components/services/frequently-asked-questions.vue';
import TitleContentWrapper from '@/components/title-content-wrapper.vue';
import LinedBulletPoints from '@/components/services/lined-bullet-points.vue';
import Explained from '@/components/services/explained.vue';
import WhyChoose from '@/components/services/why-choose.vue';

export default {
  components: {
    LinedBulletPoints,
    TitleContentWrapper,
    FrequentlyAskedQuestions,
    GetInTouch,
    SplitView,
    ServiceContainerWrapper,
    GreenAreaContainer,
    EndUserGraphic,
    ReferralPartnerGraphic,
    WhiteLabelGraphic,
    Explained,
    WhyChoose,
  },
  props: ['persona'],
  metaInfo() {
    return {
      title: this.persona.meta.title,
      meta: [
        {
          name: 'description',
          content: this.persona.meta.description || '',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
main section {
  padding-top: 64px;
  padding-bottom: 0;
}

.get-in-touch {
  @include tablet {
    float: left;
    margin-top: 64px;
    margin-right: 64px;
    margin-bottom: 32px;
  }
}
</style>
