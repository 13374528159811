import {QA} from '@/content/pages/services';
import link_building from '@/content/pages/persona/link_building';
import managed_services from '@/content/pages/persona/managed_services';
import end_user from '@/content/pages/persona/end_user';
import referral_partners from '@/content/pages/persona/referral_partners';
import white_label from '@/content/pages/persona/white_label';
import casino_seo from '@/content/pages/persona/casino_seo';
import multilingual_seo from '@/content/pages/persona/multilingual_seo';
// New 20 service page
import seo_malta from '@/content/pages/persona/seo_malta';
import may_2020_changes_to_organic_visibility from '@/content/pages/persona/may_2020_changes_to_organic_visibility';
import link_building_malta from '@/content/pages/persona/link_building_malta';
import international_link_building from '@/content/pages/persona/international_link_building';
import german_link_building from '@/content/pages/persona/german_link_building';
import digital_pr from '@/content/pages/persona/digital_pr';
import casino_link_building from '@/content/pages/persona/casino_link_building';
import spanish_link_building from '@/content/pages/persona/spanish_link_building';
import pr_outreach from '@/content/pages/persona/pr_outreach';
import pr_link_building from '@/content/pages/persona/pr_link_building';
import online_reputation_management from '@/content/pages/persona/online_reputation_management';
import mexico_link_building from '@/content/pages/persona/mexico_link_building';
import infographic_creation from '@/content/pages/persona/infographic_creation';
import igaming_seo from '@/content/pages/persona/igaming_seo';
import french_link_building from '@/content/pages/persona/french_link_building';
import crypto_link_building from '@/content/pages/persona/crypto_link_building';
import blockchain_link_building from '@/content/pages/persona/blockchain_link_building';

// New 6 service pages
import seo_israel from '@/content/pages/persona/seo_israel';
import fintech_seo from '@/content/pages/persona/fintech_seo';
import finland_link_building from '@/content/pages/persona/finland_link_building';
import cyber_security_seo from '@/content/pages/persona/cyber_security_seo';
import cbd_seo from '@/content/pages/persona/cbd_seo';
import brazil_link_building from '@/content/pages/persona/brazil_link_building';

// New 9 service page
import top_level_publications from '@/content/pages/persona/top_level_publications';
import tier_1_link_building from '@/content/pages/persona/tier_1_link_building';
import link_building_australia from '@/content/pages/persona/link_building_australia';
import emea_seo from '@/content/pages/persona/emea_seo';
import editorial_link_building from '@/content/pages/persona/editorial_link_building';
import content_syndication from '@/content/pages/persona/content_syndication';
import content_distribution from '@/content/pages/persona/content_distribution';
import blog_content_writing from '@/content/pages/persona/blog_content_writing';
import ai_seo from '@/content/pages/persona/ai_seo';

export interface Index {
  url: string;
  meta: {
    title: string;
    description?: string;
  };
  green: {
    graphic: string;
    name: string;
    catchphrase: string;
    heading: string;
    paragraph: string | string[];
  };
  content: any;
  faq?: QA[];
}

export default [
  link_building,
  managed_services,
  end_user,
  referral_partners,
  white_label,
  casino_seo,
  multilingual_seo,
  seo_malta,
  may_2020_changes_to_organic_visibility,
  link_building_malta,
  international_link_building,
  german_link_building,
  digital_pr,
  casino_link_building,
  spanish_link_building,
  pr_outreach,
  pr_link_building,
  online_reputation_management,
  mexico_link_building,
  infographic_creation,
  igaming_seo,
  french_link_building,
  crypto_link_building,
  blockchain_link_building,
  seo_israel,
  fintech_seo,
  finland_link_building,
  cyber_security_seo,
  cbd_seo,
  brazil_link_building,
  top_level_publications,
  tier_1_link_building,
  link_building_australia,
  emea_seo,
  editorial_link_building,
  content_syndication,
  content_distribution,
  blog_content_writing,
  ai_seo,
] as Index[];

export {
  link_building,
  managed_services,
  end_user,
  referral_partners,
  white_label,
  casino_seo,
  multilingual_seo,
  seo_malta,
  may_2020_changes_to_organic_visibility,
  link_building_malta,
  international_link_building,
  german_link_building,
  digital_pr,
  casino_link_building,
  spanish_link_building,
  pr_outreach,
  pr_link_building,
  online_reputation_management,
  mexico_link_building,
  infographic_creation,
  igaming_seo,
  french_link_building,
  crypto_link_building,
  blockchain_link_building,
  seo_israel,
  fintech_seo,
  finland_link_building,
  cyber_security_seo,
  cbd_seo,
  brazil_link_building,
  top_level_publications,
  tier_1_link_building,
  link_building_australia,
  emea_seo,
  editorial_link_building,
  content_syndication,
  content_distribution,
  blog_content_writing,
  ai_seo,
};
