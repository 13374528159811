import {ManagedCampaigns} from '@/content/pages/managed-campaigns/index';

export default {
  icon: require('@/assets/icons/services/pricing_icon.png'),
  title: 'Digital PR',
  url: '/services/digital-pr',
  meta: {
    title: '',
    // TODO
  },
} as ManagedCampaigns;
