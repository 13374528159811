import {Blog} from '@/content/blogs/model';

export default {
  url: 'may-2020-changes-to-organic-visibility',
  title: 'May 2020 Changes to Organic Visibility',
  category: 'Link Building',
  // metaTitle: '',
  // metaDescription:'',
  thumbnail:
    'https://images.ctfassets.net/p5gt7dkd4wn3/1T92WSCHnIJBJbzzi3M42a/5a6c1b8777bfde24bafd9ca50399b2ce/Link_Building8.jpg',
  description: [
    {
      type: 'paragraph',
      title: 'May 2020 Changes to Organic Visibility',
      content: [
        'During early May 2020, Google rolled out its first broad core algorithm update of the year. Both the impact on SERPs and the reception across Twitter and SEO discussion forums was significant, with many brands and sites experiencing unprecedented highs and lows in their rankings. ',
        'The effects were certainly felt across the board — although some industries suffered from volatility in their SERP rankings more than others — so much so that Moz recorded the highest core update temperature since the summer of 2018.',
      ],
    },
    {
      type: 'image',
      url:
        'https://images.ctfassets.net/p5gt7dkd4wn3/30C4cHASoEfCHRsujWCzet/b6ae9ce64db8f390c1fd1ad28718fbbb/Graph.png',
    },
    {
      type: 'paragraph',
      content: [
        'SEOs and SERP trackers all reported tempestuous conditions — and cryptic results — in Google’s search rankings. Within the first few days of this core update, Babycenter.com, gamesgames.com, and grubhub.com were among some of the greatest casualties, according to Moz’s analysis, while parents.com, everydayhealth.com, and play.google.com were among the best-faring sites by the end of day one. There were a few red herrings. For instance, LinkedIn disappeared from search rankings entirely on the second day of the update, but this turned out to be the result of it being de-indexed by mistake. However, significant disruptions were felt across the board, with some considerable inconsistencies between days one and two as Google revised the update, and continued to play around with rankings.',
        'There was wide criticism over Google’s new preference for ranking social media over sites containing quality content; Pinterest, in particular, began to dominate the SERPs for a number of keywords — even after the completion of the update.',
      ],
    },
    {
      type: 'image',
      url:
        'https://images.ctfassets.net/p5gt7dkd4wn3/4leeSR61Pr8x7VtMN26PLA/a59eac002d0f9ef07c995c73bbf85814/SEO5.jpg',
    },
    {
      type: 'paragraph',
      title: 'Should We Worry About Our SEO Strategy?',
      content: [
        'It only takes a brief look at the countless Tweets, reports and analyses that bubbled up during the first few weeks of May to see that, even by Google’s usual standards for broad core changes, this update was significant. No one particular industry was favoured — or penalised — by these changes to the algorithm; rather, part of the reason why this update caused so much stir across the SEO forums and Twitterverse is down to the far-reaching and mercurial impact it had on search engine rankings. ',
        'Gamesgames.com, for instance, had suffered from a 50% loss by the end of day one, whilst play.google.com fared better than most other sites. Even now, there remains a great deal of confusion over the primary intentions of this update. Already, however, we are beginning to see more consistency in the metrics as the inflammatory weather surrounding this broad core algorithm update settles. And while much of Google’s algorithm remains a well-kept secret, we can be sure that it will continue to value high-quality, relevant and informative content on its SERPs. ',
        'Obtaining quality backlinks from high authority sites, strong keyword research, original content and accurate reporting remain integral to your SEO strategy, and to maintaining a high ranking with Google’s algorithm. Dramatic shifts brought about by changes to the algorithm are enough to cause a stir until the dust has settled, but it is unlikely to remain sustainable without a sound approach to SEO. ',
      ],
    },
  ],
} as Blog;
