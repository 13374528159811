























































































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Package, Service} from '@/content/pages/services';
import EventBus from '@/content/EventBus.ts';
import {roundNearest} from '@/content/common';
import SelectCurrency from './select-currency.vue';
import moment from 'moment';

@Component({
  components: {
    SelectCurrency,
  },
})
export default class extends Vue {
  @Prop() package!: Package;
  @Prop() service!: Service;

  isUsingSliderData = false;
  sliderData: any = {
    wordCount: 250,
    articleValue: 1,
  };
  roundNearest = roundNearest;
  currency = 'GBP';
  durration = 3;

  get priceCC() {
    return `${this.currency === 'USD' ? '$' : '£'}${
      this.package.price
        ? roundNearest(
            this.package.price[this.currency] *
              this.sliderData.wordCount *
              this.sliderData.articleValue,
            2
          )
        : 0
    }`;
  }

  getEstimate(duration: number) {
    return moment().add(duration, 'days').format('Do MMMM');
  }

  getCurrencyFormatValue(value: number) {
    return `${this.currency === 'USD' ? '$' : '£'}${roundNearest(value, 2)}`;
  }

  onClickPackage(pack: any) {
    switch (pack.id) {
      case 'basic':
        return this.$router.push({
          name: 'Content Writing (Standard)',
          query: this.sliderData,
        });
      case 'pro':
        return this.$router.push({
          name: 'Content Writing (Pro)',
          query: this.sliderData,
        });
      default:
        return this.$router.push({name: 'Blogger Outreach'});
    }
  }

  @Watch('service')
  serviceChanged() {
    if (this.service.slider) {
      this.isUsingSliderData = true;
    } else {
      this.isUsingSliderData = false;
    }
  }

  created() {
    EventBus.$on('sliderData', (data: any) => {
      this.isUsingSliderData = true;
      this.sliderData = data;
    });
    EventBus.$on('changeCurrency', (data: any) => {
      this.currency = data.toUpperCase();
    });
    const tmp = localStorage.getItem('currency');
    this.currency = tmp ? tmp : 'GBP';
  }

  // destroyed() {
  //   EventBus.$off('sliderData');
  //   EventBus.$off('changeCurrency');
  // }
}
