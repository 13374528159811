import {ManagedCampaigns} from '@/content/pages/managed-campaigns/index';

export default {
  icon: require('@/assets/icons/services/seo_icon.png'),
  graphic: require('@/assets/graphics/services/seo_graphic.svg'),
  title: 'SEO Packages',
  bullet:
    'Pre-built packages that deliver organic traffic and build rankings. Modern, white-hat techniques.',
  url: '/services/seo-packages',
  meta: {
    title: 'SEO Packages | Ocere',
    description:
      'Prebuilt SEO packages for those looking to increase their organic presence and build trust and credibility for their brand. Click here for more info.',
  },
  shop: '', // https://my.ocere.com/product-category/seo-services
  days: 14,
  description: [
    '<b>Invest in a Strong, Proactive SEO Package to Improve Your Digital Reputation.</b>',
    'Ocere’s fixed price SEO packages contain everything you need to beat the competition, and retain strong visibility within search engine results. ' +
      'Our monthly SEO services help you move up the search engines while keeping your SEO costs low.',
    'Start a monthly retainer SEO project with Ocere and move ahead of your competitors, improve Google keyword rankings, ' +
      'and ensure sustainable growth for your site metrics. We have worked with thousands of business owners and ' +
      'marketing teams across a wide range of key industries, delivering original and proactive strategies that target their most valuable markets.',
  ],
  points: [
    'Between 4 - 20 Keywords',
    'Increase Google Rankings',
    'Grow Organic Traffic',
    'Outrank Competitors',
    'Full Monthly Reporting',
  ],
  explained: {
    title: 'SEO Packages Explained',
    content: [
      'As a practice, SEO – or search engine optimisation –is responsible for increasing the quantity of visitors to a website, ' +
        'the quality of visitors and the ranking of a website or web page via organic search. ' +
        'SEO packages comprise a number of other services such as <a target="_blank" href="https://www.ocere.com/link-building">SEO outreach</a>, on-page <a target="_blank" href="https://www.ocere.com/services/content-creation">creative content</a>, ' +
        'blogging and <a target="_blank" href="https://www.ocere.com/services/digital-pr">digital PR</a> in order to optimise your web presence.',
      'Together, these elements comprise an essential investment for any site hoping to achieve sustainable and ' +
        'consistent growth on Google’s search engine results pages (SERPs). Without them, sites will not only face an immense struggle in finding visibility, ' +
        'but they also risk slipping further into obscurity as more companies enlist the help of an experienced SEO agency.',
      'SEO packages ensure that each element is handled by an experienced team capable of realising ' +
        'the full potential of any site, and ensuring that they rise above competitors by deploying all the tools of digital marketing. ' +
        'They representan excellent, affordable resource for sites operating in any industry, and a vital investment for any company seeking a strong online presence.',
    ],
  },
  why_choose: [
    'SEO – or, more specifically, effective SEO – is a highly nuanced practice. Ocere has been working with thousands of ' +
      'clients for more than a decade, providing tangible and significant results to brands operating within key verticals such as ' +
      'FinTech, <a target="_blank" href="https://www.ocere.com/casino-seo">iGaming</a>, online payments, sport, business, and many more.',
    'Our experience has given us a keen understanding of the requirements for creating effective, modern and original SEO strategies, ' +
      'and our high standards ensure that everything – from blogger outreach to keyword research, creative content, and ' +
      'technical on-site optimisation – is guided towards a single goal.',
    'We pride ourselves on our ability to design best SEO package for every one of our clients, whatever their target, budget, ' +
      'and audience, and on the tangible results our efforts yield.',
  ],
  packages: [
    {
      name: 'SEO Package 1',
      // url: 'https://my.ocere.com/product/seo-package-1/',
      // price: {
      //     USD: 695,
      //     GBP: 495,
      // },
      items: {
        'On-page items': {
          'Keyword research': true,
          'Website review': true,
          'On-Page SEO Recommendations': true,
          'Page Title Recommendations': true,
          'Meta Description Writing': true,
          'H1 Tags': true,
          'Text Content Review': true,
          'Image Alt Text Creation': false,
          'Implementation of SEO Recommendations': true,
          'Onsite Content - Blog Article': false,
          'Onsite Content - Landing Page': false,
          'Website Health Check': true,
          'Google Analytics Review': false,
        },
        'Off-page items': {
          'Link Profile Review': false,
          'Link Content Creation': 5,
          'High Quality Link Placements': 5,
          'Tier 2 Linking': 10,
          'Social Signals': 20,
          'PR Link + Creation': 0,
        },
      },
    },
    {
      name: 'SEO Package 2',
      // url: 'https://my.ocere.com/product/seo-package-2/',
      // price: {
      //     USD: 1295,
      //     GBP: 895,
      // },
      items: {
        'On-page items': {
          'Keyword research': true,
          'Website review': true,
          'On-Page SEO Recommendations': true,
          'Page Title Recommendations': true,
          'Meta Description Writing': true,
          'H1 Tags': true,
          'Text Content Review': true,
          'Image Alt Text Creation': true,
          'Implementation of SEO Recommendations': true,
          'Onsite Content - Blog Article': false,
          'Onsite Content - Landing Page': false,
          'Website Health Check': true,
          'Google Analytics Review': true,
        },
        'Off-page items': {
          'Link Profile Review': true,
          'Link Content Creation': 10,
          'High Quality Link Placements': 10,
          'Tier 2 Linking': 20,
          'Social Signals': 40,
          'PR Link + Creation': 0,
        },
      },
    },
    {
      name: 'SEO Package 3',
      // url: 'https://my.ocere.com/product/seo-package-3/',
      // price: {
      //     USD: 1995,
      //     GBP: 1495,
      // },
      items: {
        'On-page items': {
          'Keyword research': true,
          'Website review': true,
          'On-Page SEO Recommendations': true,
          'Page Title Recommendations': true,
          'Meta Description Writing': true,
          'H1 Tags': true,
          'Text Content Review': true,
          'Image Alt Text Creation': true,
          'Implementation of SEO Recommendations': true,
          'Onsite Content - Blog Article': true,
          'Onsite Content - Landing Page': false,
          'Website Health Check': true,
          'Google Analytics Review': true,
        },
        'Off-page items': {
          'Link Profile Review': true,
          'Link Content Creation': 15,
          'High Quality Link Placements': 15,
          'Tier 2 Linking': 40,
          'Social Signals': 80,
          'PR Link + Creation': 2,
        },
      },
    },
    {
      name: 'SEO Package 4',
      // url: 'https://my.ocere.com/product/seo-package-4/',
      // price: {
      //     USD: 3250,
      //     GBP: 2450,
      // },
      items: {
        'On-page items': {
          'Keyword research': true,
          'Website review': true,
          'On-Page SEO Recommendations': true,
          'Page Title Recommendations': true,
          'Meta Description Writing': true,
          'H1 Tags': true,
          'Text Content Review': true,
          'Image Alt Text Creation': true,
          'Implementation of SEO Recommendations': true,
          'Onsite Content - Blog Article': true,
          'Onsite Content - Landing Page': true,
          'Website Health Check': true,
          'Google Analytics Review': true,
        },
        'Off-page items': {
          'Link Profile Review': true,
          'Link Content Creation': 25,
          'High Quality Link Placements': 25,
          'Tier 2 Linking': 80,
          'Social Signals': 160,
          'PR Link + Creation': 4,
        },
      },
    },
  ],
  how_it_works: [
    {
      title: 'Initial Strategy',
      description:
        `You'll specify your website, keyword ideas and goals (don't worry if you're unsure - ` +
        `we can advise!). We'll then conduct full keyword research, competitor research, discuss and then ` +
        `get underway. The strategy and first month's execution are all done inside the first 30days. ` +
        'You can have as much or as little involvement as you like.',
    },
    {
      title: 'Monthly Execution',
      description:
        `We'll execute this plan from Month 1 onwards. Typically this involves creating and ` +
        'implementing On-Page elements (page titles, meta tags etc), writing content and building external ' +
        'links towards your site. Full reporting is given on every activity every month (via email and ' +
        'online portal).',
    },
    {
      title: 'Ongoing Refinement',
      description:
        `The web is fast moving. Competition doesn't stay still. We analyse current results, ` +
        'competitor results and refine the approach accordingly to get the best results. ' +
        `We'll be proactive on this, unlike 95% of other agencies who sit on their laurels. ` +
        'Again, you can have as much or as little involvement as you like.',
    },
  ],
  key_benefits: [
    {
      title: 'Increase Google Rankings',
      description:
        'Outrank your competition and move up the search engine ladder, sustainably.',
    },
    {
      title: 'Grow Organic Traffic',
      description:
        'Organic Traffic will always be hugely valuable and brings in users with the right intent.',
    },
    {
      title: 'Build Brand Awareness',
      description:
        'Take control of your online perception and exceed your competitors.',
    },
    {
      title: 'Beat Competitors',
      description:
        'We can X-ray their strategies and help your leapfrog them in the SERPs.',
    },
    {
      title: 'Bring in More Customers',
      description:
        'Organic will always be such a valuable channel. Attract the right type of customers.',
    },
  ],
  faq: [
    {
      title: 'How do you rank first on Google?',
      description:
        'Sometime with great difficulty! But, with due diligence, lots of keyword research, competitor analysis, ' +
        'SEO outreach, social media marketing and great creative content, there is no reason, ' +
        'if you work hard enough and smart enough, you can get to page 1 position 1. Alternatively, leave all that to Ocere’s team of SEO magicians.',
    },
    {
      title: 'Does SEO Really Work?',
      description:
        'Yes! It is more cost effective than, for instance, PPC (Google AdWords). ' +
        'To make an analogy, SEO digital marketing services is much like building a house. ' +
        'You lay down the foundations, build the walls, fit the roof, insert doors and windows, ' +
        'fit the infrastructure and, once all that is done, you can move in. All it then needs is the odd ‘lick of paint’ to keep it maintained. ' +
        'Monthly SEO packages from Ocere lay down optimisation foundations and build on those so you can reap the long term rewards.',
    },
    {
      title: 'Is SEO relevant in 2021?',
      description:
        'Yes! Smart SEO digital marketing is more relevant than it has ever been before. As the number of websites, ' +
        'blog sites and other online marketing channels increase and users increase, ' +
        'SEO digital marketing is essential in making your online presence stand out above the crowd. ' +
        'Talk to the team at Ocere for more information about SEO packages and SEO outreach packages.',
    },
    {
      title: 'Is SEO just keywords?',
      description:
        'No. Keywords play a central role in SEO, but the sum of its parts is much greater. ' +
        'SEO outreach, on-page optimisation, reaching out to webmasters for publishing content, social media channel marketing, ' +
        'the list goes on. Ocere’s SEO packagesare designed around different elements for differing requirements of our clients.',
    },
    {
      title: 'Do I Need SEO for my website?',
      description:
        'Yes! If your website isn’t optimised for Google and other search engines, it will not rank. If it doesn’t rank, ' +
        'you will be missing out on potential traffic which will be going to your competitors instead. ' +
        'Without some form of SEO digital marketing, your website stands little chance of being ‘seen’ in Google search. ' +
        'As an online marketing agency of many years standing, our comprehensive organic SEO packages have been designed and ' +
        'areconstantly evolving to get your website noticed.',
    },
    {
      title: 'Is SEO Worth it for Small Business?',
      description:
        'Yes! Your website is an online shop window to the world. If you do not engage in SEO digital marketing, you may as well pull the blinds down, shut up shop and gohome. Without some form of SEO and/or SEO <a target="_blank" href="https://www.ocere.com/link-building">SEO outreach services</a> your website will be virtually invisible, unlike the businesses that have invested into their digital marketing services.',
    },
    {
      title: 'What is the Best SEO Strategy?',
      description:
        'Go beyond Google search. Ocere’s digital marketing services team is researchingconstantly. ' +
        '<a target="_blank" href="https://moz.com/blog/structured-data-for-seo-1">Structured data mark-up</a> is becoming more important as the changesto the Google AI algorithm kick in. ' +
        'Additionally, grow your EAT - expertise, authority, and trustworthiness – without those, your online presence is in danger of falling back.',
    },
    {
      title: 'How Much Does SEO cost?',
      description:
        'Typically, digital marketing services vary in cost depending on certain factors. ' +
        'Ocere has fixed price SEO packages as well as offering a bespoke price SEO services. ' +
        'In essence, the cost of engaging an online marketing agency and gaining access to a full range of ' +
        'SEO digital marketing services will depend on four main factors – your location, your business objectives, ' +
        'how quickly you want to increase your online ranking, and how quickly you want to achieve a page one ranking in Google search. ' +
        'Typically, SEO services start at around $200 a month, but this can vary significantly.',
    },
    {
      title: 'Why is SEO so expensive?',
      description:
        'The most common reasons SEO services tend to be expensive are working with competitive keywords, ' +
        'trying to attain a national search ranking rather than local, carrying out extensive research, competitor analysis, ' +
        'creating quality content etc. Also, you will be paying for the accumulated knowledge and experience of the team of marketers ' +
        'whom you will engage- Ocere’s SEO services packages prices reflect all these elements and more.',
    },
    {
      title: 'How can I do SEO for free?',
      description:
        'You can easily do your own SEO digital marketing. You will need to conduct a wealth of research, ' +
        'just like marketers at any online marketing agency will undertake. ' +
        'In no particular order you will need to write great content which is optimised for search – ' +
        'then you will need to outreach. Through these contacts, you will need tobuild links. ' +
        'At the same time, you will need to work to improve the experience of visitors to your website; ' +
        'generate plenty of <a target="_blank" href="https://www.ocere.com/services/content-creation">authoritative and original content</a>, optimise onsite images, utilise headers, ' +
        'improve page load speed, and post consistently to your own blog in order to curate a valuable resource for new and existing users.',
    },
    {
      title: 'How Can I learn SEO fast?',
      description:
        'You will need time on your side in order to do a lot of research. ' +
        'Read books and online training materials related to SEO digital marketing. ' +
        'Get an SEO mentor, perhaps from your local online marketing agency. ' +
        'Mingle with people of a similar mindset. Practice – lots of practice.',
    },
    {
      title: 'Is SEO a waste of money?',
      description:
        'No! SEO digital marketing should be seen as an investment into the medium- to long-term health of your business online presence. ' +
        'Ocere, as a long established online marketing agency will guide and advise you through the entire process to ensure you attain maximum ROI.',
    },
    {
      title: 'Which SEO tool is best?',
      description:
        'There is not a single tool which ‘is the best’ so to speak. AhRefs, Moz, Keyword Finder, SEMRush, SEOQuake, Fat Rank and ' +
        'SiteLiner all have attributes which can help you work smart with your SEO. If I had to pick one SEO tool, I would opt for ' +
        'Ocere’s complete digital marketing services which will deploy most of these online tools.',
    },
    {
      title: 'Which is best free SEO tool?',
      description:
        'The best free tools are Google Keyword Planner, Google Analytics and Google Search Console. ' +
        'The team at Ocere will typically use these tools and other paid for SEO tools to deliver our high quality SEO digital marketing service.',
    },
    {
      title: 'How do I start SEO?',
      description:
        'Start with the website. Make sure the foundation of your online presence is in good shape – keyword deployment, optimised and tagged images, ' +
        'headers, great content, relevant content and authoritative content. But you will only be able to do this if you have researched fully. ' +
        'Ocere’s SEO digital marketing packages include all these elements and more.',
    },
    {
      title: 'What is the Best SEO Checker?',
      description:
        'My personal choice would be AhRefs – other marketers may differ and debate.',
    },
    {
      title: 'What are Good SEO Keywords?',
      description:
        'This is a difficult one! However, the simplest of explanations would be as follows. Any keyword or search phrase which is speaking the same ' +
        'language as the potential visitors which may come to your site. You should research keywords that help connect searchers to your site – ' +
        'if you know your competitors; some analysis of their sites would help isolate the ‘good keywords’ to use.',
    },
    {
      title: 'How Do I Find SEO keywords?',
      description:
        'You can use any of the myriad tools available for finding keywords to use in an SEO digital marketing campaign. ' +
        'Word Stream and Keyword Finder are just a couple of tools you can use, as well as Google Analytics. ' +
        'However, you will need to devote a lot of time to research keywords, as well as lots of competitor analysis. Alternatively, ' +
        'Ocere’s SEO services (monthly SEO packages) will take care of that for you. Find out more today.',
    },
    {
      title: 'What is the Fastest Way to Rank a Keyword?',
      description:
        'You can use the less popular version of a keyword, which could prove invaluable for more targeted searches. You can use keyword modifiers, ' +
        'mix in branded and neutral keywords and mix up your on-page optimisation. Alternatively, Ocere’s SEO packages include all this and more.',
    },
    {
      title: 'How Do I Increase Visibility on Google Maps?',
      description:
        'You should claim your listing, complete your Google My Business profile, use a local phone number and optimise your subscription. ' +
        'However, the easy way is to engage Ocere – our digital marketing services SEO packages include this.',
    },
    {
      title: 'What is Google Search Console?',
      description:
        'Google Search Console (formerly Google Webmaster Tools) is an invaluable platform for digital marketers. ' +
        'At a glance, it allows you to view relevant statistics about your website, stats such as keywords in use, ' +
        'how many external links, dead links, live links, Follow and NoFollow links, crawl errors, etc.',
    },
    {
      title: 'How Do I Optimise My Website?',
      description:
        'On-site optimisation is essential to ensuring good growth on SERPs. ' +
        'SEO digital marketing analysts will tell you to make sure you use relevant keywords on every page, but do not stuff the pages with keywords. ' +
        'Make sure you have at least one of each size header (H1 to H6) on every page. Optimise images. ' +
        'Publish quality content that will genuinely appeal to human readers seeking relevant information. Insert internal links which are relevant.',
    },
    {
      title: 'How Do I Optimize My Business for Google?',
      description:
        'Complete the information form on Google My Business, post great content on theposts section, use high resolution images, use a local number, etc. ' +
        'However, buyan SEO package from Ocere and we will do it for you.',
    },
    {
      title: 'Do Google Posts Help SEO?',
      description:
        'Yes! Your overall strategy for SEO will improve – Google My Business is a great way to enhance your SEO campaign. Ocere’s SEO services packages include all this.',
    },
    {
      title: 'Is Google My Business Free?',
      description: 'Yes, and it is easy to use and very intuitive.',
    },
    {
      title: 'Is Google My Business Going Away?',
      description:
        'No! Google Plus is going away but Google My Business is staying and is improving constantly – it is a powerful tool in any digital marketing services campaign.',
    },
    {
      title: 'How Long Do Google Business Posts Last?',
      description:
        'Google posts expire one week (seven days) after posting, so you will need to constantly update to stay on trend.',
    },
    {
      title: 'What Is a Social Media Package?',
      description:
        'A social media package is a bundle SEO package which is an ideal way for small business owners or start-ups which have limited budgets. Using social media such as ' +
        'Facebook and Twitter for example is an excellent but very cost effective method of promoting a brand to a targeted audience.',
    },
    {
      title: 'How do I choose an SEO agency?',
      description:
        'Always ask to see case studies and examples of successful SEO digital marketingcampaigns they have run, and look through as many diverse examples as possible. ' +
        'Understand the risk of low cost digital marketing services – if the price seems too good to be true, then it probably is.',
    },
  ],
} as ManagedCampaigns;
