

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Service } from '@/content/pages/services';
import EventBus from '@/content/EventBus.ts';

@Component({})
export default class extends Vue {
  @Prop() service!: Service;
  private current = 'GBP';

  protected created() {
    const tmp = localStorage.getItem('currency');
    this.current = tmp ? tmp : 'GBP';
  }

  private selectCurrency(value: string) {
    this.current = value;
    localStorage.setItem('currency', value);
    EventBus.$emit('changeCurrency', value);
  }
}
