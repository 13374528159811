import {Index} from '@/content/pages/persona';

export default {
  url: '/services/infographic-creation',
  meta: {
    title: 'Infographic Creation Services | Ocere [2021!]',
    description:
      'Infographic creation service to enahnce your SEO strategy with valuable and shareable visual content. Click here for more.',
  },
  green: {
    graphic: 'white-label',
    name: 'Infographic Creation',
    catchphrase: 'Infographic Creation',
    heading: 'Gain high-quality links and mentions to your website',
    paragraph: [
      'Online, where access to near-instant results is already impacting our attention spans, infographics offer an excellent tool for conveying salient points to your target audience, and generating greater levels of organic traffic to your site. It is estimated that approximately <a style="text-decoration: none;color: #fff;font-weight: 800;" href="https://www.forbes.com/sites/tjmccue/2013/01/08/what-is-an-infographic-and-ways-to-make-it-go-viral/#774a065a7272">65% of people are visual learners</a>, which means that the best digital marketing strategies will be those that incorporate visual content into your online profile..',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>At Ocere, we can create quality infographics that will capture attention, generate interest within your target audience, and represent a valuable and shareable resource within your market in order to augment your SEO strategy.</p>' +
      '<p>We have seen great success in a wide range of industries based all around the world, including iGaming, financial services, payment processors, blockchain, and tech. We also have an in-house writing team with multilingual capabilities, and can produce content in French, Spanish, German, Portuguese, Finnish, Russian, and more.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver ' +
      'them. Many of our clients have had frustrating experiences with other agencies and are delighted ' +
      'to discover an entirely different experience when working with Ocere. If you are a digital ' +
      'marketing agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve ' +
      'your service to clients, have you considered white label SEO outreach service? If you are working ' +
      'as a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'Are Infographics Good for SEO?',
      description:
        'Your target market and Google’s algorithm have one thing in common: they are both seeking informative, high quality and relevant content, and will quickly disregard anything that does not provide them with the information they are looking for. Infographics offer an excellent medium for imparting large chunks of information in a concise and easy-to-read way. If made to a high standard and targeted towards a relevant audience, it will offer a piece of highly shareable content and, in turn, <a target="_blank" href="https://www.ocere.com/services/blogger-outreach">create a wider network of backlinks</a> and generate greater levels of traffic to your site.',
    },
    {
      title: 'How Do You Make Content Shareable?',
      description:
        'Begin with a comprehensive understanding of your market, readership, and trends, then create an infographic that specifically targets them through salient points, original information, and eye catching graphics. Alternatively, make use of Ocere’s infographic creation service.',
    },
    {
      title: 'How do I Optimize my Website Content?',
      description:
        'With a thorough comprehension of online trends, strong keyword research, <a target="_blank" href="https://www.ocere.com/services/content-creation">original and knowledgeable creative content</a>, engaging writing, and an up-to-date understanding of the target audience. You need to be able to ensure that your content is geared up to appeal to a wide readership, and the key factors in search engine algorithms, in order to ensure sustainable growth within your online presence. At Ocere, this is what we have been doing for 10+ years with over 3,000 clients; transforming their online profiles to reflect quality, value and originality online.',
    },
    {
      title: 'Does Google Read Infographics?',
      description:
        'Infographics are, in a technical sense, just images, which means that Google cannot ‘read’ them in the same way that it reads the written content you use for obtaining backlinks to your site. In spite of this, the shareable nature of well-made infographics make them an excellent accompaniment to your written content. Not only can they condense large subjects down to key points, they also stand a great chance of capturing your readers’ attention faster than plain text. Google will recognise these positive results, and this will reflect well on your site’s authority.',
    },
    {
      title: 'How Do I Get Backlinks with Infographics?',
      description:
        'Any effective SEO outreach strategy begins with extensive blogger outreach; good backlinks will be those that have been obtained from high authority, relevant sites. Next, you will need to offer high quality, informative content that pertains both to your brand, and the target site. Using your infographic, you will create a valuable resource that leads readers — and search engines — directly back to your own site.',
    },
  ],
} as Index;
