import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueMeta from 'vue-meta';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'handsontable/dist/handsontable.full.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'jquery/src/jquery.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import initExternalLib from '@/lib/external';

Vue.config.productionTip = false;
initExternalLib();

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => {
    setTimeout(function () {
      document.dispatchEvent(new Event('x-app-rendered'));
    }, 5000);
  },
}).$mount('#app');
