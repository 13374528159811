






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Employee } from '@/content/pages/about';
import TeamContainer from '@/components/about/team-container.vue';

@Component({ components: { TeamContainer } })
export default class extends Vue {
  @Prop() team!: Employee[];

  get selected(): Employee | undefined {
    return this.team.find(
      (employee) => this.url(employee) === this.$route.hash.substring(1),
    );
  }

  url({ firstname, lastname }: Employee): string {
    return (firstname + '-' + lastname).toLowerCase();
  }
}
