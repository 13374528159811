











import {Component, Vue} from 'vue-property-decorator';
@Component({
  metaInfo: {
    title: 'Thank You | Ocere',
    meta: [
      {
        content: 'Thank you for placing your online order with Ocere today. This is a confirmation page to confirm your order has gone through.',
        hid: 'description',
        name: 'description',
      },
    ],
  },
})
export default class extends Vue {}
