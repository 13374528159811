import blogger_outreach from './blogger-outreach';
import link_packages from './link-packages';
import casino_link_building from './casino-link-building';

export interface Package {
  id?: string;
  more?: string[];
  feature?: string[];
  noFeature?: string[];
  bulkDiscount?: any[];
  name: string;
  url?: string;
  per?: string;
  price?: {
    GBP: number;
    USD: number;
  };
  items: {
    [section: string]: {
      [item: string]: boolean | string | number;
    };
  };
}

export interface QA {
  title: string;
  description: string;
}

export interface LinkBuilding {
  icon: string;
  graphic: string;
  title: string;
  meta: {
    title: string;
    description?: string;
  };
  alias?: string;
  bullet: string;
  description: string[];
  points: string[];
  days: number;
  url: string;
  shop?: string;
  packages?: Package[];
  how_it_works?: QA[];
  faq: QA[];
  key_benefits: QA[];
}

export default [
  blogger_outreach,
  link_packages,
  casino_link_building,
] as LinkBuilding[];

export {blogger_outreach, link_packages, casino_link_building};
