import {ContentCreation} from '@/content/pages/content-creation/index';

export default {
  slider: false,
  icon: require('@/assets/icons/services/content_creation_icon.png'),
  graphic: require('@/assets/graphics/services/adwords_graphic.svg'),
  title: 'Creative Content',
  bullet:
    'High-quality creative content services. Blogs, articles, guides & more. Fast turnaround.',
  url: '/services/content-creation',
  meta: {
    title: 'Creative Content Services | Ocere [2021!]',
    description:
      'High-quality creative content services from a top online digital marketing agency. Optimsed blogs, articles, guides & more. Fast turnaround. Click here.',
  },
  days: 5,
  duration: 3,
  description: [
    'High-quality, valuable content for your brand.',
    'A simple and scalable way to outsource your content. Fast turnaround and easy ordering.',
    `We can write: Blog posts, Web pages, Press releases, Guides, Evergreen content and so much more.`,
    `Simply choose the word count, enter the brief and we’ll get started. Turnaround from as little as 3 days.`,
  ],
  explained: {
    title: 'SEO Creative Content Explained',
    content: [
      'Content represents a central component to search engine optimisation – both in terms of on-site improvements, and consistently augmenting SEO outreach strategies. Long gone are the days when writing content around a list of keywords paid off; now, keywords need to fall naturally into content that has been written for authentic, relevant audiences – your most valuable market.',
      'Strong content enables your SEO strategy to target internet users and Google’s algorithm in equal measure; on both sides of the coin, a quality resource will holdinfinitely more appeal than a page that exists solely as a vehicle for keywords.',
      'More than a tool for SEO, however, written creation also offers one of the best mediums with which to showcase the inherent value of your brand. Growing yourdigital reputation can only be done through consistent efforts to contextualise your site within a broader perspective, and to invest significant amounts of time and effort into communicating with relevant audiences – both on-site, and off.',
      'Of course, investing that time and effort into creative content – and ensuring that it is supported by cutting-edge SEO techniques – is much easier said than done, which is why so many companies turn to Ocere’s creative content services.',
    ],
  },
  points: [
    'Fast turnaround (3+ days) to your specification',
    'Blogs, articles, guides, features and more',
    'Ability to scale up, huge capacity',
  ],
  // packages: [
  //   {
  //     id: 'basic',
  //     name: 'Standard',
  //     // url: 'https://my.ocere.com/product/basic-content/',
  //     showUnit: true,
  //     price: {
  //       USD: 0.05 * 1.26, // 1 GBP = 1.26 USD
  //       GBP: 0.05,
  //     },
  //     per: 'per world',
  //     items: {
  //       Inlcudes: {
  //         'Choose Content Type': true,
  //         'SEO Optimized ': true,
  //         'Full Proofreading': true,
  //         'Fast Turnaround Time': true,
  //         Content: 'Basic Grade for Volume',
  //       },
  //     },
  //     labelAction: 'Order Now',
  //     feature: [
  //       'Standard Grade Content',
  //       'For When Cost Matters',
  //       'Basic Subject Research',
  //       'Native UK/US Writers',
  //       'Includes Standard Images',
  //     ],
  //     noFeature: [],
  //     more: [
  //       'Checked by Grammarly',
  //       'Checked by Copyscape',
  //       '1 Click Revision Request',
  //       'Downloadable Doc Links',
  //       'Track Orders in Dashboard',
  //     ],
  //   },
  //   {
  //     id: 'pro',
  //     name: 'Pro',
  //     // url: 'https://my.ocere.com/product/creative-content/',
  //     showUnit: true,
  //     price: {
  //       USD: 0.08 * 1.26, // 1 GBP = 1.26 USD
  //       GBP: 0.08,
  //     },
  //     per: 'per word',
  //     items: {
  //       Inlcudes: {
  //         'Choose Content Type': true,
  //         'SEO Optimized ': true,
  //         'Full Proofreading': true,
  //         'Fast Turnaround Time': true,
  //         Content: 'Creative Content',
  //       },
  //     },
  //     labelAction: 'Order Now',
  //     feature: [
  //       'Pro Grade Content',
  //       'For When Quality Matters',
  //       'In-Depth Subject Research',
  //       'More Stats, Facts, Figures',
  //       'Includes Researched Images',
  //       '2-Stage Proofreading Process',
  //     ],
  //     noFeature: [],
  //     more: [
  //       'Checked by Grammarly',
  //       'Checked by Copyscape',
  //       '1 Click Revision Request',
  //       'Downloadable Doc Links',
  //       'Track Orders in Dashboard',
  //     ],
  //   },
  // ],
  how_it_works: [
    {
      title: 'What To Say?',
      description:
        'We will create unique, powerful and relevant written content for your business website. ' +
        'You select the word count to get your message across – from 500, 1,000, 2,000 words or ' +
        'more. Ocere can create a wide range of unique content such as blogs, articles, guides, ' +
        'features, advertorials, Q&amp;As and product descriptions to help improve visitor engagement ' +
        'based on your preferred parameters.',
    },
    {
      title: 'Creative Content',
      description:
        'Our team of writers will produce quality content in English, French, German, Spanish, ' +
        'Italian and Portuguese to client specification. All our writers are natural speakers of their ' +
        'relevant language and are vetted. Ocere has a three stage quality checking process, which ' +
        'starts with developing a strategy, writing and proofreading the content and delivery to ' +
        'client.',
    },
    {
      title: 'Order Completion',
      description:
        'Ocere’s dedicated team of writers create thousands of content pieces every month. The ' +
        'Google search algorithm rates millions of content items every day. Our backend team will ' +
        'ensure your content is keyword rich and follows recognised parameters for best on-page ' +
        'optimisation, so we can maximise search returns for your business.',
    },
  ],
  key_benefits: [
    {
      title: 'Website Credibility',
      description:
        'Quality is one of the key search parameters for the Google search engine. Quality content ' +
        'creation and publishing on your website helps ensure your website remains credible and ' +
        'relevant. Key factors such as grammar and relevance now have a great impact on how a ' +
        'search engine will rate your website.',
    },
    {
      title: 'Better Visitor Engagement',
      description:
        'Your website is a shop window – what it displays and how it is displayed will either ' +
        'engage visitors or turn them away. Well crafted content, complete with visual images will help ' +
        'your visitors find what they are looking for – FAST!',
    },
    {
      title: 'Increase Your Audience',
      description:
        'Sharing is the beginning of content going viral. The reason something goes viral is ' +
        'because it is liked and relevant to the visitor’s perceptions. Original, well presented content ' +
        'will engage visitors and if engaged, they are more likely to share. Shared content increases ' +
        'visitors, which in turn increases the potential of new customers. ' +
        'What better incentive is there than increased visitors, increased customers and increased ' +
        'revenue?',
    },
  ],
  faq: [
    {
      title: 'How do you write SEO content 2021?',
      description:
        'You must do proper keyword research, use contextual results but, most importantly, ' +
        'find and write about topics that stand the test of time. Alternatively, engage our cost effective ' +
        'SEO copywriting services.',
    },
    {
      title: 'How do I write SEO for my website?',
      description:
        'Your website SEO copywriting should be amazing and have authority which will stem from ' +
        'your in-depth audience research and by finding out what is trending in Google search.',
    },
    {
      title: 'How do I write SEO content for my website?',
      description:
        'Do lots of keyword research, know your topic and know it well, write about what your ' +
        'visitors might want. If you do not know your target audience and their preferences, you are on the ' +
        'back foot before you begin. This is just the tip of the iceberg – there is a lot more to it than' +
        ' that. Alternatively, engage Ocere’s SEO copywriting services for seamless, expertly researched ' +
        'and written SEO writing.',
    },
    {
      title: 'What does Web content mean?',
      description:
        'Web content is basically any creative element of a website. SEO writing, images, videos ' +
        'and archived content. Web content is the driving force behind generating organic traffic.',
    },
    {
      title: 'Why is content important in a website?',
      description:
        'Web content, which includes images, SEO writing, videos and other elements of on page ' +
        'creativity provides information to search engines and visitors in a balanced format – it is the ' +
        'way in which you communicate your website to the outside world. One without the other will do you ' +
        'website no favours at all.',
    },
    {
      title: 'What should a website contain?',
      description:
        'Your website content should provide a clear description of your brand and company ethos. ' +
        'Easy to find information, images to break up the text but which are elements of the story you are ' +
        'telling. Interesting SEO writing which also has a clear call to action also helps. Oh yes, and an ' +
        'easily navigable website which ‘draws’ your visitors in further and for longer.',
    },
    {
      title: 'What makes great content?',
      description:
        'Great content is something which draws in and engages the visitor and keeps them wanting ' +
        'more. Great content, particularly great SEO copywriting is content that will get shared, liked and ' +
        'talked about.',
    },
    {
      title: 'How do you create good social media content?',
      description:
        'Create high quality content. It is often overlooked on social media but without great content, shares and likes will be minimal if at all any. Trigger emotions. Write to your audience as if they are the only person on Earth. Give your visitors links to your website – SEO outreach is an important element of great social media content. This should give you a start – now do a lot of research or engage Ocere’s SEO copywriting services and SEO outreach services or <a target="_blank" href="https://www.ocere.com/services/blogger-outreach">blogger outreach service</a>.',
    },
    {
      title: 'How do I make shareable content?',
      description:
        'Show value. Be clear in your message. Present viable results. Highlight bullet lists. ' +
        'Include numbers. Talk in the second person. Engage Ocere’s SEO copywriting services.',
    },
    {
      title: 'What is sharable content?',
      description:
        '<a target="_blank" href="https://blog.alexa.com/create-shareable-content-checklist/">Shareable content</a> is what people think likeminded people will want to see, read or hear. Your job (or Ocere’s SEO copywriting team’s job) is to create valuable, informative, engaging content. The shares and likes will come from your audience if you’ve got it right.',
    },
    {
      title: 'What should I write in about us on my website?',
      description:
        'Use facts. Inspire trust through good SEO copywriting. Tell visitors about you – but ' +
        'truthfully – no hype and DEFINATELY no false claims. Express values, your ethos and your brand’s ' +
        'journey. Forge a connection.',
    },
    {
      title: 'What makes a website great?',
      description:
        'A great website should provide basic SEO writing - information which is easy to read but is also optimised for search engines. Social media buttons, links to other great content, cool images – basically, anything which is user friendly and which engages the visitor. Many of our clients are web developers who take up Ocere’s <a target="_blank" href="https://www.ocere.com/white-label">white label SEO service</a>.',
    },
    {
      title: 'How do you write SEO friendly content in 2021?',
      description:
        'Know your audience. Engage in keyword research. Be topical but also write with longevity ' +
        'in mind. Spread the word. It all sounds easy but it is time consuming. If time is one thing you do ' +
        'not have, let Ocere’s SEO writing team take the strain.',
    },
    {
      title: 'How many words should be on a Web page for SEO?',
      description:
        'An article in Forbes suggests that a webpage should have an average of 600 to 700 words ' +
        'per page and is considered ‘optimal’. Anything below 300 words per page are considered to be ' +
        '‘thinned out’ and do not match Google’s minimum SEO writing requirements. An absolute minimum of ' +
        '500 words to a page should be your target.',
    },
    {
      title: 'What is SEO friendly websites?',
      description:
        'SEO friendly websites are those which have been designed to make them search engine ' +
        'friendly. The better a website is designed with search engines bots in mind, the quicker it will ' +
        'be indexed and the higher it will rank. However, that is just the beginning of the journey.',
    },
    {
      title: 'Is more content better for SEO?',
      description:
        'More is better certainly, but quality content (images, graphics, videos, engaging SEO ' +
        'writing) trumps quantity every time.',
    },
    {
      title: 'How do you write SEO in 4 easy steps?',
      description:
        'Research the keywords which will be relevant to your target audience. Outline your piece. ' +
        'Create and write your introduction. Write an interesting ‘body’ to your article. Write a conclusion ' +
        '– never leave the reader hanging. Alternatively, engage Ocere’s SEO copywriting team’s experience ' +
        'to save you a lot of stress and time.',
    },
    {
      title: 'What is long form content?',
      description:
        'Long form SEO writing affords the opportunity of providing more valuable information to ' +
        'your audience. Long form content is more often shared and likes than low word count content.',
    },
    {
      title: 'How do you write a SEO friendly title?',
      description:
        'Not too long. One keyword only if possible but more won’t incur penalties. Make it a' +
        ' unique title. Use brand to your advantage. Write the title for your target audience. ' +
        'Alternatively, Ocere’s SEO copywriting team can do it all for you.',
    },
    {
      title: 'How do I find SEO keywords?',
      description:
        'Know your business. Know your audience. Research related search terms in Google. ' +
        'Find a tool online which you can research keywords. engage Ocere’s SEO copywriting team to do ' +
        'the work for you.',
    },
  ],
} as ContentCreation;
