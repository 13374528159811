import {Index} from '@/content/pages/persona';

export default {
  url: '/services/pr-outreach',
  meta: {
    title: 'PR Outreach Services | Ocere [2021!]',
    description:
      'PR Outreach services that help clients to broaden their reach online, gain authority and reach a wider readership. Click here to read more.',
  },
  green: {
    graphic: 'white-label',
    name: 'PR Outreach',
    catchphrase: 'PR Outreach',
    heading: 'Gain high-quality links and mentions to your website',
    paragraph: [
      'A strong PR outreach strategy is integral to the growth and development of your online presence. By liaising with press contacts and disseminating press releases, you can begin to augment your brand online through high quality content and backlinks that lead greater numbers of organic traffic back to your own site.',
    ],
  },
  content: {
    services: ['Blogger Outreach', 'Creative Content', 'SEO Packages'],
    'How does it work?':
      '<p>At Ocere, our high-impact PR outreach service is there to take over the task of building relationships with webmasters, bloggers and new sites. We have over ten years’ experience in building a network of relevant, high quality sites for brands looking to widen their reach, increase their online visibility (both for organic traffic, and the search engine algorithms) and see tangible results on SERPs.</p>' +
      '<p>Our Digital PR team has worked on outreach for 1000s of campaigns in an impressive variety of industries. We offer this service on a ‘pay for results’ basis, and will only invoice you for live links and mentions so that you can have greater confidence in your new strategy.</p>',
    'Why choose Ocere?':
      '<p>Ocere has been exceeding expectations for ten years now, and today are trusted ' +
      'by over 3,000 clients around the world across a wide range of industries. We pride ourselves not ' +
      'only on our excellent results, but also the simplicity and transparency with which we deliver ' +
      'them. Many of our clients have had frustrating experiences with other agencies and are delighted ' +
      'to discover an entirely different experience when working with Ocere. If you are a digital ' +
      'marketing agency, or an integrated SEO and SEO outreach PR agency for example, and want to improve ' +
      'your service to clients, have you considered white label SEO outreach service? If you are working ' +
      'as a freelance business adviser, perhaps adding a SEO outreach reseller service to your business ' +
      'model.</p>',
  },
  faq: [
    {
      title: 'What is an Outreach Strategy?',
      description:
        'Identifying relevant, high authority sites who hold influence within your market, and creating working relationships with them in order to augment your own presence online. An effective strategy will be one that cuts through the noise generated by competitors through press releases, original content, and PR blogging.',
    },
    {
      title: 'How Long Does PR Outreach Take?',
      description:
        'PR outreach is an ongoing job, which is why so many brands are now turning to dedicated digital PR teams in order to take advantage of their wide network of contacts. At Ocere, we have outreached over 20,000 webmasters in order to ensure a strong outreach capability for clients seeking to gain traction within a wide variety of markets. As part of our Digital PR service, we typically allow two to four weeks for outreach. We leverage our press contacts to source the most relevant sites that will provide you with high quality, lucrative coverage.',
    },
    {
      title: 'How do I Improve My PR Outreach?',
      description:
        'By identifying relevant sites and bloggers, writing effective and engaging press releases that stand apart from the many other brands and outreach times targeting those same sites, and providing <a target="_blank" href="https://www.ocere.com/services/content-creation">high quality content</a> with which to <a target="_blank" href="https://www.ocere.com/link-building">build backlinks to your own sight</a>. It takes a great deal of time, commitment and practical experience to optimise your approach, which is why Ocere’s PR outreach service has proven so popular with our clients.',
    },
    {
      title: 'What Industries Do You Have Experience in?',
      description:
        'We have been working with clients from a wide range of industries for more than ten years. We have had great success working with brands in finance, iGaming, tech, AI, blockchain, and much more. We are also prepared to work in a wide range of languages, including French, German, Portugese and Italian, which has connected us to over 3,000 clients based all over the globe.',
    },
    {
      title: 'Are Press Releases Still Effective?',
      description:
        'In short, yes, they remain a highly effective tool for PR Outreach. However, their efficacy lies in their execution. According to a 2019 survey conducted by <a href="https://www.vuelio.com/uk/wp-content/uploads/2019/03/UK-Bloggers-Survey-2019.pdf">Vuelio</a>, more than 32% of bloggers are being contacted by PRs seven or more times each week, which just goes to show how valuable high quality sites are to brands hoping to grow their online profile. Understanding how to capture the attention of webmasters is vital to the success of any press release.',
    },
  ],
} as Index;
