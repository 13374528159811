import {Service} from '@/content/pages/services/index';

export default {
  icon: require('@/assets/icons/services/seo_icon.png'),
  graphic: require('@/assets/graphics/services/seo_graphic.svg'),
  title: 'SEO Packages',
  bullet:
    'Pre-built packages that deliver organic traffic and build rankings. Modern, white-hat techniques.',
  url: '/services/seo-packages',
  meta: {
    title: 'SEO Packages - #1 Fixed Price Monthly Organic SEO Packages [2021!]',
    description:
      '2021 SEO Packages. Amazing Fixed Price Monthly Organic SEO Packages. Helping you Outrank Competitors in Google. Start Trial Today!',
  },
  shop: '', // https://my.ocere.com/product-category/seo-services
  days: 14,
  description: [
    'Fixed price SEO packages that contain everything you need to beat the competition. Our monthly SEO ' +
      'services help you move up the search engines whilst keeping your SEO costs low. Start a monthly retainer ' +
      'SEO project with Ocere and move ahead of your competition. We help business owners and marketing teams ' +
      'improve Google keyword rankings.',
  ],
  points: [
    'Between 4 - 20 Keywords',
    'Increase Google Rankings',
    'Grow Organic Traffic',
    'Outrank Competitors',
    'Full Monthly Reporting',
  ],
  packages: [
    {
      name: 'SEO Package 1',
      // url: 'https://my.ocere.com/product/seo-package-1/',
      // price: {
      //     USD: 695,
      //     GBP: 495,
      // },
      items: {
        'On-page items': {
          'Keyword research': true,
          'Website review': true,
          'On-Page SEO Recommendations': true,
          'Page Title Recommendations': true,
          'Meta Description Writing': true,
          'H1 Tags': true,
          'Text Content Review': true,
          'Image Alt Text Creation': false,
          'Implementation of SEO Recommendations': true,
          'Onsite Content - Blog Article': false,
          'Onsite Content - Landing Page': false,
          'Website Health Check': true,
          'Google Analytics Review': false,
        },
        'Off-page items': {
          'Link Profile Review': false,
          'Link Content Creation': 5,
          'High Quality Link Placements': 5,
          'Tier 2 Linking': 10,
          'Social Signals': 20,
          'PR Link + Creation': 0,
        },
      },
    },
    {
      name: 'SEO Package 2',
      // url: 'https://my.ocere.com/product/seo-package-2/',
      // price: {
      //     USD: 1295,
      //     GBP: 895,
      // },
      items: {
        'On-page items': {
          'Keyword research': true,
          'Website review': true,
          'On-Page SEO Recommendations': true,
          'Page Title Recommendations': true,
          'Meta Description Writing': true,
          'H1 Tags': true,
          'Text Content Review': true,
          'Image Alt Text Creation': true,
          'Implementation of SEO Recommendations': true,
          'Onsite Content - Blog Article': false,
          'Onsite Content - Landing Page': false,
          'Website Health Check': true,
          'Google Analytics Review': true,
        },
        'Off-page items': {
          'Link Profile Review': true,
          'Link Content Creation': 10,
          'High Quality Link Placements': 10,
          'Tier 2 Linking': 20,
          'Social Signals': 40,
          'PR Link + Creation': 0,
        },
      },
    },
    {
      name: 'SEO Package 3',
      // url: 'https://my.ocere.com/product/seo-package-3/',
      // price: {
      //     USD: 1995,
      //     GBP: 1495,
      // },
      items: {
        'On-page items': {
          'Keyword research': true,
          'Website review': true,
          'On-Page SEO Recommendations': true,
          'Page Title Recommendations': true,
          'Meta Description Writing': true,
          'H1 Tags': true,
          'Text Content Review': true,
          'Image Alt Text Creation': true,
          'Implementation of SEO Recommendations': true,
          'Onsite Content - Blog Article': true,
          'Onsite Content - Landing Page': false,
          'Website Health Check': true,
          'Google Analytics Review': true,
        },
        'Off-page items': {
          'Link Profile Review': true,
          'Link Content Creation': 15,
          'High Quality Link Placements': 15,
          'Tier 2 Linking': 40,
          'Social Signals': 80,
          'PR Link + Creation': 2,
        },
      },
    },
    {
      name: 'SEO Package 4',
      // url: 'https://my.ocere.com/product/seo-package-4/',
      // price: {
      //     USD: 3250,
      //     GBP: 2450,
      // },
      items: {
        'On-page items': {
          'Keyword research': true,
          'Website review': true,
          'On-Page SEO Recommendations': true,
          'Page Title Recommendations': true,
          'Meta Description Writing': true,
          'H1 Tags': true,
          'Text Content Review': true,
          'Image Alt Text Creation': true,
          'Implementation of SEO Recommendations': true,
          'Onsite Content - Blog Article': true,
          'Onsite Content - Landing Page': true,
          'Website Health Check': true,
          'Google Analytics Review': true,
        },
        'Off-page items': {
          'Link Profile Review': true,
          'Link Content Creation': 25,
          'High Quality Link Placements': 25,
          'Tier 2 Linking': 80,
          'Social Signals': 160,
          'PR Link + Creation': 4,
        },
      },
    },
  ],
  how_it_works: [
    {
      title: 'Initial Strategy',
      description:
        `You'll specify your website, keyword ideas and goals (don't worry if you're unsure - ` +
        `we can advise!). We'll then conduct full keyword research, competitor research, discuss and then ` +
        `get underway. The strategy and first month's execution are all done inside the first 30days. ` +
        'You can have as much or as little involvement as you like.',
    },
    {
      title: 'Monthly Execution',
      description:
        `We'll execute this plan from Month 1 onwards. Typically this involves creating and ` +
        'implementing On-Page elements (page titles, meta tags etc), writing content and building external ' +
        'links towards your site. Full reporting is given on every activity every month (via email and ' +
        'online portal).',
    },
    {
      title: 'Ongoing Refinement',
      description:
        `The web is fast moving. Competition doesn't stay still. We analyse current results, ` +
        'competitor results and refine the approach accordingly to get the best results. ' +
        `We'll be proactive on this, unlike 95% of other agencies who sit on their laurels. ` +
        'Again, you can have as much or as little involvement as you like.',
    },
  ],
  key_benefits: [
    {
      title: 'Increase Google Rankings',
      description:
        'Outrank your competition and move up the search engine ladder, sustainably.',
    },
    {
      title: 'Grow Organic Traffic',
      description:
        'Organic Traffic will always be hugely valuable and brings in users with the right intent.',
    },
    {
      title: 'Build Brand Awareness',
      description:
        'Take control of your online perception and exceed your competitors.',
    },
    {
      title: 'Beat Competitors',
      description:
        'We can X-ray their strategies and help your leapfrog them in the SERPs.',
    },
    {
      title: 'Bring in More Customers',
      description:
        'Organic will always be such a valuable channel. Attract the right type of customers.',
    },
  ],
  faq: [
    {
      title: 'How much does SEO cost?',
      description:
        'Typically, digital marketing services vary in cost depending on certain factors. ' +
        'Ocere has fixed price SEO packages as well as offering a bespoke price SEO services. Basically, ' +
        'the cost of engaging an online marketing agency and getting access to a full range of SEO digital ' +
        'marketing services will depend on four main factors – your location, your business objectives, ' +
        'how quickly you want to increase your online ranking and how quickly you want to achieve a ' +
        'page 1 ranking in Google search.',
    },
    {
      title: 'How much does SEO cost per month in India?',
      description:
        'Typically, SEO services start at around $200 a month, but you will still have to factor ' +
        'in how quickly you want to increase your online ranking and how quickly you want to achieve a page ' +
        '1 ranking in Google search. The more you want and the faster you want it, the more the cost to you.',
    },
    {
      title: 'What is a social media package?',
      description:
        'A social media package is a bundle SEO package which is an ideal way for small business ' +
        'owners or start ups which have limited budgets. Using social media such as Facebook and Twitter for ' +
        'example is an excellent but very cost effective method of promoting a brand to a targeted audience.',
    },
    {
      title: 'Is SEO worth the money?',
      description:
        'Yes! It is more cost effective for example than is PPC (Google AdWords). To make an ' +
        'analogy, SEO digital marketing services is much like building a house. You lay down the ' +
        'foundations, build the walls, fit the roof, insert doors and windows, fit the infrastructure and, ' +
        'once all that is done, you can move in. All it then needs is the odd ‘lick of paint’ to keep it ' +
        'maintained. Monthly SEO packages from Ocere lay down optimisation foundations and build on those so ' +
        'you can reap the long term rewards.',
    },
    {
      title: 'Why is SEO so expensive?',
      description:
        'The most common reasons SEO services tend to be expensive are working with competitive ' +
        'keywords, trying to attain a national search ranking rather than local, carrying out extensive ' +
        'research, competitor analysis, creating quality content etc. Also, you will be paying for the ' +
        'accumulated knowledge and experience of the team of marketers whom you will engage- Ocere’s SEO ' +
        'services packages prices reflect all these elements and more.',
    },
    {
      title: 'Is SEO a waste of money?',
      description:
        'No! SEO digital marketing should be seen as an investment in to the medium to long term ' +
        'health of your business online presence. Ocere, as a long established online marketing agency will ' +
        'guide and advise you through the entire process to ensure you attain maximum ROI.',
    },
    {
      title: 'Is SEO worth it for small business?',
      description:
        'Yes! Your website is an online shop window to the world. If you do not engage in SEO ' +
        'digital marketing, you may as well pull the blinds down, shut up shop and go home. Without some ' +
        'form of SEO and/or SEO outreach services your website will be virtually invisible, unlike' +
        ' the websites which have had digital marketing services applied to them',
    },
    {
      title: 'How do I become SEO certified?',
      description:
        'There are many courses which on which you can enrol, search on Google for SEO certified ' +
        'courses. You will be taught the basics of all elements of SEO digital marketing. However, you only ' +
        'really start learning when you work in SEO services, whether you work for directly for an online ' +
        'marketing agency or as a freelancer.',
    },
    {
      title: 'How much do SEO freelancers make?',
      description:
        'Rates vary from between £30 an hour up to about £100 but it all depends on how you market ' +
        'yourself, how experienced you are with SEO digital marketing and where you are located. ' +
        'For example, rates in the capital will be much higher than, say, Inverness or Cheltenham ' +
        'as a couple of examples.',
    },
    {
      title: 'What is SEO optimization?',
      description:
        'Search engine optimisation, commonly referred to by the acronym SEO, is the practice of ' +
        'increasing the quantity of visitors to a website, the quality of visitors and the ranking of a ' +
        'website or web page via organic search. Elements of digital marketing services include, but are ' +
        'not limited to – SEO outreach, on page creative content, social media marketing blogging etc. ' +
        'Ocere’s blogger outreach service is a great tool to help optimise your web presence.',
    },
    {
      title: 'How can I do SEO for free?',
      description:
        'You can easily do your own SEO digital marketing. You will need to lots of research, ' +
        'just like marketers at any online marketing agency will undertake. In no particular order you ' +
        'will need to write great content which is optimised for search. You will need to outreach. You ' +
        'will need to build links. Improve the experience of visitors to your website. Optimise onsite ' +
        'images. Make sure headers are in place and of the correct size. Improve page load speed.',
    },
    {
      title: 'How can I learn SEO fast?',
      description:
        'Research – a lot of research. Read books and online training materials related to SEO ' +
        'digital marketing. Get an SEO mentor, perhaps from your local online marketing agency. Mingle with ' +
        'people of a similar mindset. Practice – lots of practice.',
    },
    {
      title: 'Is SEO Dead?',
      description:
        'No! On the contrary, as the number of websites, blog sites and other online marketing ' +
        'channels increase and users increase, SEO digital marketing is essential in making your online ' +
        'presence stand out above the crowd.',
    },
    {
      title: 'Is SEO relevant in 2021?',
      description:
        'Yes! See the answer to the question – ‘Is SEO Dead?’ Targeted, smart SEO digital marketing ' +
        'is more relevant than it has ever been before. Talk to the team at Ocere for more information about ' +
        'SEO packages and SEO outreach packages.',
    },
    {
      title: 'Is SEO just keywords?',
      description:
        'No! SEO is about keywords but the sum of the parts is much more. SEO outreach, on-page ' +
        'optimisation, reaching out to webmasters for publishing content, social media channel marketing, ' +
        'the list goes on. Ocere’s SEO packages are designed around different elements for differing ' +
        'requirements of our clients. Ocere’s',
    },
    {
      title: 'Does SEO really matter?',
      description:
        'Yes! Targeted, smart and well researched SEO digital marketing is more relevant than it ' +
        'has ever been before. For more information about our SEO services packages, talk to the team at ' +
        'Ocere today.',
    },
    {
      title: 'Which SEO techniques are in trend?',
      description:
        'Go beyond Google search. Ocere’s digital marketing services team is researching ' +
        'constantly. Structured data mark-up is becoming more important as the changes to the Google AI ' +
        'algorithm kick in. Additionally, grow your EAT - expertise, authority, and trustworthiness – ' +
        'without those, your online presence is in danger of falling back.',
    },
    {
      title: 'How can I improve my SEO ranking?',
      description:
        'Just as Ocere’s digital marketing services look at a website’s on-page content to check it ' +
        'is relevant, you should be creating killer content, authoritative and optimised correctly. ' +
        'You should build relevant internal links, outreach to websites, get content published, acquire ' +
        'back links, use social media channels – there is so much more.',
    },
    {
      title: 'How can I improve my SEO 2021?',
      description:
        'As a long established online marketing agency we would recommend making sure your website ' +
        'is optimised for mobile devices. Write content for humans to read, optimised for search engines. ' +
        'Analyse current search traffic, link social media channels to your website and ALWAYS stay up to ' +
        'date with emerging SEO trends.',
    },
    {
      title: 'What is Google SEO tools?',
      description:
        'The Google webmaster tools are a valuable marketer’s tool. At a glance you can see ' +
        'relevant statistics about your website. Stats such as keywords in use, how many external links, ' +
        'dead links, live links, Follow and NoFollow links, crawl errors etc.',
    },
    {
      title: 'How do I get SEO on Google?',
      description:
        'Elements of Ocere’s SEO packages will help you optimise your site for mobile devices. ' +
        'We’ll write amazing content for you, analyse your search traffic and that of your competitors, ' +
        'use social media channels, outreach and publish content with back links. There is more to it than ' +
        'that, but you catch my drift.',
    },
    {
      title: 'How do you rank first on Google?',
      description:
        'Sometime with great difficulty! But, with due diligence, lots of keyword research, ' +
        'competitor analysis, outreach SEO outreach, social media marketing and great creative content, ' +
        'there is no reason, if you work hard enough and smart enough, you can get to page 1 position 1. ' +
        'Alternatively, leave all that to Ocere’s team of SEO magicians.',
    },
    {
      title: 'How do I increase visibility on Google Maps?',
      description:
        'You should claim your listing, complete your Google My Business profile, use a LOCAL ' +
        'phone number and optimise your subscription. However, the easy way is to engage Ocere – our ' +
        'digital marketing services SEO packages include this.',
    },
    {
      title: 'How do you rank higher on Google?',
      description:
        'See the answer to the question – ‘How do you rank first on Google’',
    },
    {
      title: 'What is the fastest way to rank a keyword?',
      description:
        'You can use the less popular version of a keyword; you can use keyword modifiers, mix ' +
        'in branded and neutral keywords and mix up your on-page optimisation. Alternatively, Ocere’s SEO ' +
        'packages include all this and more – check them out today.',
    },
    {
      title: 'Do Google posts help SEO?',
      description:
        'Yes! Your overall strategy for SEO will improve – Google My Business is a great way to ' +
        'enhance your SEO campaign. Ocere’s SEO services packages include all this.',
    },
    {
      title: 'Does Google Maps make money?',
      description:
        'Yes, typically through Google AdWords and advertising campaigns run by webmasters and ' +
        'website owners.',
    },
    {
      title: 'Is Google my business Free?',
      description: 'Yes, and it is easy to use and very intuitive.',
    },
    {
      title: 'Is Google my business going away?',
      description:
        'No! Google Plus is going away but Google My Business is staying and is improving ' +
        'constantly – it is a powerful tool in any digital marketing services campaign.',
    },
    {
      title: 'How long do Google business posts last?',
      description:
        'Google posts expire one week (seven days) after posting, so you will need to constantly ' +
        'update to stay on trend.',
    },
    {
      title: 'How do I optimize my business for Google 2021?',
      description:
        'Complete the information form on Google My Business, post great content on the posts ' +
        'section, use high res images, use a local number etc. However, buy an SEO package from Ocere and ' +
        'we will do it for you.',
    },
    {
      title: 'Is SEO necessary for ranking a website in Google?',
      description:
        'Yes! Without SEO of some form – on-site optimisation, outreach SEO outreach, social ' +
        'media channel marketing etc – your website will not rank.',
    },
    {
      title: 'How do I find SEO keywords?',
      description:
        'You can use any of the myriad tools available for finding keywords to use in an SEO ' +
        'digital marketing campaign. Word Stream and Keyword Finder are just a couple of tools you can ' +
        'use, as well as Google Analytics. However, you will need to devote a lot of time to research ' +
        'keywords, as well as lots of competitor analysis.',
    },
    {
      title: 'How do I start SEO?',
      description:
        'Start with the website. Make sure the foundation of your online presence is in good ' +
        'shape – keyword deployment, optimised and tagged images, headers, great content, relevant content ' +
        'and authoritative content. But you will only be able to do this if you have researched fully. ' +
        'Ocere’s SEO digital marketing packages include all these elements and more.',
    },
    {
      title: 'Which SEO tool is best?',
      description:
        'There is not a single tool which ‘is the best’ so to speak. AhRefs, Moz, Keyword Finder, ' +
        'SEMRush, SEOQuake, Fat Rank and SiteLiner all have attributes which can help you work smart with ' +
        'your SEO. If I had to pick one SEO tool, I would opt for Ocere’s complete digital marketing ' +
        'services which will deploy most of these online tools.',
    },
    {
      title: 'Which is best free SEO tool?',
      description:
        'The best free tools are Google Keyword Planner, Google Analytics and Google Search ' +
        'Console. The team at Ocere will typically use these tools and other paid for SEO tools to deliver ' +
        'our high quality SEO digital marketing service.',
    },
    {
      title: 'What is the best SEO checker?',
      description:
        'My personal choice would be AhRefs – other marketers may differ and debate.',
    },
    {
      title: 'How do I improve my SEO ranking in 2021?',
      description:
        'Write content for humans to read but optimised for search engines to find. Research your ' +
        'keywords, outreach to websites to get some quality, linked content published. Use social media to ' +
        'spread the word. Optimise your website, the foundation of your presence online.',
    },
    {
      title: 'How do I SEO my website?',
      description:
        'SEO digital marketing analysts will tell you to make sure you use relevant keywords on ' +
        'every page, but do not stuff the pages with keywords. Make sure you have at least one of each size ' +
        'header (H1 to H6) on every page. Optimise images. Publish quality content. Insert internal ' +
        'links which are relevant.',
    },
    {
      title: 'What are some SEO tools?',
      description:
        'Just a few SEO digital marketing tools would include AhRefs, Moz, Keyword Finder, ' +
        'SEMRush, SEOQuake, Fat Rank, Google Analytics and SiteLiner as a few examples. However, there are ' +
        'hundreds; some you will have to pay for, others are free so happy hunting.',
    },
    {
      title: 'What are good SEO keywords?',
      description:
        'This is a difficult one! However, the simplest of explanations would be as follows. ' +
        'Any keyword or search phrase which is speaking the same language as the potential visitors which ' +
        'may come to your site. You should research keywords that help connect searchers to your site – ' +
        'if you know your competitors; some analysis of their sites would help isolate the ‘good keywords’ ' +
        'to use.',
    },
    {
      title: 'Is Google Keyword Planner still free?',
      description:
        'Yes! Unequivocally, totally and completely 100% free to use.',
    },
    {
      title: 'How do I find the best keywords?',
      description:
        'Either subscribe to a paid for keyword planner or use one of myriad free such tools ' +
        'online. Alternatively, Ocere’s SEO services (monthly SEO packages) will take care of that for you. ' +
        'Find out more today.',
    },
    {
      title: 'Is SEO necessary for website?',
      description:
        'Yes! Without some form of SEO digital marketing, your website will not be ‘seen’ in ' +
        'Google search. As an online marketing agency of many years standing, our comprehensive organic ' +
        'SEO packages have been designed and are constantly evolving to get your website noticed – FAST.',
    },
    {
      title: 'How do I choose an SEO agency?',
      description:
        'Always ask to see case studies and examples of successful successful SEO digital ' +
        'marketing campaigns they have run. Ask to see as many diverse examples as possible. Understand ' +
        'the risk of LOW COST digital marketing services – if the price seems too good to be true, then ' +
        'it probably is.',
    },
    {
      title: 'Do I need SEO for my website?',
      description:
        'Yes! If your website isn’t optimised for Google and other search engines, it will not ' +
        'rank. If it doesn’t rank, you will be missing out on potential traffic which will be going ' +
        '(probably) to your competitors.',
    },
  ],
} as Service;
