














































































































import {header, ocere} from '@/content';
import {Component, Vue, Watch} from 'vue-property-decorator';
import AppHeaderLink from '@/components/app-header-link.vue';
import LinkedIn from '@/assets/icons/linkedin.vue';
import Facebook from '@/assets/icons/facebook.vue';
import Skype from '@/assets/icons/skype.vue';
import Whatsapp from '@/assets/icons/whatsapp.vue';
import {empty, toBoolean} from '@/utils';

@Component({
  components: {AppHeaderLink, LinkedIn, Facebook, Skype, Whatsapp},
})
export default class extends Vue {
  social = ocere.social;
  phone = ocere.phone;
  email = ocere.email;
  links = header.links;
  open = false;
  visibleSignUpBar = true;
  emailInput = '';
  isShowHeader = true;
  isConfirmed = true;
  /**
   * Computed property to watch activeAssetTreeNode in store.
   */

  get showPlaceOrderBtn() {
    return this.$route.matched.some(record =>
      toBoolean(record.meta.isDashboard)
    );
  }

  @Watch('$store.state.user', {immediate: true})
  async watchUSer(uInfo) {
    this.isShowHeader = empty(uInfo);
    this.visibleSignUpBar = empty(uInfo);
    this.isConfirmed = !this.isShowHeader && uInfo.confirmed;
  }
}
