import seo_packages from './seo-packages';
import google_ads_packages from './google-ads-packages';
import digital_marketing_packages from './digital-marketing-packages';
import casino_seo from './casino-seo';

export interface Package {
  name: string;
  url?: string;
  per?: string;
  price?: {
    GBP: number;
    USD: number;
  };
  items: {
    [section: string]: {
      [item: string]: boolean | string | number;
    };
  };
}

export interface QA {
  title: string;
  description: string;
}

export interface ManagedCampaigns {
  icon: string;
  graphic: string;
  title: string;
  meta: {
    title: string;
    description?: string;
  };
  alias?: string;
  bullet: string;
  description: string[];
  points: string[];
  explained?: object;
  why_choose?: string[];
  days: number;
  url: string;
  shop?: string;
  packages?: Package[];
  how_it_works?: QA[];
  faq: QA[];
  key_benefits: QA[];
}

export default [
  seo_packages,
  google_ads_packages,
  digital_marketing_packages,
  casino_seo,
] as ManagedCampaigns[];

export {
  seo_packages,
  google_ads_packages,
  digital_marketing_packages,
  casino_seo,
};
