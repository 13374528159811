





























































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/page-title.vue';

@Component({
  components: { PageTitle },
  metaInfo: {
    title: 'Terms & Conditions | Ocere ',
    meta: [
      {
        content: 'Click here to review our the terms and conditions for Ocere Ltd.',
        hid: 'description',
        name: 'description',
      },
    ],
  },
})
export default class extends Vue {}
