import {LinkBuilding} from '@/content/pages/link-building/index';

export default {
  icon: require('@/assets/icons/services/pricing_icon.png'),
  title: 'iGaming Outreach',
  bullet: 'One of the world leaders in effective SEO Outreach for igaming.',
  url: '/casino-seo',
  meta: {
    title: '',
    // TODO
  },
} as LinkBuilding;
