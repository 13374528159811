import {LinkBuilding} from '@/content/pages/link-building/index';

export default {
  icon: require('@/assets/icons/services/link_icon.png'),
  graphic: require('@/assets/graphics/services/link_building_graphic.svg'),
  title: 'Blogger Outreach',
  bullet:
    'SEO outreach done properly. Authentic blogs with real audiences. Industry-leading turnaround times.',
  url: '/services/blogger-outreach',
  meta: {
    title: 'Blogger Outreach Services | SEO outreach  Agency | Ocere',
    description:
      'Blogger outreach services covering all major niches at competitive prices from a leading SEO outreach agency. Click here to get started.',
  },
  // shop: 'https://my.ocere.com/product-category/link-building/',
  days: 10,
  description: [
    '<b>Get high-quality links from authentic websites using our blogger outreach service.</b>',
    'We have built over 50,000 publisher relationships across all the major verticals (tech, finance, gaming, business etc). ',
    'Order using our online dashboard, or click on “Managed Services” to have a fully bespoke package created for you.',
  ],
  points: [
    'In-content Link To Your Site',
    '500 Word Content',
    '100% Authentic Outreach',
    'Fast Turnaround (10+ days)',
  ],
  explained: {
    title: 'Blogger Outreach Explained',
    content: [
      'Blogger outreach services is a catch-all phrase which develops and executes a strategy of working with other bloggers to create unique, relevant and ' +
        'authoritative content to promote a brand, service, or increase product awarenessamong a relevant readership.',
      'There are hundreds of millions of active blogs on the web today, which means that there are plenty of opportunities for collaboration and publication. ' +
        'Growing your online presence necessitates reaching beyond the parameters of your own site and blog, and forming lasting.' +
        ', professional relationships with those who can grant access to readers who will offer the most benefit to your site.',
      'In addition to garnering a higher quality of traffic to your site, blogger outreach will also contribute to ' +
        'your authority within search engine results pages. In essence, creating a tangible link between your site and another will demonstratethe value you offer to readers; the more you are able to create, ' +
        'the more evidence Google will have of the value you hold for relevant searches.',
      'As such, blogger outreach services remain crucial to any comprehensive SEO strategy.',
    ],
  },
  packages: [
    {
      isShowCurrencySwitcher: true,
      id: 'da20',
      more: [
        'Easy Online Portal',
        'Fully White Label',
        'Includes Images',
        'Google Sheet Option',
      ],
      feature: [
        '100% Authentic Outreach',
        'Native UK/US Writers',
        'Includes 500 Word Blog',
        'DA/DR20+',
      ],
      // noFeature: ['SEM Rush 1K+ Traffic'],
      noFeature: [],
      bulkDiscount: [
        {
          label: '1 Placement',
          price: {
            GBP: 60,
            USD: 85,
          },
        },
        {
          label: '25+ Placements',
          price: {
            GBP: 55,
            USD: 78,
          },
        },
        {
          label: '50+ Placements',
          price: {
            GBP: 50,
            USD: 70,
          },
        },
      ],
      name: 'DA20+',
      price: {
        GBP: 60,
        USD: 85,
      },
      per: 'Budget Tier',
      items: {
        Includes: {
          'In-content Link To Your Site': true,
          '500 Word Content': true,
          '100% Authentic Outreach': true,
          'Fast Turnaround (10+ days)': true,
          'Ahrefs Average': 'DR20+',
        },
        'Bulk Discounts': {
          '1 Placement': '£60',
          '25+ Placements': '£55',
          '50+ Placements': '£50',
        },
      },
    },
    {
      isMostPopular: true,
      id: 'da30',
      more: [
        'Easy Online Portal',
        'Fully White Label',
        'Includes Images',
        'Google Sheet Option',
      ],
      feature: [
        '100% Authentic Outreach',
        'Native UK/US Writers',
        'Includes 500 Word Blog',
        'DA/DR30+',
      ],
      // noFeature: ['SEM Rush 1K+ Traffic'],
      noFeature: [],
      bulkDiscount: [
        {
          label: '1 Placement',
          price: {
            GBP: 80,
            USD: 110,
          },
        },
        {
          label: '25+ Placements',
          price: {
            GBP: 75,
            USD: 105,
          },
        },
        {
          label: '50+ Placements',
          price: {
            GBP: 70,
            USD: 100,
          },
        },
      ],
      name: 'DA30+',
      price: {
        GBP: 80,
        USD: 110,
      },
      per: 'Mid Tier',
      items: {
        Includes: {
          'In-content Link To Your Site': true,
          'Includes 500 word content': true,
          '100% Authentic Outreach': true,
          'Fast Turnaround (10+ days)': true,
          'Ahrefs Average': 'DR30+',
        },
        'Bulk Discounts': {
          '1 Placement': '£80',
          '25+ Placements': '£75',
          '50+ Placements': '£70',
        },
      },
    },
    {
      id: 'da40',
      more: [
        'Easy Online Portal',
        'Fully White Label',
        'Includes Images',
        'Google Sheet Option',
      ],
      feature: [
        '100% Authentic Outreach',
        'Native UK/US Writers',
        'Includes 500 Word Blog',
        'DA/DR40+',
      ],
      // noFeature: ['SEM Rush 1K+ Traffic'],
      noFeature: [],
      bulkDiscount: [
        {
          label: '1 Placement',
          price: {
            GBP: 165,
            USD: 210,
          },
        },
        {
          label: '25+ Placements',
          price: {
            GBP: 160,
            USD: 205,
          },
        },
        {
          label: '50+ Placements',
          price: {
            GBP: 155,
            USD: 200,
          },
        },
      ],
      name: 'DA40+',
      price: {
        GBP: 165,
        USD: 210,
      },
      per: 'High Tier',
      items: {
        Includes: {
          'In-content Link To Your Site': true,
          'Includes 500 word content': true,
          '100% Authentic Outreach': true,
          'Fast Turnaround (10+ days)': true,
          'Ahrefs Average': 'DR40+',
        },
        'Bulk Discounts': {
          '1 Placement': '£165',
          '25+ Placements': '£160',
          '50+ Placements': '£155',
        },
      },
    },
    {
      id: 'da50',
      more: [
        'Easy Online Portal',
        'Fully White Label',
        'Includes Images',
        'Google Sheet Option',
      ],
      feature: [
        '100% Authentic Outreach',
        'Native UK/US Writers',
        'Includes 500 Word Blog',
        'DA/DR50+',
      ],
      noFeature: [],
      bulkDiscount: [
        {
          label: '1 Placement',
          price: {
            GBP: 250,
            USD: 320,
          },
        },
        {
          label: '25+ Placements',
          price: {
            GBP: 245,
            USD: 315,
          },
        },
        {
          label: '50+ Placements',
          price: {
            GBP: 240,
            USD: 310,
          },
        },
      ],
      name: 'DA50+',
      price: {
        GBP: 250,
        USD: 320,
      },
      per: 'Top Tier',
      items: {
        Includes: {
          'In-content Link To Your Site': true,
          'Includes 500 word content': true,
          '100% Authentic Outreach': true,
          'Fast Turnaround (10+ days)': true,
          'Ahrefs Average': 'DR50+',
        },
        'Bulk Discounts': {
          '1 Placement': '£240',
          '25+ Placements': '£235',
          '50+ Placements': '£230',
        },
      },
    },
  ],
  how_it_works: [
    {
      title: 'Outreaching Sites',
      description:
        `You'll provide the Anchor Text, Target URLs and quantity you require to our outreach ` +
        `agency team. We'll then start outreaching topically relevant sites, with strong authority. ` +
        'We already have 20,000 publisher relationships to help streamline this process. For a small charge ' +
        'you can approve sites upfront.',
    },
    {
      title: 'Creative Content',
      description:
        'Our UK content team will start writing your content immediately (typically 500 words ' +
        'per piece). The key here is to form a natural bridge between the blog and your target URL. ' +
        'We are experts at this. For a small charge you can approve content upfront.',
    },
    {
      title: 'Order Completion',
      description:
        `As a ‘blogger agency' we place thousands of content pieces per month. Outreach link ` +
        'building PR (press release) content is another element we regularly deploy. The content is quickly ' +
        'live. We send you a link report (via Email and Online Portal) which shows the live link, metrics ' +
        `(DA etc) and live date. We're on hand for any questions etc.`,
    },
  ],
  key_benefits: [
    {
      title: 'Authentic, Natural Sites',
      description:
        'Forget dodgy networks or "thin" blogs made for SEO purposes. We deal in authentic sites, ' +
        'with authentic audiences.',
    },
    {
      title: 'Fastest Turnaround',
      description:
        'We boast over 20,000 publisher relationships and sharper processes to turn orders around ' +
        'quicker.',
    },
    {
      title: 'Full Approval',
      description:
        'Unlike others - you can have full approval of all content and sites (+10% fee). Stay in ' +
        'control!',
    },
    {
      title: 'In Content Links',
      description:
        'All our links are naturally contained in the editorial. Forget footer links or sponsored ' +
        'links.',
    },
    {
      title: 'Price Match',
      description: `We'll match or beat any comparable offer from another company. No questions asked.`,
    },
  ],
  why_choose: [
    'With over a decade of experience in providing blogger outreach services – and over 40,000 working relationships to show for our efforts ' +
      '– we consistently offer our clients a strong, proactive approach toward all aspects of obtaining backlinks.',
    'We have worked with thousands of clients operating within some of the most competitive industries, providing <a target="_blank" href="https://www.ocere.com/casino-seo">SEO for iGaming</a>, FinTech, financial services, and many more. ' +
      'In that time, we have developed an acute understanding of the measures necessary to augmenting and solidifying a site’s online presence' +
      ', and are able to take charge at every stage of the process to ensure strong results for our clients.',
  ],
  faq: [
    {
      title: 'How Do I Connect with Bloggers?',
      description:
        'Your easiest and most direct route is via a link agency such as Ocere. The process of forming and maintaining these ' +
        'connections can be incredibly time consuming and, at times, frustrating, which is why making ' +
        'use of our existing network of strong working relationships proves invaluable to our clients.',
    },
    {
      title: 'How Does a Blogger Outreach Strategy Work?',
      description:
        'Blogger outreach is about building relationships with bloggers and influencers, sothey will host your content to promote your brand, ' +
        'product or service on their websites or blog sites. Your easiest and most direct route is via a blogger agencysuch as Ocere. ' +
        'We have invested many years already into growing our network of webmasters and bloggers to ensure a perfect fit for your brand.',
    },
    {
      title: 'What are Blogger Outreach Campaigns?',
      description:
        'A blogger outreach campaign is a method of encouraging promotion of your brand, product or service by collaborating ' +
        'with identified influencers in your target market.',
    },
    {
      title: 'What are the Tools of Blogger Outreach?',
      description:
        'There are many influencer outreach and content marketing tools which a bloggeragency will ' +
        'deploy to maximise efficiency of an outreach campaign. Outreach activities cover a broad range such as email outreach, ' +
        'establishing relationships with influencers, brand promotion via blogging, unique <a target="_blank" href="https://www.ocere.com/services/content-creation">creative content</a>, PR blogging etc. ' +
        'The best tools, however, are first-hand knowledge and experience garnered within the ever evolving digital landscape.',
    },
    {
      title: 'How Much Does a Blogger Outreach Service Cost?',
      description:
        'The cost of blogger outreach can range from a few hundred to tens of thousands ' +
        'depending entirely on the type of the campaign and what your trying to achieve. We offer both set packages and tailored solutions to suitalmost any budget. ' +
        'Get in touch today for more detailed information about blogger outreach prices and what you get for your investment.',
    },
    {
      title: 'How Long Does Outreach Take?',
      description:
        'Outreach is an ongoing process. Developing a network of thousands of bloggers and webmasters takes years, and countless hours of work, to achieve. ' +
        'When you engage Ocere’s blogger outreach services, you stand to benefit from our substantial network of existing contacts, ' +
        'which will pay dividends in terms of the time and effort it takes to get your name out there. ' +
        'If you sign up today, we can provide you with our initial report in just ten days ' +
        '– significantly less time and hassle than it would take if you were to do it yourself.',
    },
    {
      title: 'What is Email Outreach?',
      description:
        'Email represents the most common medium for outreach. The main aim is to catch the attention of quality blogs, ' +
        'webmasters and influences in the hopes thatthey will feel inspired to work alongside ' +
        'your brand to create a piece of content that leads their readers directly to your site. ' +
        'In email outreach, you will need a short, catchy and standout subject line, provide genuine stats, ' +
        'and some samples of unique content and then a proposal of working together. After that, you just have to hope that you get a reply. ' +
        'Avoiding the ‘Trash’ or ‘Spam’ folder is an art in and of itself, and you also need to be careful that you do not fall foul of current data protection legislation.',
    },
    {
      title: 'How do I Find Blogs?',
      description:
        'You can search online via Google, Bing or Yahoo for example, visit a blog directory, use Tumblr etc. ' +
        'Alternatively, use Ocere’s blogger outreach service or white label SEO service to cut out all the hard work.',
    },
    {
      title: 'How Do I Outreach to Influencers for Collaboration?',
      description:
        'By identifying, reaching out to them by email or phone, and then establishing trusting, working relationships with them. ' +
        'Find influencers in your niche, outreach them by email or phone, then start to establish a trusting, working relationship with them and create a perfect pitch. ' +
        'Alternatively, you could engage Ocere – with over 40,000 established relationships with influencers, we have done all the groundwork for you.',
    },
    {
      title: 'How do I Get Influencers to Promote My Brand?',
      description:
        'Find influencers in your niche, outreach them, start building a relationship and create a perfect pitch ' +
        '– or you could engage Ocere’s influencer and blogger outreach service to save time, money and lots of frustration.',
    },
    {
      title: 'How Much Do Influencers Charge?',
      description:
        'Influencers can charge anything from a few tens of pounds to many thousands ofpounds.',
    },
    {
      title: 'How Do I Outreach to Brands for Collaboration?',
      description:
        'Be cordial, be brief, but outline what you want to do and, in particular, what advantages this alliance could bring for them. ' +
        'Identify areas in which your brands’ interests coincide, and aim to base your pitch upon the ways in which you will both stand to benefit from collaboration. ' +
        'This is, of course, easier said than done, which is why so many brands turn to an outreach agency like Ocere to ensure the best results.',
    },
    {
      title: 'How Can I Promote My Brand for Free?',
      description:
        'Publish self-made content on YouTube and other media channels, embrace several social media channels, ' +
        'optimise your brand’s website, start a blog, become a PR blogger and distribute for publication...the work never stops.',
    },
    {
      title: 'What is the Cheapest Way to Advertise Your Business?',
      description:
        'Post authoritative, engaging content on your blog, build a free email list, <a target="_blank" href="https://www.google.com/business/">create a Google My Business account</a>, optimise your website, send out marketing texts to subscribers etc...',
    },
    {
      title: 'What is Content Outreach?',
      description:
        'Put simply, content outreach is a method of directly promoting your content which is intended to be presented to ' +
        'influential people whose audiences are similar to yours, so that they will promote your content to their audiences.',
    },
    {
      title: 'Who Writes the Content?',
      description:
        'Some bloggers will stipulate that the content needs to be created by them, though the vast majority will ask for it to be provided ' +
        'in accordance with the site’s theme, tone and style. ' +
        'If you work with Ocere, however, our team of native writers can take over the <a target="_blank" href="https://www.ocere.com/services/content-creation">creative content</a> on your behalf, ' +
        'and provide quality work that will lead readers directly to your site via backlinks.',
    },
    {
      title: 'What Are the Qualities of Effective Content?',
      description:
        'It needs to be written with your ideal readership in mind. Your content should be engaging, informative, relevant, ' +
        'original and authoritative, and it should be grounded in strong keyword research in order to appeal to Google’s algorithm.',
    },
    {
      title: 'What is the Relationship Between Outreach and SEO Outreach?',
      description:
        '<a target="_blank" href="https://www.ocere.com/link-building">SEO Outreach</a> remains one of the most important aspects of any good SEO strategy. The more links Google is able to observe between you and other qualitysites, ' +
        'the more ‘confident’ it can feel in raising your rank on results pages. ' +
        'Consider each link to be a piece of tangible evidence supporting the quality and value your site holds; ' +
        'the more consistent you are in your approach, the better the results will be. ' +
        'Backlink outreach involves proactively acquiring backlinks from websites. It enables you to form those vital connections with relevant sites; to that end, ' +
        'you can begin to obtain quality backlinks through creative content and strong keyword research.',
    },
    {
      title: 'How Does SEO Outreach Work?',
      description:
        'Blogger SEO outreach entails elements of email outreach, influencer outreach, social outreach and PR blogger outreach to ' +
        'people to get them to click on links pointing to your website, or to publish links on their websites which pointto your website.',
    },
    {
      title: 'Do backlinks still work 2021?',
      description: 'The simple answer is yes!',
    },
    {
      title: 'How Can I Get Free Backlinks?',
      description:
        'Dedicate your time to researching and compile a list of bloggers and influencers relevant to your niche. ' +
        'Create relevant content which will appeal to them. Reach out to them. Hope they publish your content and link to your website.',
    },
    {
      title: 'How Can I Get Quality Backlinks?',
      description:
        'With a great deal of time, frustration, and perseverance. ' +
        'Instead, put us on the case. We will get to work researching and compiling a list of bloggers and influencers relevant to your niche, ' +
        'and take advantage of our existing relationship with them by targeting the values and interests you hold in common. ' +
        'Our content team will then create relevant written content which will appeal to them, ' +
        'and ensure that it features your backlink in a way that is natural and authentic. ' +
        'Next, we publish your content and link, and continue to monitor the results.',
    },
    {
      title: 'How do I Get Thousands of Backlinks?',
      description:
        'With hard work, lots of research, continuous blogger outreach and lots of quality content submitted for publication, ' +
        'you can create thousands of backlinks – all of which will stand you in good stead for a high ranking on search engine results pages. ' +
        'Alternatively, make it easier on yourself with one of Ocere’s <a target="_blank" href="https://www.ocere.com/services/seo-packages">SEO packages</a>; we can do all the work required to ' +
        'implement a strong SEO outreach strategy for yoursite, from outreach to creative content and ' +
        'keyword research.',
    },
    {
      title: 'How do I Find Backlinks to My Site?',
      description:
        'You can learn how to use industry standard tools such as Moz, AhRefs, Majestic and SEMRush – ' +
        'to name just a few. At Ocere, we can take over the work of monitoring backlinks for you.',
    },
    {
      title: 'How Do I Know if I Have Bad Backlinks?',
      description:
        'You can track down any bad backlinks using those same tools. However, part of our services includes tracking down – ' +
        'and working to develop a strategy for clearing – any detrimental backlinks to ensure that the rest of our efforts remain unhampered. ' +
        'This is just one aspect of what we do for our clients.',
    },
    {
      title: 'How Do I Find My Competitor’s Backlinks?',
      description:
        'These same tools can also be deployed to analyse your competitors’ backlinks, and to track down any <a target="_blank" href="https://www.socialmediatoday.com/news/toxic-backlinks-how-they-hurt-seo-and-how-to-get-rid-of-them/573246/">bad backlinks which may be harming your digital reputation</a> – or you could engage Ocere’s blogger outreach service. We can work out what your competitors are doing, and ensure that you are going the extra mile.',
    },
    {
      title: 'What is the Best SEO Outreach Strategy?',
      description:
        'Any link agency achieving success through SEO outreach will draw upon a numberof tools and methodologies, ' +
        'but the best SEO outreach strategy will be grounded within strong blogger outreach and a firm understanding of the sites’ target audiences. ' +
        'They will also be creating and distribute amazing, relevant and authoritative content which is underpinned by dogged keyword research. ' +
        'They will also employ social media channels to amplify and sustain your content.',
    },
    {
      title: 'How do You Improve SEO Outreach?',
      description:
        'Constantly investigate external links pointing to your website, ensure none are broken, ' +
        'seek out sites suitable for SEO outreach which have good metrics. At the same time, you should be keeping on on top of your competitors’ ' +
        'SEO outreach strategy and continuing to build new relationships as a means of furthering your efforts each month. ' +
        'At Ocere, this is what we do. Blogger outreach and SEO outreach services are full time jobs – and that’s at the best of times. ' +
        'The only way to ensure consistency and quality is to turn to the professionals, and wait for the results to speak for themselves.',
    },
  ],
} as LinkBuilding;
