

















import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/page-title.vue';
import DmGraphic from '@/assets/graphics/dm-graphic.vue';
import GetInTouch from '@/components/get-in-touch.vue';
import { home } from '@/content';
import PersonaContainer from '@/components/home/persona-container.vue';
import LinedBulletPoints from '@/components/services/lined-bullet-points.vue';

@Component({
  components: {
    LinedBulletPoints,
    PersonaContainer,
    PageTitle,
    GetInTouch,
    DmGraphic,
  },
  metaInfo: {
    title:
      'Get in Touch Today! | SEO, PPC & Link Building Services | Ocere',
    meta: [
      {
        name: 'description',
        content:
          'Looking to see what our SEO, link building and PPC services can do for you? Click here to visit our site now and get in touch with our UK-based team of specialists.',
      },
    ],
  },
})
export default class extends Vue {
  points = home['get-in-touch'];
}
