export interface Employee {
  portrait: string;
  firstname: string;
  lastname: string;
  expertise: string;
  calendly?: string;
  description: string;
}

export const meta = {
  title: 'Terms and Conditions - Ocere',
  description: 'Terms and conditions for Ocere Ltd.',
};

export const employees = [
  {
    portrait: require('@/assets/team/tom_parling.jpg'),
    firstname: 'Tom',
    lastname: 'Parling',
    expertise: 'Founder & CEO',
    calendly: 'https://calendly.com/tomocere',
    description: `Tom Parling is the driving force behind Ocere and founded the company back in 2009. He possesses a talent
            for Entrepreneurship and has a proven track record of creating business from scratch, with little external
            help or resources. Tom is a member of several invite-only Entrepreneurial organizations and was recently
            recognized “Young Entrepreneur of the Year” in a large regional business awards ceremony. Tom has also
            completed two Ironman triathlons, along with a string of challenging Cyclo-Sportives across Europe.`,
  },
  {
    portrait: require('@/assets/team/amanda_falshaw.jpg'),
    firstname: 'Amanda',
    lastname: 'Falshaw',
    expertise: 'Business Support Director',
    description: `Mandy joined Ocere in 2009 and as Business Support Director, her role is to ensure that all areas of the
            business work cohesively, ensuring processes remain effective and efficient. Mandy is also responsible for
            the Finance/HR function of the business including all invoicing, payments, HMRC submissions,
            accounts/reconciliations, payroll, 121s and appraisals. She also oversees and works closely with Credit
            Control, Customer Service and Lead Generation Sales. Mandy also works with our CEO and Digital Marketing
            Director to help formulate the Business Plan and project strategy. Mandy started her own business at 19, but
            a career change has seen her gain more than 30 years’ experience working in in both the private and public
            sectors, mainly in the Finance field. Mandy is a Lancashire lass at heart, and regularly travels back to
            spend time with her 3 ‘grown up sons’. She has a keen interest in photography and anything involving walking,
            swimming and cinema and regularly takes part and raises money for various charities.`,
  },
  {
    portrait: require('@/assets/team/remi_ashton.jpg'),
    firstname: 'Remi',
    lastname: 'Ashton',
    expertise: 'Digital Marketing Director',
    calendly: 'https://calendly.com/remiocere',
    description: `Remi has been part of the Ocere Team since 2013 and his role within the company is that of Digital
            Marketing Director. Heading up the Digital Marketing Team, Remi oversees the Account Management, Sales, Adwords
            and Outreach teams. He plays a very important role in making sure that all clients receive the best service
            available to them. Remi is always looking to improve his skills and knowledge and invests a great deal of his
            time on technical and personal development. Remi also works closely with our CEO and Business Support Director
            to help formulate our Business Plan and project strategy. Remi passed his Google Squared Online course in 2016,
            receiving ‘The Most Influential “Squared” member on the course. He is Google Certified, studying and passing a
            number of exams including- Google Adwords Fundamental and an Advanced Search exam in 2017. Outside of work, Remi
            runs his own Martial Arts School called – KiXtreme Martial Arts in which his family also participate. Remi is a
            former Martial Arts World Champion (from 2002-2004). He enjoys trips to Anfield to watch his beloved football
            team, Liverpool, and spending time with his wife and 3 children.`,
  },
  {
    portrait: require('@/assets/team/lee_bastin.jpg'),
    firstname: 'Lee',
    lastname: 'Bastin',
    expertise: 'Sales Account Manager',
    calendly: 'https://calendly.com/leeocere',
    description: `Lee is a multifaceted sales and marketing professional with experience across a variety of different
            industries, as well as a background in advertising. Such broad knowledge and experience make him very
            well-suited to his role at Ocere, allowing him to contribute to sales and marketing processes at almost
            any level. Lee also has a genuine hunger to learn new things and assimilate information, not only for its own
            sake, but also to stay on top of industry trends and new developments, thereby providing the best possible
            service to our digital marketing clients. Outside of work, Lee enjoys playing and composing music, reading,
            drawing and other creative pursuits, as well as spending time with family and friends.`,
  },
  {
    portrait: require('@/assets/team/rob_helm.jpg'),
    firstname: 'Rob',
    lastname: 'Helm',
    expertise: 'Sales Account Manager',
    calendly: 'https://calendly.com/robocere',
    description: `Rob is a member of the Ocere Digital Marketing Team. Joining us at the start of 2017 and bringing with
            him over 25 years of Sales and Marketing experience.  With a friendly and honest approach, his role within the
            team is to focus on Sales & Account Management of SEO for Agencies and End User accounts. Outside of work, Rob
            has 2 children which he regularly spends time with and watching his team, Liverpool. Rob also enjoys cooking
            and reading.`,
  },
  {
    portrait: require('@/assets/team/ashley_yetman.jpg'),
    firstname: 'Ashley',
    lastname: 'Yetman',
    expertise: 'Paid Search Manager',
    description: `Ashley has been part of the team since 2015. With his primary role overseeing the management of all
            client Adwords & Facebook Ads accounts, and running our internal PPC campaigns, he has a real talent for coming
            up with different strategies to implement and recommend to clients. Ashley is the go-to member of the team for
            all things technical.  Ashley is a Certified Google Specialist, which includes passing 6 exams set by Google and
            means he has to have in depth knowledge of Adwords and is the point of contact when asking a question regarding
            that field. Ashley started off his career with Ocere as an apprentice, after completed a Games Design Degree at
            the Staffordshire University. In his free time, Ashley likes to game online with his friends as well as
            socialising regularly.`,
  },
  {
    portrait: require('@/assets/team/ben_beard.jpg'),
    firstname: 'Ben',
    lastname: 'Beard',
    expertise: 'Digital Marketing Manager',
    description: `Ben has been with the company since May 2016. Ben is our Digital Marketing Analyst, looking after Online
            Lead Generation, systems, processes and marketing. He is also involved in various tasks within the Digital
            Marketing team. Ben oversees and draws management information from a program that is used by both sides of
            the business and ensures that it is running correctly for the team to use. Ben finished Sixth Form with 2 A*
            in Business Studies and IT. He reads and speaks a small amount of French. His previous role working as a
            receptionist at a mainstream car dealership, has given him experience and confidence to interact with customers,
            affiliates, managers and other team members. Outside of work, Ben enjoys watching his team, Liverpool, and
            engaging in outside activities with his friends.`,
  },
  {
    portrait: require('@/assets/team/callum_flanders.jpg'),
    firstname: 'Callum',
    lastname: 'Flanders',
    expertise: 'Digital Marketing Executive',
    description: `Callum has been part of the team since 2018. Callum is our Digital Marketing Executive & his primary
            roles include project coordination, ensuring reports are completed on-time & on-quality to the
            specification, technical analysis using Excel and online SEO tools, reviewing statistics & adjusting
            campaigns appropriately. He assists with the technical setup and integration of new publishers as
            well as reporting weekly on progress, actively building new connections to facilitate Ocere’s rather
            considerable client base. Outside of work, Callum enjoys a multitude of activities in various forms
            whether that is bodybuilding, martial arts or studying a specific subject matter through reading.`,
  },
  {
    portrait: require('@/assets/team/anna_chadwick.jpg'),
    firstname: 'Anna',
    lastname: 'Chadwick',
    expertise: 'Credit Control',
    description: `Anna joined Ocere in April 2016 and brings with her 17 years of Finance and Credit Control experience.
            She is looks after the credit control function of the business, being responsible for coordinating the debts of
            existing creditors and processing queries in a timely manner. Always putting customers and their needs first,
            Anna builds great relationships with our customers. She works closely with Customer Service to reach a
            satisfactory resolution. In her spare time, Anna is working towards a Credit Management Degree. Anna is mum to
            a little girl who keeps her busy most of the time.`,
  },
  {
    portrait: require('@/assets/team/person_placeholder.jpg'),
    firstname: 'Harry',
    lastname: 'Green',
    expertise: 'Head of Project Management',
    description: `Harry has been a key part of the team for over 8 years and prides himself on ensuring both delivery and
            results are strong on client projects. Strong experience with working with large UK/US agency clients and
            delivering on challenging needs.`,
  },
  {
    portrait: require('@/assets/team/person_placeholder.jpg'),
    firstname: 'Jayce',
    lastname: 'Redford',
    expertise: 'Project Manager',
    description: `Jayce co-ordinates a large number of projects with our account management team. He has a specialism in
            outreach and organic projects. Outside of work Jayce is a talented singer and musician.`,
  },
  {
    portrait: require('@/assets/team/person_placeholder.jpg'),
    firstname: 'Oliver',
    lastname: 'Samson',
    expertise: 'Project Manager',
    description: `Oliver is a relatively new team member, who is known for quick turnarounds and accuracy of work within
            the project management team.`,
  },
] as Employee[];
