import {apiClient, handleResp} from './utils';
// import {empty} from '@/utils';
// import moment from 'moment';
const ClientService = {
  doGetClients() {
    return handleResp(apiClient().get('/clients'));
  },
  doGetClient(id: string) {
    return handleResp(apiClient().get('/clients/' + id));
  },
  doCreateClient(data) {
    return handleResp(apiClient().post('/clients/', data));
  },
  doUpdateClient(id, data) {
    return handleResp(apiClient().patch('/clients/' + id, data));
  },
  doGetClientOrders(id: string) {
    return handleResp(apiClient().get('/clients/' + id + '/orders'));
  },
  doGetClientOrder(clientId: string, orderId: string) {
    return handleResp(
      apiClient().get('/clients/' + clientId + '/orders/' + orderId)
    );
  },
  formatInfo(client) {
    return {
      id: client.id,
      email: client.email,
      firstName: client.first_name,
      lastName: client.last_name,
      phoneNumber: client.phone_number,
      companyName: client.company_name,
      buildingNameOrNumber: client.building_name_or_number,
      address1: client.address_line_1,
      address2: client.address_line_2,
      state: client.state,
      postcode: client.postcode,
      billingCountry: client.billing_country,
      role: client.role,
      status: client.confirmed,
      notes: client.notes,
    };
  },
};
export default ClientService;
