import content_creation from './content-creation';

export interface Package {
  id?: string;
  name: string;
  url?: string;
  per?: string;
  showUnit?: boolean;
  price?: {
    GBP: number;
    USD: number;
  };
  items: {
    [section: string]: {
      [item: string]: boolean | string | number;
    };
  };
  labelAction?: string;
  feature?: string[];
  noFeature?: string[];
  more?: string[];
}

export interface QA {
  title: string;
  description: string;
}

export interface ContentCreation {
  slider: boolean;
  icon: string;
  graphic: string;
  title: string;
  meta: {
    title: string;
    description?: string;
  };
  alias?: string;
  bullet: string;
  description: string[];
  points: string[];
  days: number;
  url: string;
  shop?: string;
  packages?: Package[];
  how_it_works?: QA[];
  faq: QA[];
  key_benefits: QA[];
}

export default [content_creation] as ContentCreation[];

export { content_creation };
