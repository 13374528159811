










































import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/page-title.vue';
import TeamContainer from '@/components/about/team-container.vue';
import { Employee, employees } from '@/content/pages/about';
import TeamContainerWrapper from '@/components/about/team-container-wrapper.vue';

@Component({
  components: { TeamContainerWrapper, TeamContainer, PageTitle },
  metaInfo: {
    title: 'About Us | Who Runs the #1 Blogger Outreach Agency? | Ocere',
    meta: [
      {
        name: 'description',
        hid: 'description',
        content:
          'Meet the team at Ocere and learn how we became the #1 blogger outreach agency, and a trusted partner of an ever-growing international client base. Click here.',
      },
    ],
  },
})
export default class extends Vue {
  public team: Employee[] = employees;
}
