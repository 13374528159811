import {Service} from '@/content/pages/services/index';

export default {
  icon: require('@/assets/icons/services/pricing_icon.png'),
  title: 'Casino SEO',
  bullet: 'Looking for an SEO strategy/proposal for SEO Outreach?',
  url: '/casino-seo',
  meta: {
    title: '',
    // TODO
  },
} as Service;
