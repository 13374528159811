import blogger_outreach from './blogger-outreach';
import casino_link_building from './casino-link-building';
import casino_seo from './casino-seo';
import content_creation from './content-creation';
import digital_marketing_packages from './digital-marketing-packages';
import google_ads_packages from './google-ads-packages';
import link_packages from './link-packages';
import seo_packages from './seo-packages';
import link_building from './link-building';

export interface Package {
  name: string;
  url?: string;
  per?: string;
  price?: {
    GBP: number;
    USD: number;
  };
  items: {
    [section: string]: {
      [item: string]: boolean | string | number;
    };
  };
}

export interface PackageNew {
  id: string;
  title: string;
  description: string;
  turnaround: string;
  unit: string;
  startingAt: number;
  items: ItemPackage[];
}
export interface ItemPackage {
  id: string;
  value: number;
  price: {
    GBP: number;
    USD: number;
  };
}

export interface QA {
  title: string;
  description: string;
}

export interface Service {
  icon: string;
  graphic: string;
  title: string;
  subTitle?: string;
  meta: {
    title: string;
    description?: string;
  };
  alias?: string;
  bullet: string;
  description: string[];
  points: string[];
  explained?: object;
  why_choose?: string[];
  days: number;
  url: string;
  shop?: string;
  packages?: Package[];
  package_new?: PackageNew[];
  how_it_works?: QA[];
  faq: QA[];
  key_benefits: QA[];
  slider?: boolean;
}

export default [
  link_building,
  seo_packages,
  casino_seo,
  content_creation,
  blogger_outreach,
  digital_marketing_packages,
  // google_ads_packages,
  // casino_link_building,
] as Service[];

export {
  blogger_outreach,
  casino_link_building,
  casino_seo,
  content_creation,
  digital_marketing_packages,
  google_ads_packages,
  link_packages,
  seo_packages,
};
